<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" :title="'Add '+$route.name" backdrop shadow
            :visible="allowAddEquipment" :right="true" no-header-close no-close-on-backdrop no-close-on-esc>
            <div class="d-block text-center mt-3">
                <b-row class="align-items-center px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Unit # * :" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Unit #" class="unit" required
                                v-model="newEquipment.unit_no"></b-form-input>
                            <p v-if="errors && errors.unit_no" class="field-error mb-lg-3">
                                {{ errors.unit_no[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3 d-none d-md-block">
                    </b-col>
                    <b-col md="6" sm="12" class="text-left select_arrow mt-3">
                        <label>Subtype:</label>
                        <b-form-input type="text" v-model="newEquipment.subtype" placeholder="Enter Subtype"
                            class="unit"></b-form-input>
                        <p v-if="errors && errors.subtype" class="field-error mb-lg-3">
                            {{ errors.subtype[0] }}
                        </p>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <b-form-group label="Vin #:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Vin #:"
                                v-model="newEquipment.vin_no"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Year:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Year" v-model="newEquipment.year"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Make:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Make" v-model="newEquipment.make"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Model:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Model" v-model="newEquipment.model"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Plate #:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Plate #:"
                                v-model="newEquipment.plate_no"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">State:</label>
                        <v-select class="f-12" :options="states" :reduce="(option) => option.id"
                            v-model="newEquipment.state.id" />
                    </b-col>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Tags</h3>
                    <b-col md="12" class="mt-3">
                        <v-select class="f-12" :options="allTags" multiple :reduce="(option) => option.id"
                            v-model="equipmentTags" />
                    </b-col>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4 sidebar-b-margin">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Details</h3>
                    <!-- <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Length:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Length:"
                                v-model="newEquipment.length"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Weight:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Weight:"
                                v-model="newEquipment.weight"></b-form-input>
                        </b-form-group>
                    </b-col> -->
                    <!-- <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Number of Axles:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Axles:"
                                v-model="newEquipment.number_of_axles"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Color:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Color:"
                                v-model="newEquipment.color"></b-form-input>
                        </b-form-group>
                    </b-col> -->
                    <b-col sm="12" class="mt-3">
                        <b-form-group label="Odometer:" class="text-left mb-0">
                            <b-form-input  type="text" v-mask="numberMask" placeholder="Enter Odometer"
                                v-model="newEquipment.odometer"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Value:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Value" v-model="newEquipment.value"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Serial #:" class="text-left mb-0">
                            <b-form-input type="text" placeholder="Enter Serial #"
                                v-model="newEquipment.serial_number"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col md="6" sm="12" class="text-left select_arrow mt-3">
                        <label>Fuel Type:</label>
                        <b-form-select class="f-12" v-model="newEquipment.fuel_type" :options="[
                            {
                                value: null,
                                text: '',
                            },
                            {
                                value: 'Diesel',
                                text: 'Diesel',
                            },
                            {
                                value: 'Bio-Diesel',
                                text: 'Bio-Diesel',
                            },
                            {
                                value: 'Gasoline',
                                text: 'Gasoline',
                            },
                            {
                                value: 'Ethanol',
                                text: 'Ethanol',
                            },
                        ]"></b-form-select>
                        <p v-if="errors && errors.fuel_type" class="field-error mb-lg-3">
                            {{ errors.fuel_type[0] }}
                        </p>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left select_arrow mt-3">
                        <label>Ownership:</label>
                        <b-form-select class="f-12" :options="[
                            {
                                value: null,
                                text: '',
                            },
                            {
                                value: 'Owned',
                                text: 'Owned',
                            },
                            {
                                value: 'Leased',
                                text: 'Leased',
                            },
                            {
                                value: 'Owner Operator',
                                text: 'Owner Operator',
                            }
                        ]" v-model="newEquipment.ownership"></b-form-select>
                        <p v-if="errors && errors.ownership" class="field-error mb-lg-3">
                            {{ errors.ownership[0] }}
                        </p>
                    </b-col> -->
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label for="active-date" class="state">Active Date:</label>
                        <date-picker v-model="newEquipment.active_date" :formatter="momentFormat"
                                :range-separator="' - '" @clear="newEquipment.active_date = ''" placeholder="Select Date"
                                class="date-picker-custom" :teleport="'body'"
                                ></date-picker>
                        <p v-if="errors && errors.active_date" class="field-error">
                            {{ errors.dob[0] }}
                        </p>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label for="inactive-date" class="state">Inactive Date:</label>
                        <date-picker v-model="newEquipment.inactivate_date" :formatter="momentFormat"
                                :range-separator="' - '" @clear="newEquipment.inactivate_date = ''" placeholder="Select Date"
                                class="date-picker-custom" :teleport="'body'"
                                ></date-picker>
                        <p v-if="errors && errors.inactivate_date" class="field-error">
                            {{ errors.dob[0] }}
                        </p>
                    </b-col>
                </b-row>
            </div>
            <div class="sidebar-footer position-fixed">
                <div class="text-right modal-footer border-0 p-0">
                    <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()"
                            variant="secondary">Cancel</b-button>
                            <b-button class="mx-0 h-40px w-125px px-2 mr-3 d-none d-sm-block" @click="onAddEquipment(true)"
                            variant="outline-primary">Save & Open</b-button>   
                        <b-button class="mx-0 h-40px w-100px" @click="onAddEquipment()"
                            variant="primary">Save</b-button>                        
                </div>
            </div>
        </b-sidebar>
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters, mapActions } from "vuex";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import * as moment from "moment-timezone";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const numberMask = createNumberMask({
    prefix: "",
    allowDecimal: true,
    includeThousandsSeparator: true,
});
export default {
    data() {
        return {
            numberMask,
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            equipmentTags: [],
            errors: {},
            newEquipment: {
                unit_no: null,
                type: null,
                subtype: null,
                year: null,
                make: null,
                vin_no: null,
                plate_no: null,
                color: null,
                number_of_axles: null,
                serial_number: null,
                weight: null,
                length: null,
                odometer: null,
                fuel_type: null,
                ownership: null,
                active_date:new Date(),
                inactive_date:null,
                state: {
                    id: 54,
                    country: 1,
                },
            },
        }
    },
    name: "AddEquipment",
    props: ['allowAddEquipment', 'options', "states"],
    components: { vSelect, DatePicker },
    methods: {
        ...mapActions([
            "getAllTags",
            "addEquipment",
            "setNotification",
            "setLoader"
        ]),
        resetPopup(close = true) {
            this.newEquipment = {
                unit_no: null,
                type: null,
                subtype: null,
                year: null,
                make: null,
                vin_no: null,
                plate_no: null,
                color: null,
                number_of_axles: null,
                odometer:null,
                serial_number: null,
                weight: null,
                length: null,
                fuel_type: null,
                ownership: null,
                active_date:new Date(),
                inactive_date:null,
                state: {
                    id: 54,
                    country: 1,
                },
            }
            this.errors={}
            if(close)this.$emit('close');

        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async onAddEquipment(openEquipment = false) {
            try {
                this.setLoader(true);
                const nullKeys = this.checkNullValues({
                    unit_no: this.newEquipment.unit_no,
                });
                if (nullKeys.length === 0) {
                    const newEquipment = await this.addEquipment({
                        ...this.newEquipment,
                        type:this.$route.name,
                        state: this.newEquipment.state.id ? this.newEquipment.state.id : 54,
                        tags: this.equipmentTags,
                        active_date: moment(this.newEquipment.active_date).format('YYYY-MM-DD'),
                        inactivate_date: this.newEquipment.inactivate_date ? moment(this.newEquipment.inactivate_date).format("YYYY-MM-DD"):null,
                        ...this.newEquipment.odometer ? {current_odometer: this.newEquipment.odometer.toString().replace(/,/g, "")}:{}
                    });
                    if (newEquipment) {
                        // await this.getEquipmentData();
                        await this.setNotification({
                            msg: `Successfully added ${newEquipment.unit_no}`,
                            type: "success",
                            color: "green",
                        });
                        this.equipmentTags = []
                        this.resetPopup(false)
                            this.setLoader(false);
                        if (openEquipment) this.$emit("getNewData", newEquipment.id)
                        else this.$emit("getNewData", null)
                    }
                } else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
                this.$bvModal.hide("modal-add-equipment");
                this.setLoader(false);
            } catch (error) {
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                } else {
                    await this.setNotification({
                        msg: error,
                        type: "error",
                        color: "red",
                    });
                }
            }
        },
    },
    computed: {
        ...mapGetters([
            "company",
            "userCompany",
            "tags"
        ]),
        allTags() {
            return this.tags.map((item) => {
                return {
                    id: item.id,
                    label: item.add_tag,
                }
            })
        },
    },
    async mounted() {
        await this.getAllTags(this.userCompany);
    }
}
</script>