<template>
    <div>
        <header class="text-left search_equipment d-flex user-detail row">
            <div class="col-md-8 d-flex justify-content-between mx-3 mx-md-auto align-items-center">
                <b-img :src="require('@/assets/form-logo.png')" class="d-none d-sm-block" width="230" height="37"></b-img>
                <b-img :src="require('@/assets/small-logo.svg')" class="d-block d-sm-none" width="40" height="40"></b-img>
            </div>
        </header>
        <div class="company-details-section d-flex justify-content-center pt-64">
            <div class="company-details-section-card col-md-8 mx-3 mx-md-auto bg-white rounded">
                <b-form @submit.prevent="getCompanyEquipments" class="mx-auto">
                    <b-img :src="companyDetails[0].logo" v-if="companyDetails[0].logo" class="mb-4 w-100"/>
                    <b-form-group label="Equipment #:" label-for="input-1" class="text-left label text-capitalize">
                        <b-form-input type="text" v-model="equipmentNo" class="mb-4 input" placeholder="Enter Equipment #"
                            required></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="companyDetails[0].pin_code" label="Pin #:" label-for="input-1"
                        class="text-left label text-capitalize">
                        <b-form-input type="text" class="mb-4 input" placeholder="Enter Pin" required pattern="\d{4,8}"
                            title="Min 4 Digits and Max 8 Allowed" v-model="pinNo"></b-form-input>
                    </b-form-group>
                    <b-button type="submit" class="w-100 login" variant="primary">Submit</b-button>
                    <p class="text-center mt-4 f-14">Thank you for using Digital Permit Book !</p>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            companyDetails: null,
            equipmentNo: null,
            pinNo: null
        }
    },
    name: "CompanyDetails",
    methods: {
        ...mapActions(["getCompanyDetails", "getCompanyEquipmentDetails", "setNotification","setCompanyPinCode"]),
        async getCompanyEquipments() {
            if (this.pinNo) {
                if(this.pinNo !== this.companyDetails[0].pin_code){
                await this.setNotification({
                    msg: "Pin is invalid",
                    type: "error",
                    color: "red",
                });
                return
            }
            else this.setCompanyPinCode(this.pinNo)
            }
            try {
                const data = await this.getCompanyEquipmentDetails({ company: this.companyDetails[0].id, unit_no: this.equipmentNo })
                this.$router.push(`/search-equipment?equipment=${data[0].id}`)
            }
            catch (e) {
                await this.setNotification({
                    msg: "Equipment doesn't exist.",
                    type: "error",
                    color: "red",
                });
            }
        },
    },
    async mounted() {
        try {
            this.companyDetails = await this.getCompanyDetails({ path: this.$route.params.name })
        }
        catch (e) {
            await this.setNotification({
                msg: "Company doesn't exist.",
                type: "error",
                color: "red",
            });
            this.$router.push(`/`)
        }
    }
}
</script>
<style>
.company-details-section {
    height: 100vh;
    align-items: center;
}

.company-details-section-card {
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.10);
    border-radius: 5px;
}

.company-details-section-card>form {
    width: 384px;
    padding: 57px 0px 80px 0px;
}

@media (max-width: 500px) {
    .company-details-section {
        height: auto;
        align-items: start;
        margin-top: 3rem;
    }

    .company-details-section-card>form {
        width: 100%;
    }
}</style>