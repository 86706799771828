<template>
    <b-container fluid class="form login-form reset-form">
        <b-row>
            <b-col lg="6" md="6" sm="12" class="left-side">
                <b-row>
                    <b-col lg="9" md="12" sm="12" class="m-md-0 my-0 my-md-5">
                        <div class="fix-width fix-width-login">
                            <div class="logo mb-3 pb-3 text-left">
                                <b-img class="" :src="require('@/assets/new-logo.png')" width="300" height="50"></b-img>
                            </div>
                            <b-form @submit.prevent="handleSubmit">
                                <b-form-group id="input-group-1" label="New Password:" label-for="input-1"
                                    class="text-left label password auth-form-group">
                                    <b-form-input id="input-1" :type="showPassword1 ? 'text' : 'password'"
                                        class="mb-3 input h-50px" required v-model="user.password1"></b-form-input>
                                    <b-icon :icon="showPassword1 ? 'eye-fill' : 'eye-slash-fill'"
                                        @click="showPassword1 = !showPassword1"></b-icon>
                                </b-form-group>

                                <b-form-group id="input-group-1" label="Confirm Password:" label-for="input-1"
                                    class="text-left label password auth-form-group">
                                    <b-form-input id="input-1" :type="showPassword2 ? 'text' : 'password'"
                                        class="mb-3 input h-50px" required v-model="user.password2"></b-form-input>
                                    <b-icon :icon="showPassword2 ? 'eye-fill' : 'eye-slash-fill'"
                                        @click="showPassword2 = !showPassword2"></b-icon>
                                </b-form-group>

                                <b-button type="submit" class="w-100 mt-2 login h-50px" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </b-col>
                    <p class="mb-0 f-14 opacity-50 copyright-text">© {{ new Date().getFullYear() }} Copyright Digital Permit
                        Book LLC.</p>
                </b-row>
            </b-col>
            <b-col cols="6" md="6" class="p-0 auth-bg-img">
                <Auth />
            </b-col>
        </b-row>
    </b-container>
</template>
  
<script>
import Auth from "../../components/auth/Auth.vue";
import { mapActions } from "vuex";
export default {
    components: { Auth },
    name: "ChangePassword",
    data() {
        return {
            showPassword1: false,
            showPassword2: false,
            user: {
                token: this.$route.params.token,
                uid: this.$route.params.uid,
                password1: "",
                password2: "",
            },
        };
    },
    methods: {
        ...mapActions(["changePasswordConfirm", "setNotification"]),
        async handleSubmit() {
            try {
                await this.changePasswordConfirm(this.user);
                this.setNotification({
                    msg: "Password changed successfully.",
                    type: "success",
                    color: "green",
                });
                this.$router.push("/all-companies");
            } catch (error) {
                console.log(error);
                this.setNotification({
                    msg: error.error,
                    type: "error",
                    color: "red",
                });

            }
        },
    },
};
</script>
  
<style>
/* .login-form.reset-form .right-img{
      height: calc(100vh);
  } */
.password {
    position: relative;
}

.password .b-icon {
    position: absolute;
    top: 42px;
    right: 13px;
}

@media (max-width: 1200px) {
    .reset-form .auth-bg-img {
        height: 100vh !important;
    }
}

@media (max-width: 991px) {
    .login-form.reset-form .right-img {
        height: 100%;
    }
}
</style>
  