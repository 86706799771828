<template>
  <div id="app">
    <div class="spinner" v-if="loader">
      <b-spinner variant="main" class="loader" type="grow" label="Spinning"></b-spinner>
    </div>
    <Notification v-if="notification.msg" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Notification from "./components/Notification.vue";
import { connectSocket, disconnectSocket, getSocket } from "./services/socketService";

export default {
  name: "App",
  data() {
    return {
      connected: false,
    };
  },
  computed: {
    ...mapGetters(["notification", "loader", "user", 'userNotifications']),
  },
  components: {
    Notification,
  },
  methods: {
    ...mapActions(['getUserNotifications', 'updateUserNotifications']),
    listenForNotifications() {
      const socket = getSocket();
      if (socket) {
        socket.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            const notifications = [
            data.message,
              ...(Array.isArray(this.userNotifications) ? this.userNotifications : [])
              
            ];
            this.updateUserNotifications(notifications)

          } catch (error) {
            console.error("Failed to parse message:", error);
          }
        };
      }
    },
  },
  watch: {
    user: {
      async handler() {
        if (this.user) {
          this.getUserNotifications()
          connectSocket();
          this.listenForNotifications();
          const socket = getSocket();
          if (socket) {
            this.connected = socket.readyState === WebSocket.OPEN;
          }
        } else {
          disconnectSocket();
          this.connected = false;
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    disconnectSocket();
    this.connected = false;
  },
};
</script>

<style>
/* *{
  color: var(--black);
} */
html,
body {
  height: 100vh;
}

body {
  background: #f8f8f8 !important;
}

:root {
  /* bg-color */
  --btn-bg-color: #2D69F6;
  --btn-color: #2D69F6;
  --danger-color: #FF0000;
  --warning-color: #FFC800;
  --black: #00000;
  --bg-grey: rgba(219, 219, 219, 1);
  --bg-green: rgba(44, 206, 137, 1);
  --bg-dark: #171f47;

  /* Font size */
  --extrasmall: 12px;
  --small: 14px;
}

.rounded-top {
  border-radius: 0.25rem 0.25rem 0rem 0rem !important;
}

.text-main {
  color: var(--btn-bg-color) !important;
}

.text-danger-color{
  color: var(--danger-color) !important;
}

.text-warning-color{
  color: var(--warning-color) !important;
}

.btn-primary {
  background-color: var(--btn-bg-color) !important;
}

.btn-primary:focus {
  border-color: #0062cc !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5) !important;
}

.btn-secondary {
  background-color: #F4F4F4 !important;
  border: 1px solid #EAEAEA !important;
  color: black !important
}

.text-black {
  color: black !important
}

.text_secondary {
  color: #797979 !important
}

.text_secondary_2 {
  color: #c4c4c4 !important
}

.pe-none {
  pointer-events: none !important;
}

.btn-danger:focus,
.btn-danger.focus {
  border-color: #bd2130 !important;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5) !important;
}

.btn-outline-primary {
  color: var(--btn-bg-color) !important;
  border: 1px solid var(--btn-bg-color) !important;
  background: white !important
}

.btn-secondary-2 {
  background-color: #F4F4F4 !important;
  border: 1px solid #EAEAEA !important;
}

.btn-success {
  background-color: #2CCE89 !important;
  height: 40px !important;
  width: 100px !important;
}

.btn-success:focus {
  border-color: #1e7e34 !important;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5) !important;
}

.btn-light {
  border: 1px solid #EAEAEA !important;
  background: #FFF !important
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: var(--btn-bg-color) !important;
  border-color: var(--btn-bg-color) !important;
}

.custom-checkbox .custom-control-input:checked:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(94, 114, 228, 0.25)
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}

.custom-checkbox .custom-control-input:active~.custom-control-label::before {
  background-color: #C8FFC8;
}

.table.b-table>tbody>.table-active,
.table.b-table>tbody>.table-active>th,
.table.b-table>tbody>.table-active>td {
  background-color: transparent !important;
  outline: none !important;
}

.btn-danger {
  background-color: var(--danger-color) !important;
}

.btn-danger:hover {
  color: #fff !important;
  background-color: var(--danger-color) !important;
  border-color: var(--danger-color) !important;
}

.text-tertiary {
  color: #797979 !important;
}

.w-100px {
  width: 100px !important;
}

.w-125px {
  width: 125px !important;
}

.b-icon :not(svg) {
  color: #fff;
}

button {
  border: none !important;
}

/* .btn{
  font-weight: 600 !important;
} */

button:focus,
input:focus {
  box-shadow: unset !important;
}

.input::placeholder {
  font-size: var(--small);
}

.table> :not(:first-child) {
  border-top: none !important;
}

.text-danger-2 {
  color: var(--danger-color) !important
}

.v-application--wrap {
  min-height: auto !important;
}

.theme--light.v-application {
  background-color: transparent !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  /* margin-top: 60px; */
  /* background: #E5E5E5; */
}

.vs__dropdown-toggle,
.vue-tel-input {
  min-height: 38px;
}

td {
  font-size: 14px;
}

.f-12 {
  font-size: 12px !important;
}

.v-select {
  border-radius: 5px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-14 {
  font-size: 14px !important;
}
.f-16 {
  font-size: 16px !important;
}


.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px;
}

.f-25 {
  font-size: 25px !important;
}

.f-32 {
  font-size: 32px !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.input-group-text {
  text-transform: lowercase !important;
}

.mb-25 {
  margin-bottom: 25px;
}

body.modal-open .modal {
  display: flex !important;
  height: 100%;
}

body.modal-open .modal .modal-dialog {
  margin: auto;
}

.bg-secondary-2 {
  background-color: #fafafa !important;
}

.spinner {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 5rem !important;
  height: 5rem !important;
}

.table td,
.table th {
  vertical-align: middle;
  white-space: pre;
}

.table th {
  min-width: 100px;
}

.table-striped tbody tr:nth-of-type(odd)* {
  background-color: #fafafa !important;
}

.table td>p {
  margin-bottom: 0 !important;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom: none !important;
}

.w-f-content {
  width: auto !important;
}

.modal-backdrop {
  opacity: 0.8 !important;
}

.dropdown-item:hover {
  background-color: transparent !important;
}

label {
  font-weight: 500 !important;
}

.dropdown-dark-section>button::after {
  color: white;
}

.header-section-dropdown .btn {
  padding: 0rem 0.75rem !important;
}

.notification-dropdown .btn {
  padding: 0px !important;
}

@media (max-width: 576px) {
  body.modal-open .modal {
    display: block !important;
    height: 100%;
  }
}
</style>
