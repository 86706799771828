<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" backdrop shadow :visible="!!driverID" :right="true"
            no-header-close no-close-on-backdrop no-close-on-esc>
            <template #header="{ }" v-if="currentTeamMember">
                <div class="d-flex align-items-center w-100 justify-content-between">
                    <div class="d-flex align-items-center w-100 mr-2">
                        <div class="text-nowrap" v-if="currentTeamMember.user">
                            {{ currentTeamMember.user.first_name }} {{ currentTeamMember.user.last_name }}
                        </div>
                        <!-- <b-button
                            class="ml-5 export pe-none d-flex justify-content-center align-items-center w-100px h-40px"
                            :class="currentTeamMember.status
                                ? 'equipment'
                                : 'inactive-equipment'
                                " :variant="currentTeamMember.status ? 'primary' : 'secondary'">
                            <p class="mb-0">{{ currentTeamMember.status ? 'Active' : "Inactive" }}</p>
                        </b-button> -->
                    </div>
                    <b-dropdown id="dropdown-divider" text="text" class="load-dropdown mt-0" v-if="allowUpdateDrivers">
                        <template slot="button-content">
                            <b-icon class="dots" icon="three-dots"></b-icon>
                        </template>
                        <div>
                            <b-dropdown-item-button class="mb-2" v-if="currentTeamMember.status"
                                @click="showMemberModal = true">
                                <p class="text_secondary cursor-pointer mb-0 f-14">Deactivate</p>
                            </b-dropdown-item-button>
                            <b-dropdown-item-button class="mb-0" v-else @click="showMemberModal = true">
                                <p class="text_secondary cursor-pointer mb-0 f-14">Activate</p>
                            </b-dropdown-item-button>
                        </div>
                        <b-dropdown-item-button class="mb-2" v-if="currentTeamMember.status
                            " @click="$bvModal.show('modal-password'); showPassword = false; password = ''">
                            <p class="text_secondary cursor-pointer mb-0 f-14">Set Password</p>
                        </b-dropdown-item-button>
                        <b-dropdown-item-button v-if="currentTeamMember.status" @click="$bvModal.show('send-invite-modal')">
                            <p class="text_secondary cursor-pointer mb-0 f-14">Send Invite</p>
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </template>
            <div class="body" v-if="currentTeamMember">
                <div class="sidebar-tabs">
                    <div class="bg-white rounded">
                        <v-tabs class="team-tabs mb-0" v-model="activeTab">
                            <v-tab key='info'>Info</v-tab>
                            <v-tab key='documents' v-if="allowViewDocDrivers">Documents</v-tab>
                            <v-tab key='notes'>Notes</v-tab>
                            <v-tab key='activity' v-if="showActivitySection">Activity</v-tab>
                        </v-tabs>
                    </div>
                </div>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item key='info'>
                        <hr class="mt-0" />
                        <div class="d-block text-center mt-4rem">
                            <b-row class="align-items-center px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                                <b-col md="6" sm="12" class="mt-3" v-if="currentTeamMember.user">
                                    <b-form-group label="First Name * :" class="text-left mb-0">
                                        <b-form-input v-model="currentTeamMember.user.first_name" type="text"
                                            placeholder="Enter First Name" class="unit" :disabled="!allowUpdateDrivers"
                                            required></b-form-input>
                                        <p v-if="errors &&
                                            errors.user &&
                                            errors.user.first_name
                                            " class="field-error">
                                            {{ errors.user.first_name[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3" v-if="currentTeamMember.user">
                                    <b-form-group label="Last Name * :" class="text-left mb-0">
                                        <b-form-input v-model="currentTeamMember.user.last_name" type="text"
                                            placeholder="Enter Last Name" class="unit" :disabled="!allowUpdateDrivers"
                                            required></b-form-input>
                                        <p v-if="errors &&
                                            errors.user &&
                                            errors.user.last_name
                                            " class="field-error">
                                            {{ errors.user.last_name[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Username * :" class="text-left mb-0">
                                        <b-form-input type="text" autocomplete="new-username" placeholder="Enter Username"
                                            v-model="memberUsername" :disabled="!allowUpdateDrivers"
                                            class="unit"></b-form-input>
                                        <p v-if="errors &&
                                            errors.user &&
                                            errors.user.username
                                            " class="field-error">
                                            {{ errors.user.username[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <b-form-group label="Status:" class="text-left mb-0">
                                        <b-form-input type="text" :value="currentTeamMember.status ? 'Active' : 'Inactive'"
                                            placeholder="Enter Status" disabled></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3" v-if="currentTeamMember.user">
                                    <b-form-group label="Email:" class="text-left mb-0">
                                        <b-form-input v-model="currentTeamMember.user.email" :disabled="!allowUpdateDrivers"
                                            type="text" placeholder="Enter Email" class="unit" required></b-form-input>
                                        <p v-if="errors &&
                                            errors.email
                                            " class="field-error">
                                            {{ errors.email[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Phone:" class="text-left position-relative mb-0">
                                        <b-input-group size="lg" class="w-100" prepend="+1">
                                            <b-form-input v-mask="phoneMask" class="f-14" v-model="driverPhoneNumber"
                                                :disabled="!allowUpdateDrivers"></b-form-input>
                                        </b-input-group>
                                        <p v-if="errors && errors.phone" class="field-error">
                                            {{ errors.phone[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="px-4">
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="License #:" class="text-left mb-0">
                                        <b-form-input :disabled="!allowUpdateDrivers"
                                            v-model="currentTeamMember.license_number" type="text"
                                            placeholder="Enter License #" class="unit" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3" v-if="currentTeamMember.state">
                                    <label class="state">State:</label>
                                    <v-select class="f-12" :options="states" :reduce="(option) => option.id"
                                        v-model="currentTeamMember.license_state.id" :disabled="!allowUpdateDrivers" />
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Tags</h3>
                                <b-col md="12" class="mt-3">
                                    <v-select class="f-12" :options="allTags" multiple :reduce="(option) => option.id"
                                        v-model="existingTags" :disabled="!allowUpdateDrivers" />
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Division</h3>
                                <b-col sm="12" class="mt-3">
                                    <v-select class="f-12" :clearable="false" :options="allDivisons"
                                        v-model="currentTeamMember.division.id" :reduce="(option) => option.id" />
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Details</h3>
                                <!-- <b-col md="6" sm="12" class="text-left select_arrow mt-3">
                                    <label>Type:</label>
                                    <b-form-select class="f-12" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                        $store.state.user.user.team_member.role !== 'Operations'" :options="[
        { value: null, text: '' },
        { value: 'Company', text: 'Company' },
        {
            value: 'Owner Operator',
            text: 'Owner Operator',
        },
    ]" v-model="currentTeamMember.driver_type"></b-form-select>
                                    <p v-if="errors && errors.driver_type" class="field-error mb-lg-3">
                                        {{ errors.driver_type[0] }}
                                    </p>
                                </b-col> -->
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Driver ID:" class="text-left mb-0">
                                        <b-form-input :disabled="!allowUpdateDrivers"
                                            v-model="currentTeamMember.employee_id" type="text"
                                            placeholder="Enter Driver ID" class="unit" required></b-form-input>
                                        <p v-if="errors && errors.employee_id" class="field-error">
                                            {{ errors.employee_id[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <!-- <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Driver Pin:" class="text-left mb-0">
                                        <b-form-input :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                            $store.state.user.user.team_member.role !== 'Operations'"
                                            v-model="currentTeamMember.pin_code" type="text" placeholder="Enter Pin"
                                            class="unit" required></b-form-input>
                                        <p v-if="errors && errors.pin_code" class="field-error">
                                            {{ errors.pin_code[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col> -->
                                <!-- <b-col md="6" sm="12" class="text-left select_arrow mt-3">
                                    <label>CDL:</label>
                                    <b-form-select class="f-12" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                        $store.state.user.user.team_member.role !== 'Operations'" :options="[
        {
            value: null,
            text: '',
        },
        {
            value: true,
            text: 'Yes',
        },
        {
            value: false,
            text: 'No',
        },
    ]" v-model="currentTeamMember.CDL"></b-form-select>
                                </b-col> -->
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label for="dob-datepicker" class="state">DOB:</label>
                                    <date-picker v-model="currentTeamMember.dob" :formatter="momentFormat"
                                        :range-separator="' - '" @clear="currentTeamMember.dob = ''"
                                        placeholder="Select Date" class="date-picker-custom" :teleport="'body'"
                                        :disabled="!allowUpdateDrivers"></date-picker>
                                    <p v-if="errors && errors.dob" class="field-error">
                                        {{ errors.dob[0] }}
                                    </p>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label for="active-datepicker" class="state">Active Date:</label>
                                    <date-picker v-model="currentTeamMember.activate_date" :formatter="momentFormat"
                                        :range-separator="' - '" @clear="currentTeamMember.activate_date = ''"
                                        placeholder="Select Date" class="date-picker-custom" :teleport="'body'"
                                        :disabled="!allowUpdateDrivers"></date-picker>
                                    <p v-if="errors && errors.activate_date" class="field-error">
                                        {{ errors.activate_date[0] }}
                                    </p>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label for="active-datepicker" class="state">Inactive Date:</label>
                                    <date-picker v-model="currentTeamMember.inactive_date" :formatter="momentFormat"
                                        :range-separator="' - '" @clear="currentTeamMember.inactive_date = ''"
                                        placeholder="Select Date" class="date-picker-custom" :teleport="'body'"
                                        :disabled="!allowUpdateDrivers"></date-picker>
                                    <p v-if="errors && errors.inactive_date" class="field-error">
                                        {{ errors.inactive_date[0] }}
                                    </p>
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Assigned Equipment & Co Driver</h3>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Vehicle:</label>
                                    <v-select class="f-12" :disabled="!allowUpdateDrivers" :options="vehicleOptions"
                                        v-model="defaultVehicle" :reduce="(option) => option.id" @search="searchVehicle">
                                        <span slot="no-options">
                                            Search Unit No...
                                        </span>
                                    </v-select>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label class="state">Trailer:</label>
                                    <v-select class="f-12" :disabled="!allowUpdateDrivers" :options="trailerOptions"
                                        v-model="defaultTrailer" :reduce="(option) => option.id" @search="searchTrailer">
                                        <span slot="no-options">
                                            Search Unit No...
                                        </span>
                                    </v-select>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3"
                                    v-if="currentTeamMember.is_co_driver && currentTeamMember.co_driver">
                                    <b-form-group label="Lead Driver" class="text-left mb-0">
                                        <b-form-input v-model="currentTeamMember.co_driver.name" class="bg_white"
                                            disabled />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3" v-else>
                                    <label class="state">Co Driver:</label>
                                    <v-select class="f-12" :disabled="!allowUpdateDrivers" :options="coDrivers"
                                        v-model="driver.co_driver" :reduce="(option) => option.id" @input="sameAsDrivername"
                                        @search="searchDriver">
                                        <span slot="no-options">
                                            Search Name...
                                        </span>
                                    </v-select>
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4 mb-7rem">
                                <h3 class="f-18 font-weight-bold col-12 text-left">App User Status</h3>
                                <b-col md="6" sm="12" class="text-left mt-3" v-if="currentTeamMember.user">
                                    <label>Active:</label>
                                    <b-form-checkbox :disabled="!allowUpdateDrivers"
                                        v-model="currentTeamMember.user.is_active" name="check-button" switch size="lg"
                                        class="ml-2"></b-form-checkbox>
                                    <p v-if="errors && errors.app_user" class="field-error">
                                        {{ errors.app_user[0] }}
                                    </p>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3 d-none d-md-block">
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label>Lock Vehicle:</label>
                                    <b-form-checkbox :disabled="!allowUpdateDrivers"
                                        v-model="currentTeamMember.lock_vehicle" name="check-button" switch size="lg"
                                        class="ml-2"></b-form-checkbox>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label>Lock Trailer:</label>
                                    <b-form-checkbox :disabled="!allowUpdateDrivers"
                                        v-model="currentTeamMember.lock_trailer" name="check-button" switch size="lg"
                                        class="ml-2"></b-form-checkbox>
                                </b-col>
                            </b-row>
                        </div>
                    </v-tab-item>
                    <v-tab-item key='documents' v-if="allowViewDocDrivers">
                        <div class="d-block body mb-7rem">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Documents</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    v-if="allowCreateDocDrivers" @click="addDocument">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Document</p>
                                </div>
                            </div>
                            <b-table show-empty responsive :fields="fields" :items="items" hover
                                :current-page="currentPage">
                                <template #cell(name)="data">
                                    <a :href="`#${data.value
                                        .replace(/[^a-z]+/i, '-')
                                        .toLowerCase()}`">{{ data.value }}</a>
                                </template>
                                <template #cell(description)="data">
                                    <span class="item-description" v-if="data.value && data.value !== 'null'">{{ data.value
                                    }}</span>
                                    <span v-else>-</span>
                                </template>
                                <template #cell(expiry_date)="data">
                                    <span v-html="data.value"></span>
                                </template>
                                <template #cell(driver)="data">
                                    <b-form-checkbox :disabled="!allowUpdateDocDrivers" v-model="data.value"
                                        name="check-button" switch @change="
                                            updateDocumentOnToggle(data.item.id, 'driver', data.value)
                                            " size="lg" class="ml-2" />
                                </template>
                                <template #cell(action)="data">
                                    <svg @click="editDocument(data.item.id, data.item.pk)" class="cursor-pointer mr-4"
                                        width="20" height="15" viewBox="0 0 20 15" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.6426 6.19707C18.8726 6.48384 19 6.85642 19 7.24273C19 7.62904 18.8726 8.00161 18.6426 8.28838C17.186 10.052 13.8704 13.4854 10 13.4854C6.12957 13.4854 2.81403 10.052 1.35741 8.28838C1.12735 8.00161 1 7.62904 1 7.24273C1 6.85642 1.12735 6.48384 1.35741 6.19707C2.81403 4.4335 6.12957 1 10 1C13.8704 1 17.186 4.4335 18.6426 6.19707Z"
                                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M10.0007 10.0154C11.533 10.0154 12.7752 8.77323 12.7752 7.24091C12.7752 5.70859 11.533 4.4664 10.0007 4.4664C8.46839 4.4664 7.2262 5.70859 7.2262 7.24091C7.2262 8.77323 8.46839 10.0154 10.0007 10.0154Z"
                                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <svg @click="downloadDoc(data.item.pk, data.item.name)" class="mr-4 cursor-pointer"
                                        width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.53943 11.3822L10.001 14.8437L13.4625 11.3822" stroke="#FFC800"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.0001 14.8497V5.15741" stroke="#FFC800" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                                            stroke="#FFC800" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <!-- <svg @click="editDocument(data.item.id)" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                                        $store.state.user.user.team_member.role === 'Operations'"
                                        class="mr-4 cursor-pointer" width="21" height="21" viewBox="0 0 21 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                                            stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg> -->
                                    <svg @click="showPopup(data.item.pk)" class="cursor-pointer" width="19"
                                        v-if="allowDeleteDocDrivers" height="20" viewBox="0 0 19 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </template>
                            </b-table>
                        </div>
                    </v-tab-item>
                    <v-tab-item key="notes">
                        <div class="mt-4rem">
                            <Notes :key="currentTeamMember.id" :noteURL="'app/driver/' + currentTeamMember.id + '/note'"
                                :allowUpdate="allowUpdateDrivers" @updateNotes="$emit('updateData', true)" />
                        </div>
                    </v-tab-item>
                    <v-tab-item key="activity" v-if="showActivitySection">
                        <div class="mt-4rem mb-6rem">
                            <ActivityLogs :key="currentTeamMember.id" :record="currentTeamMember.name"
                                :object_id="currentTeamMember.id" />
                        </div>
                    </v-tab-item>
                </v-tabs-items>
                <div class="sidebar-footer position-fixed">
                    <div class="d-flex align-items-center justify-content-end" v-if="allowUpdateDrivers">
                        <div class="text-right modal-footer border-0 p-0">
                            <b-button class="mx-0 h-40px w-100px m-0" @click="closePopup" variant="secondary"
                                :class="hasChanged && allowUpdateDrivers ? 'mr-3' : ''">Close</b-button>
                            <b-button class="mx-0 mb-0 h-40px w-100px m-0" v-if="hasChanged && allowUpdateDrivers"
                                variant="primary" @click="updateTeamMemberData">Save</b-button>
                        </div>
                    </div>
                    <div v-else class="text-right">
                        <b-button class="mx-0 h-40px w-100px m-0" @click="closePopup" variant="secondary">Close</b-button>
                    </div>
                </div>
            </div>
        </b-sidebar>
        <b-modal id="modal-password" centered no-close-on-esc no-close-on-backdrop
            :footer-class="'d-block px-3 pt-0 pb-3 m-0'">
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Set Password
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <b-row align-v="center">
                    <b-col md="12">
                        <b-form-group label="Password"
                            class="text-left label font-weight-normal password show-password-section">
                            <b-form-input id="pass-field" v-model="password" :type="showPassword ? 'text' : 'password'"
                                class="mb-2" placeholder="Enter Password" />
                            <b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"
                                @click="showPassword = !showPassword"></b-icon>
                            <p v-if="errors && errors.password" class="field-error float-start">
                                {{ errors.password[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <div class="text-right modal-footer border-0 p-0 mx-2">
                    <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="$bvModal.hide('modal-password')"
                        variant="secondary">Cancel</b-button>
                    <div class="d-flex align-items m-0">
                        <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="setPass">Save</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
        <DeleteModal v-if="showDocumentModal" @confirm="deleteDocumentItem($event)" />
        <DeleteModal v-if="showMemberModal" @confirm="changeDriverStatusItem($event)"
            :message="currentTeamMember.status ? 'Do you want to Deactivate this Driver?' : 'Do you want to Activate this Driver?'"
            :removeMessage="currentTeamMember.status ? 'Deactivate' : ''"
            :successMessage="!currentTeamMember.status ? 'Activate' : ''" />
        <AddTags v-if="showTagsModal" @confirmTags="addTag($event)" :existingTags="currentTeamMember.tags" :type="'Driver'"
            :driverID="driverID" :co_driver="driver.co_driver" />
        <b-modal id="modal-document" centered hide-footer @shown="resetField" no-close-on-backdrop size="lg"
            :body-class="'px-3 pt-2 overflow-preview-auto'" no-close-on-esc :footer-class="'d-block border-0 pt-0 pb-3 m-0'"
            :header-class="'align-items-center'" scrollable>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Document
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
                <b-dropdown id="dropdown-preview" class="load-dropdown mt-0" v-if="editDocumentFlag">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button
                        :class="(fileName && allowUpdateDocDrivers && editDocumentFlag) || allowDeleteDocDrivers ? 'mb-2' : ''"
                        @click="downloadDoc(documentData.id, `${currentTeamMember.user.first_name} ${currentTeamMember.user.last_name}-${documentData.description ? documentData.description : ''}`)">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Download</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button class='mb-2' v-if="(fileName && allowUpdateDocDrivers && editDocumentFlag)"
                        :class="allowDeleteDocDrivers ? 'mb-2' : ''" @click="removeFile()">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="showPopup(documentData.id)" v-if="allowDeleteDocDrivers">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
                    </b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown id="dropdown-preview" text="text" class="load-dropdown mt-0"
                    v-if="!editDocumentFlag && fileName">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button @click="removeFile()">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                    </b-dropdown-item-button>
                </b-dropdown>
            </template>
            <div class="d-block text-center">
                <b-row>
                    <b-col lg="4" md="8" sm="12">
                        <div class="text-left mb-3">
                            <label for="modal-datepicker" class="state">Date:</label>
                            <date-picker v-model="documentData.date" :formatter="momentFormat" :range-separator="' - '"
                                @clear="documentData.date = ''" placeholder="Select Date" class="date-picker-custom"
                                :teleport="'body'" :disabled="!allowUpdateDocDrivers"></date-picker>
                            <p v-if="errors && errors.date" class="field-error">
                                {{ errors.date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label for="modal-expiry-date" class="state">Expiry Date:</label>
                            <date-picker v-model="documentData.expiry_date" :formatter="momentFormat"
                                :range-separator="' - '" @clear="documentData.expiry_date = ''" placeholder="Select Date"
                                class="date-picker-custom" :teleport="'body'"
                                :disabled="!allowUpdateDocDrivers"></date-picker>
                            <p v-if="errors && errors.expiry_date" class="field-error">
                                {{ errors.expiry_date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Type:</label>
                            <b-form-select class="f-12" :options="getDotOptions" v-model="documentData.doc_type"
                                @change="setDocDescription($event)" :disabled="!allowUpdateDocDrivers">
                            </b-form-select>
                            <p v-if="errors && errors.doc_type" class="field-error">
                                {{ errors.doc_type[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Description:</label>
                            <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                                v-model="documentData.description" :disabled="!allowUpdateDocDrivers"></b-form-textarea>
                            <p v-if="errors && errors.description" class="field-error">
                                {{ errors.description[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Driver:</label>
                            <b-form-checkbox v-model="documentData.driver" :disabled="!allowUpdateDocDrivers"
                                name="check-button" switch size="lg" class="ml-0-6rem" />
                        </div>
                    </b-col>
                    <b-col lg="8" sm="12">
                        <div class="upload-btn-wrapper" v-if="!fileName">
                            <b-img :src="require('@/assets/drop.svg')"></b-img>
                            <button v-if="!fileName" class="foo">
                                Drop your document here, or browse
                            </button>
                            <button v-else class="foo">{{ fileName }}</button>

                            <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                                accept=".pdf, .png, .jpg, .jpeg" />
                            <p v-if="errors && errors.document" class="field-error">
                                {{ errors.document[0] }}
                            </p>
                        </div>
                        <div v-else>
                            <div class="review-media-section" :class="mediaType === 'pdf' ? 'overflow-hidden' : ''">
                                <iframe class="w-100 h-100 border-0" v-if="mediaType === 'pdf'" :src="media"></iframe>
                                <div v-else>
                                    <div id="panzoom-element" ref="panzoomElement">
                                        <img :src="media" class="w-100 h-100 img-section" />
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center zoom-section">
                                        <b-icon @click="zoom(1)" icon="zoom-in"
                                            style="fill:white; cursor: pointer;"></b-icon>
                                        <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out"
                                            style="fill:white;cursor: pointer;"></b-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-4">
                <b-button class="mx-0 h-40px w-100px m-0" @click="$bvModal.hide('modal-document'); panzoom = null"
                    variant="secondary" :class="hasDocChanged && hasDocChanged ? 'mr-3' : ''">{{ editDocumentFlag ? 'Close'
                        : 'Cancel'
                    }}</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="sendDocumentData"
                    v-if="hasDocChanged && allowUpdateDocDrivers">Save</b-button>
            </div>
        </b-modal>
        <SendInvite v-if="driverID" @sendInvite="sentInvitetoDriver($event)" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import "vue-select/dist/vue-select.css";
import { areAllDigitsSame } from '../../services/helper';
import AddTags from "@/components/Equipment/AddTags.vue";
import vSelect from "vue-select";
import DeleteModal from "@/components/DeleteModal.vue";
import * as moment from "moment-timezone";
import generateQRCode from '../../services/generateDriverQRCode';
import { checkEmail, checkValueExists, checkPhoneFormat } from "../../services/helper";
import SendInvite from "./SendInvite.vue";
import ActivityLogs from "@/components/Shared/ActivityLogs.vue"
import Panzoom from '@panzoom/panzoom'
import Notes from "../Shared/Notes";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    props: ["driverID", "states", "options", "setNotesSection"],
    components: { DeleteModal, AddTags, SendInvite, vSelect, ActivityLogs, Notes, DatePicker },
    name: "Edit-Teammember",
    data() {
        return {
            checkEmail,
            checkValueExists,
            checkPhoneFormat,
            panzoom: null,
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            userActiveStatus: false,
            qrCodeUrl: null,
            existingTags: [],
            media: null,
            mediaType: null,
            docName: null,
            mediaID: null,
            activeTab: "info",
            fileName: "",
            coDrivers: [],
            password: "",
            showPassword: false,
            vehicleOptions: [],
            trailerOptions: [],
            showTagsModal: false,
            showDocumentModal: false,
            showMemberModal: false,
            memberPinCode: null,
            memberUsername: "",
            emptyTable: false,
            errors: {},
            itemId: null,
            num_docs: 0,
            currentPage: 1,
            defaultVehicle: null,
            defaultTrailer: null,
            items: [],
            driver: {
                sameAsDriver: false,
                co_driver: null,
            },
            fields: [
                {
                    key: "Description",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "expiry_date",
                    label: "Expiry Date",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Driver",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            editDocumentFlag: false,
            documentIndex: null,
            documentData: {
                object_id: null,
                date: null,
                type: null,
                expiry_date: "",
                document: null,
                description: "",
                driver: true,
                doc_type: ""
            },
            defaultData: {},
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            defaultDocsData: {},
            documentOptions: [
                {
                    value: null,
                    text: "",
                },
                {
                    value: "License",
                    text: "License",
                },
                {
                    value: "MVR",
                    text: "MVR",
                },
                {
                    value: "Medical Card",
                    text: "Medical Card",
                },
                {
                    value: "Insurance",
                    text: "Insurance",
                },
                {
                    value: "Drug Test",
                    text: "Drug Test",
                },
                {
                    value: "Other",
                    text: "Other",
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            "currentTeamMember",
            "company",
            "allDrivers",
            "allEquipments",
            "userCompany",
            "tags",
            "docTypeItems",
            "defaultDocType",
            "divisions",
            'rolePermissions'
        ]),
        showActivitySection() {
            return this.rolePermissions && this.rolePermissions['Activity Log'] && this.rolePermissions['Activity Log'][0].allow
        },
        allowUpdateDrivers() {
            return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][2].allow
        },
        allowViewDocDrivers() {
            return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][7].allow
        },
        allowCreateDocDrivers() {
            return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][9].allow
        },
        allowUpdateDocDrivers() {
            return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][8].allow
        },
        allowDeleteDocDrivers() {
            return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][10].allow
        },
        allDivisons() {
            return this.divisions.map((item) => {
                return {
                    id: item.id,
                    label: item.division,
                }
            })
        },
        hasChanged() {
            return this.defaultData.user?.username?.split(
                `.${this.company[0]?.company_ID}`
            )[0] != this.memberUsername || this.defaultData.driverPhoneNumber !== this.driverPhoneNumber  || this.checkValueExists(this.defaultData['default_vehicle']?.id) != this.defaultVehicle || this.checkValueExists(this.defaultData['default_trailer']?.id) != this.defaultTrailer || this.checkValueExists(this.defaultData['co_driver']?.id) != this.driver.co_driver || this.existingTags.toString() !== this.currentTeamMember?.tags?.map((tag) => tag.id).toString() || Object.keys(this.currentTeamMember).filter(field => {
                if (field !== 'docs' && field !=='phone' && field !== 'updated_at' && field !== 'company' && field !== 'co_driver' && field !== 'default_vehicle' && field !== 'default_trailer' && field !== 'dispatch_name') {
                    if (typeof this.currentTeamMember[field] === 'object') return JSON.stringify(this.currentTeamMember[field]) !== JSON.stringify(this.defaultData[field])
                    else if (!this.currentTeamMember[field] && !this.defaultData[field]) return false
                    else return this.checkValueExists(this.currentTeamMember[field]) != this.checkValueExists(this.defaultData[field])
                } return false
            }).length > 0
        },
        hasDocChanged() {
            return Object.keys(this.documentData).filter(field => {
                if (field !== 'updated_at') {
                    if (typeof this.documentData[field] === 'object') return JSON.stringify(this.documentData[field]) !== JSON.stringify(this.defaultDocsData[field])
                    if (field === 'description') {
                        return this.checkValueExists(this.documentData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n')) != this.checkValueExists(this.defaultDocsData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n'));
                    }
                    else if (!this.documentData[field] && !this.defaultDocsData[field]) return false
                    else return this.checkValueExists(this.documentData[field]) != this.checkValueExists(this.defaultDocsData[field])
                } return false
            }).length > 0
        },
        driverPhoneNumber: {
            get: function () {
                return this.currentTeamMember.phone ? this.currentTeamMember.phone.startsWith("+1") ? this.currentTeamMember.phone.slice(2).trim() : this.currentTeamMember.phone : ''
            },
            set: function (newValue) {
                this.currentTeamMember.phone = newValue
            }
        },
        phoneMask() {
            return this.driverPhoneNumber.length > 0
                ? [
                    /\d/, /\d/, /\d/, " ",
                    /\d/, /\d/, /\d/, " ",
                    /\d/, /\d/, /\d/, /\d/
                ]
                : false; // Allows empty input
        },
        getDotOptions() {
            return [...[{
                value: "",
                text: "",
            }], ...this.docTypeItems?.filter((item) => item.model === 'Driver' || item.model === 'All').map((item) => {
                return {
                    value: item.id,
                    text: item.type,
                }
            })]
        },
        allTags() {
            return this.tags.map((item) => {
                return {
                    id: item.id,
                    label: item.add_tag,
                }
            })
        }
    },
    watch: {
        currentTeamMember() {
            if (this.currentTeamMember) this.existingTags = this.currentTeamMember?.tags.map((item) => item.id);
        },
        async driverID() {
            if (this.driverID) {
                this.activeTab = 'info'
                try {
                    this.setLoader(true)
                    await this.getTeamMember(this.driverID);
                    await this.getAllTags(this.userCompany);
                    this.userActiveStatus = this.currentTeamMember.status
                    this.memberUsername = this.currentTeamMember.user.username.split(
                        `.${this.company[0].company_ID}`
                    )[0];
                    if (this.currentTeamMember.dob) {
                        this.currentTeamMember.dob = moment.tz(this.currentTeamMember.dob, this.userTimeZone).toDate();
                    }
                    if (this.currentTeamMember.activate_date) {
                        this.currentTeamMember.activate_date = moment.tz(this.currentTeamMember.activate_date, this.userTimeZone).toDate();
                    }
                    if (this.currentTeamMember.inactive_date) {
                        this.currentTeamMember.inactive_date = moment.tz(this.currentTeamMember.inactive_date, this.userTimeZone).toDate();
                    }
                    this.defaultData = JSON.parse(JSON.stringify({...this.currentTeamMember, driverPhoneNumber: this.currentTeamMember.phone ? this.currentTeamMember.phone.startsWith("+1") ? this.currentTeamMember.phone.slice(2).trim() : this.currentTeamMember.phone : ''}))
                    if (this.setNotesSection) {
                        if (this.allowViewDocDrivers) {
                            this.activeTab = 2
                        }
                        else this.activeTab = 1
                    }
                    if (this.currentTeamMember.default_vehicle) {
                        this.defaultVehicle = this.currentTeamMember.default_vehicle.id;
                        if (this.currentTeamMember.default_vehicle) {
                            this.vehicleOptions.push({
                                id: this.currentTeamMember.default_vehicle.id,
                                label: this.currentTeamMember.default_vehicle.unit_no,
                            });
                        }
                    }
                    else {
                        this.defaultVehicle = null
                        this.vehicleOptions = []
                    }
                    if (this.currentTeamMember.default_trailer) {
                        this.defaultTrailer = this.currentTeamMember.default_trailer.id;
                        if (this.currentTeamMember.default_trailer) {
                            this.trailerOptions.push({
                                id: this.currentTeamMember.default_trailer.id,
                                label: this.currentTeamMember.default_trailer.unit_no,
                            });
                        }
                    } else {
                        this.defaultTrailer = null
                        this.trailerOptions = []
                    }
                    await this.$nextTick();
                    if (this.currentTeamMember.co_driver) {
                        this.coDrivers.push({
                            id: this.currentTeamMember.co_driver.id,
                            label: this.currentTeamMember.co_driver.name,
                        });
                        this.coDrivers = this.coDrivers.sort((a, b) =>
                            a.label.localeCompare(b.label)
                        );
                    } else this.coDrivers = []
                    if (this.currentTeamMember.role === "Driver" && this.currentTeamMember.pin_code) {
                        this.memberPinCode = this.currentTeamMember.pin_code;
                    }
                    this.driver.co_driver = this.currentTeamMember.co_driver
                        ? this.currentTeamMember.co_driver.id
                        : null;
                    this.updateDocs();
                    this.setLoader(false)
                }
                catch (e) {
                    console.log(e)
                }
            }
        },
    },
    methods: {
        ...mapActions([
            "getTeamMember",
            "getAllTags",
            "setPasswordforAllDrivers",
            "setNotification",
            "getAllDrivers",
            "updateTeamMember",
            "deleteTeamMember",
            "setLoader",
            "sendInvite",
            "sendTeamMemberDocument",
            "updateTeamMemberDocument",
            "deleteCompanyDocument",
            "getAllEquipments",
            "retrieveDocument",
            "changeDriverStatus",
            "sendBulkInvite"
        ]),
        zoom(level) {
            if (!this.panzoom) {
                this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
                    maxScale: 5
                })
            }
            level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
        },
        setDocDescription(event) {
            if (event) {
                this.documentData.description = this.getDotOptions.filter((option) => option.value === event)[0].text
            }
            else this.documentData.description = this.defaultDocsData.description
        },
        async downloadDoc(id, name) {
            this.setLoader(true)
            const doc = await this.retrieveDocument(id);
            fetch(doc.document)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const newUrl = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = newUrl;
                    const urlWithoutParams = doc.document.split('?')[0];
                    const extension = urlWithoutParams.split('.').pop();
                    a.download = name + "." + extension
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(newUrl);
                    a.remove()
                    this.setLoader(false)
                })
                .catch(error => {
                    this.setLoader(false)
                    console.log(error)
                });
        },
        async openDoc(id) {
            const doc = await this.retrieveDocument(id);
            this.media = doc.document
            this.mediaID = id
            this.itemId = id;
            if (doc.document.includes('.pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
        },
        async updateDriverStatus() {
            try {
                this.setLoader(true);
                const driver = await this.changeDriverStatus({
                    status: !this.currentTeamMember.status,
                    driver_ids: [this.currentTeamMember.id]
                })
                await this.setNotification({
                    msg: `Driver ${driver.status ? 'Activated' : 'Deactivated'} Successfully`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
                this.$emit("updateData", true);
                this.$emit("resetDriver", true);
            }
            catch (e) {
                this.setLoader(false)
                console.log(e)
            }
        },
        async sentInvitetoDriver(password) {
            if (this.currentTeamMember.user.email && this.checkEmail(this.currentTeamMember.user.email)) {
                try {
                    this.setLoader(true)
                    await this.sendBulkInvite({
                        driver_ids: [this.currentTeamMember.id],
                        password: password ? password : null
                    });
                    await this.setNotification({
                        msg: "Invitation Sent",
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$bvModal.hide('send-invite-modal')
                } catch (error) {
                    this.setLoader(false)
                    if (typeof error === "object") {
                        this.errors = error;
                        // eslint-disable-next-line no-ex-assign
                        error = "Couldnot send email";
                    }
                    console.log(error);
                    await this.setNotification({
                        msg: error,
                        type: "error",
                        color: "red",
                    });
                }
            }
            else {
                await this.setNotification({
                    msg: 'Set Email First',
                    type: "error",
                    color: "red",
                });
            }
        },
        async setPass() {
            try {
                if (this.password) {
                    this.setLoader(true)
                    await this.setPasswordforAllDrivers({
                        driver_ids: [this.driverID],
                        password: this.password,
                    });
                    await this.setNotification({
                        msg: "Password is set successfully!!",
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$bvModal.hide("modal-password");
                } else {
                    throw {
                        password: ["Please Enter Password."],
                    };
                }
            } catch (error) {
                this.setLoader(false)
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        async editDocument(id, docID) {
            this.editDocumentFlag = true;
            this.documentIndex = id;
            this.documentData = { ...this.currentTeamMember.docs[id], doc_type: this.currentTeamMember.docs[id].doc_type_id, date: moment.tz(this.currentTeamMember.docs[id].date, this.userTimeZone).toDate(), expiry_date: this.currentTeamMember.docs[id].expiry_date ? moment.tz(this.currentTeamMember.docs[id].expiry_date, this.userTimeZone).toDate() : "" };
            if (this.documentData.document) {
                let file_name = "";
                if (typeof this.documentData.document === "string") {
                    file_name = this.documentData.document;
                } else {
                    file_name = this.documentData.document.name;
                }
                this.fileName = file_name.substring(file_name.lastIndexOf("/") + 1);
            }
            this.errors = {
                date: null,
                type: null,
                document: null,
                expiry_date: "",
                description: null,
                ...this.company[0].doc_type ? { doc_type: null } : {}
            };
            await this.openDoc(docID)
            this.defaultDocsData = JSON.parse(JSON.stringify({ ...this.documentData, doc_type: this.currentTeamMember.docs[id].doc_type_id ? this.currentTeamMember.docs[id].doc_type_id : '' }))
            this.$bvModal.show("modal-document");
        },
        showPopup(id) {
            this.showDocumentModal = true;
            this.itemId = id;
        },
        sameAsDrivername() {
            if (this.currentTeamMember.is_dispatch_name) {
                if (this.driver.co_driver && this.driver.co_driver) {
                    this.currentTeamMember.dispatch_name = this.driver.co_driver.name
                } else {
                    this.currentTeamMember.dispatch_name =
                        this.currentTeamMember.user.first_name +
                        " " +
                        this.currentTeamMember.user.last_name;
                }
            }
        },
        closePopup() {
            this.$emit("resetDriver", true);
        },
        addDocument() {
            this.fileName = null;
            this.editDocumentFlag = false;
            this.defaultDocsData = {}
            this.$bvModal.show("modal-document");
            this.documentData = {
                object_id: null,
                date: new Date(),
                type: null,
                document: null,
                expiry_date: "",
                description: null,
                driver: true,
                doc_type: ""
            };
            this.errors = {
                date: null,
                type: null,
                document: null,
                expiry_date: "",
                description: null,
                ...this.company[0].doc_type ? { doc_type: null } : {}
            };
        },
        resetField() {
            if (this.$refs.docFile) this.$refs.docFile.value = null;
        },
        async generateDriverQRCode(driverName, id, fileName) {
            if (this.currentTeamMember.pin_code) {
                this.qrCodeUrl =
                    this.$refs?.qrcode.qrious._canvasRenderer.element.toDataURL();
                generateQRCode(driverName, id, fileName, this.qrCodeUrl)
            }
            else await this.setNotification({
                msg: "Please Enter Driver's Pin Code to Generate QR Code",
                type: "error",
                color: "red",
            });
        },
        addTag(item) {
            console.log(item)
            this.showTagsModal = false;
        },
        removeFile() {
            this.fileName = null;
            // this.$refs.docFile.value = "";
            this.documentData.document = null
            this.media = null
            this.mediaType = null
        },
        async searchVehicle(searchText) {
            const url = `unit_no=${searchText}&type=Vehicle&status=Active&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.typeOptions()
        },
        async searchTrailer(searchText) {
            const url = `unit_no=${searchText}&type=Trailer&status=Active&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.subTypeOptions()
        },
        typeOptions() {
            let options = [];
            if (this.currentTeamMember.default_vehicle) {
                options.push({
                    id: this.currentTeamMember.default_vehicle.id,
                    label: this.currentTeamMember.default_vehicle.unit_no,
                });
            }
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.vehicleOptions = this.vehicleOptions.concat(options)
            this.vehicleOptions = [...new Map(this.vehicleOptions.map(item => [item['id'], item])).values()]
        },
        subTypeOptions() {
            let options = [];
            if (this.currentTeamMember.default_trailer) {
                options.push({
                    id: this.currentTeamMember.default_trailer.id,
                    label: this.currentTeamMember.default_trailer.unit_no,
                });
            }
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.trailerOptions = this.trailerOptions.concat(options)
            this.trailerOptions = [...new Map(this.trailerOptions.map(item => [item['id'], item])).values()]
        },
        async searchDriver(searchText) {
            const url = `name=${searchText}&page=1&page_size=10&status=True`
            await this.getAllDrivers(url);
            this.coDrivers = []
            this.allDrivers
                .filter(
                    (driver) =>
                        driver.id !== this.currentTeamMember.id && !driver.co_driver
                )
                .map((item) => {
                    this.coDrivers.push({
                        id: item.id,
                        label: item.name,
                    });
                });
        },
        async removeTag(tag) {
            const index = this.existingTags.indexOf(tag);
            this.existingTags.splice(index, 1);
            await this.updateTeamMemberData(false);
        },
        async updateTeamMemberData(closePopup = true, showNotification = true) {
            try {
                if (!this.memberUsername) {
                    await this.setNotification({
                        msg: "Username is required",
                        type: "error",
                        color: "red",
                    });
                    return;
                }
                if (this.memberUsername && this.memberUsername.length < 4) {
                    await this.setNotification({
                        msg: "Username should have minimum 4 characters",
                        type: "error",
                        color: "red",
                    });
                    return;
                }
                if (this.memberUsername && this.memberUsername.includes(`.${this.company[0].company_ID}`)) {
                    await this.setNotification({
                        msg: "Username can not contain .compid",
                        type: "error",
                        color: "red",
                    });
                    return;
                }
                if (this.currentTeamMember.user.email) {
                    if (!this.checkEmail(this.currentTeamMember.user.email)) {
                        throw {
                            email: ["Wrong format"],
                        };
                    }
                }
                if (this.memberUsername && (this.memberUsername.includes("@") || this.memberUsername.includes(" "))) {
                    await this.setNotification({
                        msg: "Username shouldn't contain @ or a space",
                        type: "error",
                        color: "red",
                    });
                    return;
                }
                if (this.currentTeamMember.phone) {
                    if (!this.checkPhoneFormat(this.driverPhoneNumber)) {
                        throw {
                            phone: ["Wrong format"],
                        };
                    }
                }

                if (
                    this.currentTeamMember.role === "Driver" &&
                    this.currentTeamMember.pin_code
                ) {
                    if (this.currentTeamMember.pin_code.length > 4) {
                        await this.setNotification({
                            msg: "Max 4 digits allowed",
                            type: "error",
                            color: "red",
                        });
                        return;
                    } else if (areAllDigitsSame(this.currentTeamMember.pin_code)) {
                        await this.setNotification({
                            msg: "All digits should not be same",
                            type: "error",
                            color: "red",
                        });
                        return;
                    } else this.membersPinCode = this.currentTeamMember.pin_code;
                }
                if (closePopup) this.setLoader(true)
                delete this.currentTeamMember.profile_picture;
                delete this.currentTeamMember.user.password;
                this.currentTeamMember.default_vehicle = this.defaultVehicle;
                this.currentTeamMember.name = this.currentTeamMember.user.first_name + " " + this.currentTeamMember.user.last_name
                this.currentTeamMember.default_trailer = this.defaultTrailer;
                this.currentTeamMember.status = this.userActiveStatus
                this.currentTeamMember.user.username =
                    this.memberUsername + "." + this.company[0].company_ID;
                let currentMember = {
                    ...this.currentTeamMember,
                    state: this.currentTeamMember.state.id
                        ? this.currentTeamMember.state.id
                        : 54,
                    license_state: this.currentTeamMember.license_state.id
                        ? this.currentTeamMember.license_state.id
                        : 54,
                    division: this.currentTeamMember.division.id,
                    dispatch_name:
                        this.currentTeamMember.is_dispatch_name && this.driver.co_driver
                            ? this.driver.co_driver.name
                            : this.currentTeamMember.dispatch_name,
                    dispatch_group1: this.dispatch_group1,
                    dispatch_group2: this.dispatch_group2,
                    co_driver: this.driver.co_driver,
                    activate_date: this.currentTeamMember.activate_date ? moment(this.currentTeamMember.activate_date).format("YYYY-MM-DD") : null,
                    clearing_house_date: this.currentTeamMember.clearing_house_date
                        ? this.currentTeamMember.clearing_house_date
                        : null,
                    dob: this.currentTeamMember.dob ? moment(this.currentTeamMember.dob).format("YYYY-MM-DD") : null,
                    inactive_date: this.currentTeamMember.inactive_date ? moment(this.currentTeamMember.inactive_date).format("YYYY-MM-DD") : null,
                    pre_emp_drug_test_date: this.currentTeamMember.pre_emp_drug_test_date
                        ? this.currentTeamMember.pre_emp_drug_test_date
                        : null,
                    pre_emp_mvr_date: this.currentTeamMember.pre_emp_mvr_date
                        ? this.currentTeamMember.pre_emp_mvr_date
                        : null,
                    pre_emp_clearing_house_date: this.currentTeamMember
                        .pre_emp_clearing_house_date
                        ? this.currentTeamMember.pre_emp_clearing_house_date
                        : null,
                    hazmat_date: this.currentTeamMember.hazmat_date
                        ? this.currentTeamMember.hazmat_date
                        : null,
                    insurance_date: this.currentTeamMember.insurance_date
                        ? this.currentTeamMember.insurance_date
                        : null,
                    license_date: this.currentTeamMember.license_date
                        ? this.currentTeamMember.license_date
                        : null,
                    medical_card_date: this.currentTeamMember.medical_card_date
                        ? this.currentTeamMember.medical_card_date
                        : null,
                    mvr_review_date: this.currentTeamMember.mvr_review_date
                        ? this.currentTeamMember.mvr_review_date
                        : null,
                    other_date: this.currentTeamMember.other_date
                        ? this.currentTeamMember.other_date
                        : null,
                    twic_card_date: this.currentTeamMember.twic_card_date
                        ? this.currentTeamMember.twic_card_date
                        : null,
                    lock_trailer: this.currentTeamMember.lock_trailer ? true : false,
                    phone: this.currentTeamMember.phone ? !this.currentTeamMember.phone.includes("+1") ? "+1 " + this.currentTeamMember.phone : this.currentTeamMember.phone : ''
                };
                this.currentTeamMember.years_of_experience =
                    this.currentTeamMember.years_of_experience !== ""
                        ? this.currentTeamMember.years_of_experience
                        : null;
                const updatedTeamMember = await this.updateTeamMember({
                    id: this.driverID,
                    payload: {
                        ...currentMember,
                        tags: this.existingTags.length
                            ? this.existingTags
                            : [],
                    }
                });

                if (this.currentTeamMember) {
                    if (showNotification) {
                        await this.setNotification({
                            msg: `Successfully updated ${this.currentTeamMember.user.first_name} ${this.currentTeamMember.user.last_name}`,
                            type: "success",
                            color: "green",
                        });
                    }
                    if (closePopup) {
                        this.$emit("updateDriver", updatedTeamMember);
                        this.$emit("resetDriver", true);
                    }
                    else this.$emit("updateData", true);
                    if (showNotification) this.setLoader(false)
                }

            } catch (error) {
                this.setLoader(false)
                if (Array.isArray(error)) {
                    await this.setNotification({
                        msg: error[0],
                        type: "error",
                        color: "red",
                    });
                }
                if (typeof error === "object" && error.error) {
                    await this.setNotification({
                        msg: Array.isArray(error.error) ? error.error[0] : error.error,
                        type: "error",
                        color: "red",
                    });
                }
                else if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
                else {
                    await this.setNotification({
                        msg: error,
                        type: "error",
                        color: "red",
                    })
                }
            }
        },
        deleteDocumentItem(item) {
            if (item) {
                this.deleteDocument(this.itemId);
            }
            this.showDocumentModal = false;
        },
        async deleteDocument(id) {
            try {
                this.setLoader(true)
                const docs = await this.deleteCompanyDocument(`${id}/?company_id=${this.userCompany}`);
                if (docs) {
                    this.currentTeamMember.docs = docs;
                }
                this.updateDocs();
                this.itemId = null
                await this.setNotification({
                    msg: "Document successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                await this.getTeamMember(this.driverID);
                this.setLoader(false)
                this.$bvModal.hide("modal-document");
                this.$emit("updateData", true);
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        changeDriverStatusItem(item) {
            if (item) {
                this.updateDriverStatus();
            }
            this.showMemberModal = false;
        },
        async deleteMember() {
            try {
                this.setLoader(true)
                await this.deleteTeamMember(`${this.currentTeamMember.id}/?company_id=${this.userCompany}`);
                await this.setNotification({
                    msg: "Driver successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
                this.$emit("updateData", true);
                this.$emit("resetDriver", true);
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        handleFileUpload() {
            this.documentData.document = this.$refs.docFile.files[0];
            this.fileName = this.documentData.document.name;
            this.media = URL.createObjectURL(this.documentData.document);
            if (this.$refs.docFile.files[0].type.includes('pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
        },
        updateDocs() {
            this.items = [];
            this.currentTeamMember.docs.sort((a, b) => a.description?.toLowerCase().localeCompare(b.description?.toLowerCase())).forEach((doc, id) => {
                let color;
                if (doc.expiry_date) {
                    const currentDate = new Date();
                    const expiryDate = new Date(doc.expiry_date);
                    const diffDays = parseInt(
                        (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                        10
                    );
                    if (diffDays > 31) {
                        color = "color:black";
                    } else if (diffDays <= 31 && diffDays >= 1) {
                        color = "color:#FFC800";
                    } else color = "color:#FF0000";
                }
                this.items.push({
                    id: id,
                    pk: doc.id,
                    Date: moment(doc.date).format("MMM DD, YYYY"),
                    Description: doc.description,
                    Driver: doc.driver,
                    link: doc.document,
                    expiry_date: doc.expiry_date
                        ? `<span style=${color}>${moment(doc.expiry_date).format(
                            "MMM DD, YYYY"
                        )}</span>`
                        : "-",
                    name: `${this.currentTeamMember.user.first_name} ${this.currentTeamMember.user.last_name}-${doc.description ? doc.description : ''}`
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
            });
        },
        async updateDocumentOnToggle(id, key, value) {
            try {
                this.setLoader(true)
                this.currentTeamMember.docs[id][key] = value;
                if (!this.currentTeamMember.docs[id].expiry_date)
                    delete this.currentTeamMember.docs[id].expiry_date;
                delete this.currentTeamMember.docs[id].document;
                await this.updateTeamMemberDocument({
                    id: this.currentTeamMember.docs[id].id,
                    payload: this.currentTeamMember.docs[id],
                });
                await this.setNotification({
                    msg: "Document Successfully updated",
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        extractFilePath(url) {
            const pathname = new URL(url).pathname;

            return pathname.substring(pathname.indexOf("documents/"));
        },
        async sendDocumentData() {
            try {
                const nullKeys = this.checkNullValues({
                    date: this.documentData.date,
                    document: this.documentData.document,
                    ...this.company[0].doc_type ? { doc_type: this.documentData.doc_type } : {}
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true);
                    let formData = new FormData();
                    let fileType = this.documentData.document instanceof File || this.documentData.document instanceof Blob;
                    if (!fileType) this.documentData.document = null
                    formData.append("object_id", this.currentTeamMember.id);
                    formData.append(
                        "date",
                        moment(this.documentData.date).format("YYYY-MM-DD")
                    );
                    formData.append("type", this.documentData.type);
                    formData.append("description", this.documentData.description);
                    if (this.documentData.doc_type) formData.append("doc_type", this.documentData.doc_type);
                    if (this.documentData.document) {
                        formData.append(
                            "document",
                            new File(
                                [this.documentData.document],
                                this.currentTeamMember.user.first_name + "_" + this.currentTeamMember.user.last_name + "_" + this.documentData.description +
                                "_" + this.userCompany + "_" +
                                new Date().getTime() +
                                "." +
                                this.documentData.document["type"].split("/").pop(),
                                { type: this.documentData.document["type"] }
                            )
                        );
                    }
                    formData.append("expiry_date", this.documentData.expiry_date ? moment(this.documentData.expiry_date).format("YYYY-MM-DD") : '');
                    formData.append("driver", this.documentData.driver);
                    formData.append("model_type", "teammember");
                    formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
                    if (this.editDocumentFlag) {
                        this.documentData = await this.updateTeamMemberDocument({
                            id: this.documentData.id,
                            payload: formData,
                        });
                        await this.setNotification({
                            msg: "Document Successfully updated!!",
                            type: "success",
                            color: "green",
                        });
                        let color;
                        if (this.documentData.expiry_date) {
                            const currentDate = new Date();
                            const expiryDate = new Date(this.documentData.expiry_date);
                            const diffDays = parseInt(
                                (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                                10
                            );
                            if (diffDays > 31) {
                                color = "color:black";
                            } else if (diffDays <= 31 && diffDays >= 1) {
                                color = "color:#FFC800";
                            } else color = "color:#FF0000";
                        }
                        this.currentTeamMember.docs[this.documentIndex] = { ...this.documentData, doc_type_id: this.documentData.doc_type, document: this.extractFilePath(this.documentData.document) };
                        this.items[this.documentIndex].Date = moment(
                            this.documentData.date
                        ).format("MMM DD, YYYY");
                        this.items[this.documentIndex].Type = this.documentData.type;
                        this.items[this.documentIndex].Description =
                            this.documentData.description;
                        this.items[this.documentIndex].expiry_date = this.documentData
                            .expiry_date
                            ? `<span style=${color}>${moment(
                                this.documentData.expiry_date
                            ).format("MMM DD, YYYY")}</span`
                            : "-";
                        this.items[this.documentIndex].Driver = this.documentData.driver;
                        this.editDocumentFlag = false;
                        this.updateDocs();
                        this.documentData = {
                            object_id: null,
                            date: null,
                            type: null,
                            expiry_date: "",
                            document: null,
                            description: null,
                            driver: true,
                            doc_type: ""
                        };
                        this.fileName = null;
                        this.$bvModal.hide("modal-document");
                    } else {
                        if (this.documentData.date) {
                            await this.sendTeamMemberDocument(formData);
                            await this.getTeamMember(this.driverID);
                            this.updateDocs();
                            await this.setNotification({
                                msg: `Document Successfully ${this.editDocumentFlag ? "updated" : "added"
                                    }!!`,
                                type: "success",
                                color: "green",
                            });
                            this.documentData = {
                                object_id: null,
                                date: null,
                                type: null,
                                expiry_date: "",
                                document: null,
                                description: null,
                                driver: true,
                                doc_type: ""
                            };
                            this.fileName = null;
                            this.$bvModal.hide("modal-document");
                        }
                    }
                    this.setLoader(false);
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            } catch (error) {
                console.log(error)
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
    },
}
</script>