<template>
  <div class="search-equipment-page">
    <header class="text-left search_equipment d-flex user-detail row">
      <div class="col-md-8 d-flex justify-content-between mx-3 mx-md-auto align-items-center">
        <b-img :src="require('@/assets/form-logo.png')" class="d-none d-sm-block"  width="230" height="37"></b-img>
        <b-img :src="require('@/assets/small-logo.svg')" class="d-block d-sm-none" width="40" height="40"></b-img>
      </div>
    </header>
    <div class="row">
      <div class="col-md-8 mx-3 mx-md-auto">
        <div v-if="showInfo">
        <h1 class="font-weight-bold f-32 text-left mt-3 mb-3">{{ equipment.unit_no }}</h1>
        <div class="bg-white p-4 rounded equipment-details-section">
          <b-row v-if="equipment.unit_no">
            <b-col>
              <div class="desc text-left f-14 mb-2">{{ equipment.unit_no }}</div>
              <div class="desc text-left f-14 mb-2">{{ equipment.type }}</div>
              <div class="desc text-left f-14 mb-2">{{ equipment.subtype }}</div>
              <div class="desc text-left f-14 mb-2">{{ equipment.vin_no }}</div>
              <div class="desc text-left f-14 mb-2">{{ equipment.plate_no }}</div>
              <div class="desc text-left f-14 mb-2">{{ equipment.state.name }}</div>
              <!-- <div class="left_side">
              <div class="heading">Country</div>
              <div class="desc">{{ equipment.state.country.name }}</div>
            </div> -->
              <div class="mt-4">
                <div class="desc text-left f-14 mb-2">{{ equipment.company.name }}</div>
                <div class="desc text-left f-14 mb-2">{{ equipment.company.phone }}</div>
                <div class="desc text-left f-14">{{ equipment.company.email }}</div>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <h1>No Record Found!!</h1>
            </b-col>
          </b-row>
        </div>
      </div>
        <div class="mt-3" v-if="showInfo && items.length > 0">
          <div @click="openLink(docs.id)"
            class="equipment-details-section equipment-detail-section cursor-pointer bg-white justify-content-between mb-3"
            v-for="(docs, index) in items" :key="index">
            <div>
              <p class="f-14 mb-0 text-left font-weight-bold mb-1">
                {{ docs.description ? docs.description : "-" }}
              </p>
              <p class="f-12 mb-0 text-left" :class="docs.expired_status">
                {{
                  docs.expiry_date
                  ? "Expiring " + moment(docs.expiry_date).format("MMM DD, YYYY")
                  : "-"
                }}
              </p>
            </div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4849_341)">
                <path
                  d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                  fill="#2D69F6" />
              </g>
              <defs>
                <clipPath id="clip0_4849_341">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div v-if="emptyMessage">
          <h2 class="text-center mt-5">{{ emptyMessage }}</h2>
        </div>
      </div>
    </div>
    <b-modal id="pinModal" centered hide-footer :title="'Pin'" :no-close-on-backdrop="true" size="sm" no-close-on-esc>
        <template #modal-header="{ close }">
          <h5 class="modal-title">Pin</h5>
          <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
            Close Modal
          </b-button>
        </template>
        <div class="d-block text-center">
          <b-row>
            <b-col cols="12">
              <b-form @submit.prevent="handleFormSubmit">
                <b-form-group label="Pin Number" class="text-left">
                  <b-form-input type="text" v-model="form.pin_code" placeholder="Enter Pin Number" required
                    pattern="\d{4}" title="Max 4 Digits Allowed"></b-form-input>
                  <p v-if="errors.pin_code" class="field-error">
                    This field is required.
                  </p>
                </b-form-group>
                <b-button type="submit" variant="primary" class="save w-100">Submit</b-button>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as moment from "moment-timezone";

export default {
  name: "search-equipment",
  data() {
    return {
      moment,
      country: 1,
      items: [],
      showInfo: false,
      emptyMessage:"",
      equipment: {},
      errors: {
        pin_code: false,
      },
      form: {
        pin_code: "",
      },
    };
  },
  computed: {
    ...mapGetters(["currentEquipment", "allCountries","companyPinCode"]),
  },
  methods: {
    ...mapActions([
      "getAllCountries",
      "searchEquipment",
      "retrieveDocument",
      "setNotification",
      "getEquipment",
    ]),
  handleFormSubmit(){
    if(this.equipment.company.pin_code === this.form.pin_code){
      this.showInfo = true
      this.$bvModal.hide("pinModal");
    }
  },
    getCountry(id) {
      return this.allCountries.filter((country) => country.id === id)[0].name;
    },
    async searchEq(query) {
      try {
        const equipments = await this.searchEquipment(query);
        if (equipments.length > 0) {
          this.equipment = equipments[0];
          this.items = this.equipment?.docs.map((item) => {
            if (item.expiry_date) {
              let color;
              const currentDate = new Date();
              const expiryDate = new Date(item.expiry_date);
              const diffDays = parseInt(
                (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                10
              );
              if (diffDays > 31) {
                color = "black-text";
              } else if (diffDays <= 31 && diffDays >= 1) {
                color = "yellow-text";
              } else color = "red-text";
              return { ...item, expired_status: color };
            } else return { ...item };
          });
        } else {
          this.items = [];
          this.equipment = {};
        }
      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
        this.items = [];
        this.equipment = {};
      }
    },
    async openLink(id) {
      const doc = await this.retrieveDocument(id);
      window.location.href = doc.document
    },
  },
  async mounted() {
    try {
      if (this.$route.query.equipment) {
        await this.searchEq(`equipment_id=${this.$route.query.equipment}`);
        if(this.equipment && this.equipment.docs && this.equipment.docs.length){
        if(this.equipment.company.pin_code && !this.companyPinCode){
          this.$bvModal.show("pinModal");
        }
        else this.showInfo = true
        await this.getAllCountries();
      } else this.emptyMessage = "No Data"
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
.search-equipment-page {
  background: #fafafa;
  height: calc(100vh - 0px);
}

.equipment-details-section {
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.10);
  border-radius: 5px;
}

.search-equipment-page .main-search {
  background: #fafafa;
}

#vs1__combobox {
  min-height: 40px;
}

.main-search {
  padding: 30px 132px !important;
}

.search_equipment {
  background-color: #000 !important;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 6;
  padding: 10px 0px;
  top: 0;
}

.search_card {
  width: 520px;
  margin: auto;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
}

.search_card p {
  width: 100%;
  /* border-bottom: 1px solid rgba(0, 0, 0, 30%); */
  line-height: 0.05em;
  margin: 30px 0px;
  padding-top: 12px;
}

.search_card p span {
  background-color: #fff;
  padding: 0px 10px;
  color: rgba(0, 0, 0, 60%);
  font-weight: 100;
  font-size: var(--small);
}

.search_card input,
.search_card input::placeholder,
.search_card input:active,
.search_card input:focus {
  color: rgba(0, 0, 0, 60%);
}

.search_card .search,
.search_card .search:hover,
.search_card .search:focus,
.search_card .search:active {
  background-color: var(--btn-bg-color);
}

.left_side,
.right_side {
  display: flex;
  line-height: 30px;
  color: var(--black);
  font-size: var(--small);
}

.left_side .heading,
.right_side .heading {
  width: 250px;
  text-align: left;
  font-weight: 600;
}

.search-equipment-page .select_arrow {
  position: relative;
}

.equipment-detail-section {
  padding: 13px 25px;
  display: flex;
  align-items: center;
}

.search-equipment-page .select_arrow svg {
  position: absolute;
  top: 40px;
  right: 10px;
}

@media (max-width: 991px) {

  .left_side .heading,
  .right_side .heading {
    width: 150px;
  }
}

@media (max-width: 767px) {

  .left_side,
  .right_side {
    justify-content: space-between;
  }

  .main-search {
    padding: 30px !important;
  }
}

@media (max-width: 575px) {
  .search_card {
    width: auto;
  }
}

@media (max-width: 500px) {

  .left_side,
  .right_side {
    display: block !important;
  }

  .left_side .heading,
  .right_side .heading {
    width: auto;
    text-align: left;
  }

  .left_side .desc,
  .right_side .desc {
    text-align: left;
  }
  .equipment-detail-section > div> p{
    width: 200px;
  }

}</style>
