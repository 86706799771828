<template>
    <div>
        <div class="">
            <header class="text-left search_equipment px-3 py-3">
                    <b-img class="ml-5 d-none d-sm-block" :src="require('@/assets/form-logo.png')" width="230" height="37"></b-img>
                    <b-img :src="require('@/assets/small-logo.svg')" class="d-block d-sm-none" width="40" height="40"></b-img>
            </header>
        </div>
        <div>
            <b-modal id="searchModal" centered hide-footer :title="'Pin'" :no-close-on-backdrop="true" size="sm" no-close-on-esc>
                <template #modal-header="{ close }">
                    <h5 class="modal-title">Driver ID</h5>
                    <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                        Close Modal
                    </b-button>
                </template>
                <div class="d-block text-center">
                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="handleFormSubmit">
                                <b-form-group label="Driver ID" class="text-left">
                                    <b-form-input type="text" v-model="form.driver_id" placeholder="Enter Driver ID"
                                        required></b-form-input>
                                    <p v-if="errors.driver_id" class="field-error">
                                        This field is required.
                                    </p>
                                </b-form-group>
                                <b-button type="submit" class="save w-100" variant="primary">Submit</b-button>
                            </b-form>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
        </div>
    </div>
</template>
  
<script>
import { mapActions } from "vuex";
export default {
    name: "driver-search",
    data() {
        return {
            errors: {
                driver_id: false,
            },
            form: {
                driver_id: "",
            },
        };
    },
    methods: {
        ...mapActions(["setNotification", "checkDriverStatus"]),
        async handleFormSubmit() {
            try {
                const status = await this.checkDriverStatus({id:this.form.driver_id});
                if(status) this.$router.push(`/driver-details?driver=${this.form.driver_id}`)
                else this.setNotification({
                    msg: "Driver Doesn't Exist",
                    type: "error",
                    color: "red",
                });
                // this.$bvModal.hide("searchModal");
            } catch (e) {
                console.log(e)
            }
        },
    },
    async mounted() {
        this.$bvModal.show("searchModal");
    },
};
</script>