<template>
  <b-container v-if="user" fluid class="mt-2 px-1 px-sm-3 pb-3">
    <b-row>
      <b-col cols="" class="">
        <b-row>
          <b-col cols="">
            <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-0">
              <h2 class="text-left">Dashboard</h2>
            </div>
          </b-col>
        </b-row>
        <b-row class="dashboard_total">
          <b-col lg="3" md="4" sm="6" class="px-3 pr-lg-0 mt-3">
            <div class="total_team total_driver flex-wrap">
              <b-img :src="require('@/assets/total_drivers.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ dashboardSummary.total_drivers || 0 }}
                </h5>
                <p>Total Drivers</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3" md="4" sm="6" class="px-3 mt-3">
            <div class="total_team total_vehicle flex-wrap">
              <b-img :src="require('@/assets/total_vehicles.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ dashboardSummary.total_vehicles || 0 }}
                </h5>
                <p>Total Vehicles</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3" md="4" sm="6" class="px-3 px-lg-0 mt-3">
            <div class="total_team total_trailer flex-wrap">
              <b-img :src="require('@/assets/total_trailers.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ dashboardSummary.total_trailers || 0 }}
                </h5>
                <p>Total Trailers</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3" md="4" sm="6" class="px-3 pl-lg-3 mt-3">
            <div class="total_team flex-wrap">
              <b-img :src="require('@/assets/total-docs.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ dashboardSummary.total_documents || 0 }}
                </h5>
                <p>Total Documents</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3" md="4" sm="6" class="px-3 pr-lg-0 mt-3">
            <div class="total_team total_driver justify-content-between flex-wrap">
              <div class="d-flex align-items-center">
              <b-img :src="require('@/assets/expired-docs.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ dashboardSummary.expired_documents_count || 0 }}
                </h5>
                <p>Expired</p>
              </div>
            </div>
            <p class="mb-0 text-danger-color f-16 font-weight-bold">{{parseFloat(dashboardSummary.expired_documents_percentage).toFixed(1) || 0}}%</p>
            </div>
          </b-col>
          <b-col lg="3" md="4" sm="6" class="px-3 mt-3">
            <div class="total_team total_vehicle justify-content-between flex-wrap">
              <div class="d-flex align-items-center">
              <b-img :src="require('@/assets/expiring-soon.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ dashboardSummary.expiring_soon_count || 0 }}
                </h5>
                <p>Expiring soon</p>
              </div>
              </div>
              <p class="mb-0 text-warning-color f-16 font-weight-bold">{{ parseFloat(dashboardSummary.expiring_soon_documents_percentage).toFixed(1) || 0}}%</p>
            </div>
          </b-col>
          <b-col lg="3" md="4" sm="6" class="px-3 px-lg-0 mt-3">
            <div class="total_team total_vehicle flex-wrap">
              <b-img :src="require('@/assets/review.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ dashboardSummary.review_count || 0 }}
                </h5>
                <p>Review</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3" md="4" sm="6" class="px-3 pl-lg-3 mt-3">
            <div class="total_team flex-wrap">
              <b-img :src="require('@/assets/total-file-drop.svg')"></b-img>
              <div>
                <h5 class="number">
                  {{ dashboardSummary.file_drop_count || 0 }}
                </h5>
                <p>File Drop</p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" cols="12" class="my-1">
            <div class="data-table bg-white pt-3 pt-sm-4 table-custom-border rounded-top">
              <div class="equipment-border filter-box table_row">
                <div class="d-flex px-3 px-sm-4 mb-4">
                  <b-form-group class="text-left label font-weight-normal search-field mb-0">
                    <b-input-group class="username-input-field">
                      <b-form-input type="text" placeholder="Search" v-model="filterFields.search" class="unit f-12"
                        @keyup.enter="getDocuments()"></b-form-input>
                      <b-input-group-append>
                        <b-button variant="primary" @click="getDocuments()">
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <div class="ml-auto d-flex align-items-center flex-wrap">
                    <div class="position-relative">
                      <div class="position-absolute notification-badge" v-if="activeFiltersCount">
                        {{ activeFiltersCount }}
                      </div>
                      <b-dropdown id="dropdown-divider" ref="filterDropdown"
                        class="filter-section table-columns-dropdown mt-0">
                        <template slot="button-content">
                          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.74045 19C8.55379 19 8.37477 18.9256 8.24278 18.7933C8.11079 18.6609 8.03663 18.4813 8.03663 18.2941V11.5071L1.51223 4.31059C1.2554 4.02674 1.08624 3.67422 1.02528 3.29583C0.964316 2.91743 1.01418 2.52942 1.16881 2.17889C1.32344 1.82836 1.57619 1.53038 1.89639 1.32112C2.21659 1.11185 2.59047 1.0003 2.97266 1H18.0273C18.4095 1.0003 18.7834 1.11185 19.1036 1.32112C19.4238 1.53038 19.6766 1.82836 19.8312 2.17889C19.9858 2.52942 20.0357 2.91743 19.9747 3.29583C19.9138 3.67422 19.7446 4.02674 19.4878 4.31059L12.9634 11.5071V15.4706C12.9633 15.5763 12.9395 15.6807 12.8939 15.776C12.8482 15.8713 12.7817 15.9551 12.6994 16.0212L9.18034 18.8447C9.05557 18.9451 8.90042 18.9999 8.74045 19ZM2.97266 2.41176C2.86314 2.41204 2.75604 2.44414 2.66434 2.50419C2.57263 2.56424 2.50024 2.64966 2.45594 2.75011C2.41163 2.85056 2.3973 2.96174 2.41468 3.07019C2.43207 3.17864 2.48042 3.27971 2.55389 3.36118L9.26128 10.7729C9.3762 10.8997 9.44123 11.064 9.44427 11.2353V16.8259L11.5557 15.1176V11.2353C11.5553 11.0591 11.6206 10.8892 11.7387 10.7588L18.4461 3.34706C18.5152 3.26523 18.5598 3.16547 18.5748 3.0593C18.5897 2.95312 18.5744 2.84488 18.5306 2.74706C18.4868 2.64924 18.4163 2.56585 18.3273 2.50652C18.2382 2.44719 18.1342 2.41434 18.0273 2.41176H2.97266Z"
                              stroke-width="0.2" />
                          </svg>

                        </template>
                        <div class="filter-section-items">
                          <p class="font-weight-bold mb-2 mt-2 heading">Filter</p>

                          <b-form-group label="Type" class="text-left select_arrow label mb-0">
                            <b-form-select type="text" class="mb-4 input f-12" required v-model="filterFields.type"
                              :options="typeOptions" @change="getDocuments()"></b-form-select>
                          </b-form-group>

                          <!-- <b-form-group label="Days:" class="text-left label mb-0">
                            <b-form-select class="mb-4 input f-12" @change="getDocuments()" :options="[
                              {
                                value: null,
                                text: '',
                              },
                              {
                                value: '0-15',
                                text: '0-15',
                              },
                              {
                                value: '0-31',
                                text: '0-31',
                              },
                            ]" v-model="filterFields.days">
                            </b-form-select>
                          </b-form-group> -->
                        </div>
                        <div class="d-flex align-items-center justify-content-between buttons-section">
                          <b-button variant="primary" @click="resetFilterFields()">Reset</b-button>
                          <b-button class="text-dark btn-secondary-2"
                            @click="$refs.filterDropdown.hide()">Close</b-button>
                        </div>
                      </b-dropdown>
                    </div>
                    <b-button v-if="allowViewDocCompany || allowViewDocDrivers || allowViewDocEquipment"
                      @click="downloadExcel" class="ml-2" variant="dashboard-export-table-btn"><svg width="16" height="18"
                        viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M13.0206 6.20996H10.364V7.80592H13.0206C13.8106 7.80592 14.4235 8.21448 14.4235 8.56613V15.6431C14.4235 15.9948 13.8106 16.4033 13.0206 16.4033H2.97916C2.18919 16.4033 1.57628 15.9948 1.57628 15.6431V8.56639C1.57628 8.21475 2.18919 7.80618 2.97916 7.80618H5.63519V6.21023H2.97916C1.30857 6.21023 0 7.24521 0 8.56639V15.6434C0 16.9648 1.30857 17.9996 2.97916 17.9996H13.0208C14.6912 17.9996 16 16.9646 16 15.6434V8.56639C15.9997 7.24494 14.6912 6.20996 13.0206 6.20996Z"
                          fill="#C4C4C4" />
                        <path
                          d="M5.66755 3.97792C5.86932 3.97792 6.07082 3.89999 6.22477 3.74411L7.21151 2.74505V6.2104V7.80636V10.8637C7.21151 11.3044 7.56434 11.6617 7.99965 11.6617C8.43497 11.6617 8.78779 11.3044 8.78779 10.8637V7.80636V6.2104V2.69105L9.82787 3.74411C9.98182 3.89999 10.1836 3.97792 10.3851 3.97792C10.5866 3.97792 10.7883 3.89999 10.9423 3.74411C11.2502 3.43264 11.2502 2.92725 10.9423 2.61577L8.58918 0.233276C8.43523 0.0774039 8.23373 0 8.03223 0C8.03013 0 8.02829 0 8.02619 0C8.02409 0 8.02225 0 8.02014 0C7.81864 0 7.61714 0.0774039 7.46319 0.233276L5.11007 2.61577C4.80218 2.92725 4.80218 3.43264 5.11007 3.74411C5.26429 3.89999 5.46579 3.97792 5.66755 3.97792Z"
                          fill="#C4C4C4" />
                      </svg>
                    </b-button>
                  </div>
                </div>

                <b-table class="mt-2" responsive :fields="tableFields" :items="items" hover :current-page="currentPage"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
                  :show-empty="emptyTable">
                  <template #cell(name)="data">
                    <p class="mb-0">{{ data.value }}</p>
                  </template>
                  <template #cell(type)="data">
                    {{ data.value ? data.value : '-' }}
                  </template>
                  <template #cell(date)="data">
                    <span v-html="data.value"></span>
                  </template>
                  <template #cell(tags)="data">
                    <div v-if="data.value.length" class="d-flex align-items-center">
                      <div class="table-tags mr-2">{{ data.value[0] }}</div>
                      <v-app>
                        <v-tooltip bottom v-if="data.value.length > 1">
                          <template #activator="{ on, attrs }">
                            <div class="table-tags" v-on="on" v-bind="attrs">+{{ data.value.length - 1 }}</div>
                          </template> <span v-html="data.value.slice(1).join('<br>')"></span>
                        </v-tooltip>
                      </v-app>
                    </div>
                    <p class="mb-0" v-else>-</p>
                  </template>
                  <template #cell(description)="data">
                    <v-app>
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <span class="w-fit-content item-description text-left" v-on="on" v-bind="attrs">{{ data.value
                          }}</span>
                        </template>{{ data.value }}
                      </v-tooltip>
                    </v-app>
                  </template>
                  <template #cell(days)="data">
                    <span :style="data.item.color">{{ data.value }} Days</span>
                  </template>
                  <template #cell(action)="data">
                    <svg @click="openDoc(data.item)" class="cursor-pointer mr-4" width="20" height="15"
                      viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.6426 6.19707C18.8726 6.48384 19 6.85642 19 7.24273C19 7.62904 18.8726 8.00161 18.6426 8.28838C17.186 10.052 13.8704 13.4854 10 13.4854C6.12957 13.4854 2.81403 10.052 1.35741 8.28838C1.12735 8.00161 1 7.62904 1 7.24273C1 6.85642 1.12735 6.48384 1.35741 6.19707C2.81403 4.4335 6.12957 1 10 1C13.8704 1 17.186 4.4335 18.6426 6.19707Z"
                        stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M10.0007 10.0154C11.533 10.0154 12.7752 8.77323 12.7752 7.24091C12.7752 5.70859 11.533 4.4664 10.0007 4.4664C8.46839 4.4664 7.2262 5.70859 7.2262 7.24091C7.2262 8.77323 8.46839 10.0154 10.0007 10.0154Z"
                        stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <svg @click="downloadDoc(data.item)" class="mr-4 cursor-pointer" width="20" height="20"
                      viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.53943 11.3822L10.001 14.8437L13.4625 11.3822" stroke="#FFC800" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.0001 14.8497V5.15741" stroke="#FFC800" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                        stroke="#FFC800" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <svg @click="showPopup(data.item.pk)" class="cursor-pointer" v-if="checkDeleteDocsStatus(data.item)"
                      width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                        stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                        stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </template>
                </b-table>
                <div class="entries-pagination px-3 px-sm-4">
                  <div class="d-flex align-items-center justify-content-between flex-wrap">
                    <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                      <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-3 mt-sm-0">{{ positionText }}</p>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <!-- <b-col lg="4" md="8" sm="12" class="mx-auto my-1">
            <b-card img-top class="profile-card mb-2 bg-white" style="background: white !important">
              <b-card-text class="card-detail">
                <div class="user_photo text-center mb-3">
                  <b-img class="userImage rounded-circle" :src="user.team_member && user.team_member.profile_picture
                    ? user.team_member.profile_picture
                    : require('@/assets/user.png')
                    "></b-img>
                </div>
                <div class="card-title font-weight-bold mb-0">
                  {{ user.first_name }} {{ user.last_name }}
                </div>

                <div v-if="user && user.team_member" class="designation mb-3 f-14">
                  <div>
                    {{ user.team_member.role }}
                  </div>
                </div>
                <div class="w-100 text-center mt-5">
                  <p class="f-14">{{ user.email }}</p>
                  <p class="f-14">{{ user.team_member.phone }}</p>
                </div>
              </b-card-text>
              <b-button variant="primary" class="my-5 save text-white text-center" to="/profile-page">View
                Profile</b-button>
            </b-card>
          </b-col> -->
        </b-row>
      </b-col>
    </b-row>
    <b-modal id="modal-document" centered no-close-on-backdrop no-close-on-esc size="lg"
      :body-class="'px-3 pt-2 overflow-preview-auto'" :footer-class="'d-block border-0 pt-0 pb-3 m-0'"
      :header-class="'align-items-center'" scrollable>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Document
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
        <b-dropdown id="dropdown-preview" class="load-dropdown mt-0">
          <template slot="button-content">
            <b-icon class="dots" icon="three-dots"></b-icon>
          </template>
          <b-dropdown-item-button :class="(checkUpdateDocsStatus() || checkDeleteDocsStatus(documentData)) ? 'mb-2' : ''"
            @click="downloadDoc(documentData)">
            <p class="text_secondary cursor-pointer mb-0 f-14">Download</p>
          </b-dropdown-item-button>
          <b-dropdown-item-button :class="checkDeleteDocsStatus(documentData) ? 'mb-2' : ''"
            v-if="checkUpdateDocsStatus()" @click="removeFile()">
            <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="showPopup(documentData.id)" v-if="checkDeleteDocsStatus(documentData)">
            <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
          </b-dropdown-item-button>
        </b-dropdown>
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col lg="4" md="8" sm="12">
            <div class="text-left mb-3">
              <label class="state">Name</label>
              <v-select class="bg_white w-100 f-12" v-model="modelName" :options="nameOptions" item-value="id"
                item-text="label" :reduce="(option) => option.id" @option:selected="getNameItem($event)"
                :disabled="!checkUpdateDocsStatus()" @search="searchAssets">
                <span slot="no-options">
                  Search Unit No or Name...
                </span>
              </v-select>
              <p v-if="errors && errors.name" class="field-error">
                {{ errors.name[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Date</label>
              <date-picker v-model="documentData.date" :formatter="momentFormat" :range-separator="' - '"
                @clear="documentData.date = ''" placeholder="Select Date"
                class="date-picker-custom"
                :disabled="!checkUpdateDocsStatus()"
                :teleport="'body'"
                ></date-picker>
              <p v-if="errors && errors.date" class="field-error">
                {{ errors.date[0] }}
              </p>
            </div>

            <div class="text-left mb-3">
              <label for="modal-expiry-date" class="state">Expiry Date</label>
                <date-picker v-model="documentData.expiry_date" :formatter="momentFormat" :range-separator="' - '"
                @clear="documentData.expiry_date = ''" placeholder="Select Date"
                class="date-picker-custom"
                :disabled="!checkUpdateDocsStatus()"
                :teleport="'body'"
                ></date-picker>
            </div>
            <div class="text-left mb-3" v-if="showDivision">
              <label class="state">Division:</label>
              <v-select class="f-12" :clearable="false" :options="allDivisons" v-model="documentData.division"
                :disabled="!checkUpdateDocsStatus()" :reduce="(option) => option.id" />
            </div>
            <div class="text-left mb-3">
              <label class="state">Type:</label>
              <b-form-select class="f-12" :options="getDotOptions" :disabled="!checkUpdateDocsStatus()"
                v-model="documentData.doc_type" @change="setDocDescription($event)">
              </b-form-select>
              <p v-if="errors && errors.doc_type" class="field-error">
                {{ errors.doc_type[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Description</label>
              <b-form-textarea id="textarea" :disabled="!checkUpdateDocsStatus()" placeholder="Enter Description" rows="3"
                v-model="documentData.description"></b-form-textarea>
              <p v-if="errors && errors.description" class="field-error">
                {{ errors.description[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Driver</label>
              <b-form-checkbox class="ml-0-6rem" :disabled="!checkUpdateDocsStatus()" v-model="documentData.driver"
                name="driver-button" switch size="lg" />
            </div>
          </b-col>
          <b-col lg="8" sm="12">
            <div class="upload-btn-wrapper" v-if="!fileName">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button v-if="!fileName" class="foo">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ fileName }}</button>

              <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                accept=".pdf, .png, .jpg, .jpeg" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document[0] }}
              </p>
            </div>
            <div v-else>
              <div class="review-media-section" :class="mediaType === 'pdf' ? 'overflow-hidden' : ''">
                <iframe class="w-100 h-100 border-0" v-if="mediaType === 'pdf'" :src="media"></iframe>
                <div v-else>
                  <div id="panzoom-element" ref="panzoomElement">
                    <img :src="media" class="w-100 h-100 img-section" />
                  </div>
                  <div class="d-flex align-items-center justify-content-center zoom-section">
                    <b-icon @click="zoom(1)" icon="zoom-in" style="fill:white; cursor: pointer;"></b-icon>
                    <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out" style="fill:white;cursor: pointer;"></b-icon>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="">
      <div class="text-right modal-footer border-0 p-0 mt-4">
        <b-button class="mx-0 h-40px w-100px m-0" :class="hasChanged && checkUpdateDocsStatus() ? 'mr-3' : ''" block
          @click="$bvModal.hide('modal-document'); panzoom = null">Close</b-button>
        <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="sendDocumentData"
          v-if="hasChanged && checkUpdateDocsStatus()">Save</b-button>
      </div>
      </template>
    </b-modal>
    <DeleteModal v-if="showDocumentModal" @confirm="deleteDocumentItem($event)" />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueMask from "v-mask";
import Vue from "vue";
import * as moment from "moment-timezone";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import axios from "axios";
import DeleteModal from "@/components/DeleteModal.vue";
import { checkValueExists } from "../../services/helper";
import Panzoom from '@panzoom/panzoom'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(VueMask);

export default {
  name: "Dashboard",
  components: { vSelect, DeleteModal, DatePicker },
  computed: {
    ...mapGetters(["user", "documents", "allCountries", "singleDoc", "userCompany", "docTypeItems", "company", "defaultDocType", 'divisions', 'rolePermissions']),
    allDivisons() {
      return this.divisions.map((item) => {
        return {
          id: item.id,
          label: item.division,
        }
      })
    },
    allowViewDocDrivers() {
      return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][7].allow
    },
    allowViewDocEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][6].allow
    },
    allowViewDocCompany() {
      return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][0].allow
    },
    allowDeleteDocDrivers() {
      return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][10].allow
    },
    allowDeleteDocEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][9].allow
    },
    allowDeleteDocCompany() {
      return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][3].allow
    },
    allowUpdateDocDrivers() {
      return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][8].allow
    },
    allowUpdateDocEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][7].allow
    },
    allowUpdateDocCompany() {
      return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][1].allow
    },
    hasChanged() {
      return Object.keys(this.documentData).filter(field => {
        if (field !== 'updated_at') {
          if (typeof this.documentData[field] === 'object') return JSON.stringify(this.documentData[field]) !== JSON.stringify(this.defaultData[field])
          if (field === 'description') {
            return this.checkValueExists(this.documentData[field]?.trim().replace(/\r\n|\r|\n/g, '\n')) != this.checkValueExists(this.defaultData[field]?.trim().replace(/\r\n|\r|\n/g, '\n'));
          }
          else if (!this.documentData[field] && !this.defaultData[field]) return false
          else return this.checkValueExists(this.documentData[field]) != this.checkValueExists(this.defaultData[field])
        } return false
      }).length > 0
    },
    getDotOptions() {
      if (!this.modelName) return []
      return [...[{
        value: "",
        text: "",
      }], ...this.docTypeItems?.filter((item) => item.model === this.modelType || item.model === 'All').map((item) => {
        return {
          value: item.id,
          text: item.type,
        }
      })]
    },
    activeFiltersCount() {
      return Object.keys(this.filterFields).reduce((count, key) => {
        if (
          this.filterFields[key] !== '' &&
          this.filterFields[key] !== null
        ) {
          return count + 1;
        }
        return count;
      }, 0);
    },
    typeOptions() {
      const options = [
        {
          value: "",
          text: "",
        },
      ];
      if (this.allowViewDocEquipment) {
        options.push({
          value: "Vehicle",
          text: "Vehicle",
        })
        options.push(
          {
            value: "Trailer",
            text: "Trailer",
          })
      }
      if (this.allowViewDocDrivers) {
        options.push({
          value: "Driver",
          text: "Driver",
        });
      }
      if (this.allowViewDocCompany) {
        options.push({
          value: "Company",
          text: "Company",
        });
      }
      return options;
    }
  },
  methods: {
    ...mapActions([
      "getAllDocuments",
      "setNotification",
      "getEquipmentCount",
      "getTeamMembersCount",
      "getAllDrivers",
      "postExcelData",
      "getAllEquipments",
      "getSingleDoc",
      "setLoader",
      "updateTeamMemberDocument",
      "retrieveDocument",
      "deleteCompanyDocument",
      "exportExpiryData",
      "getDashboardStats"
    ]),
    checkUpdateDocsStatus() {
      if (this.documentData) return this.checkUpdateStatus(this.documentData.type)
      return false
    },
    checkUpdateStatus(type) {
      if (type === 'Driver' && this.allowUpdateDocDrivers) return true
      else if (type === 'Company' && this.allowUpdateDocCompany) return true
      else if ((type === 'Equipment' || type === 'Vehicle' || type === 'Trailer') && this.allowUpdateDocEquipment) return true
      else return false
    },
    checkDeleteDocsStatus(data) {
      if (data.type === 'Driver' && this.allowDeleteDocDrivers) return true
      else if (data.type === 'Company' && this.allowDeleteDocCompany) return true
      else if ((data.type === 'Equipment' || data.type === 'Vehicle' || data.type === 'Trailer') && this.allowDeleteDocEquipment) return true
      else return false
    },
    zoom(level) {
      if (!this.panzoom) {
        this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
          maxScale: 5
        })
      }
      level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
    },
    setDocDescription(event) {
      if (event) {
        this.documentData.description = this.getDotOptions.filter((option) => option.value === event)[0].text
      }
      else this.documentData.description = ""
    },
    deleteDocumentItem(item) {
      if (item) {
        this.deleteDocument(this.itemId);
      }
      this.showDocumentModal = false;
    },
    async downloadDoc(data) {
      this.setLoader(true)
      const doc = await this.retrieveDocument(data.id);
      fetch(doc.document)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const newUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = newUrl;
          const urlWithoutParams = doc.document.split('?')[0];
          const extension = urlWithoutParams.split('.').pop();
          a.download = `${data.docName ? data.docName : data.name}-${data.description ? data.description : ''}.${extension}`
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(newUrl);
          a.remove()
          this.setLoader(false)
        })
        .catch(error => {
          this.setLoader(false)
          console.log(error)
        });
    },
    async deleteDocument(id) {
      try {
        await this.deleteCompanyDocument(`${id}/?company_id=${this.userCompany}`);
        await this.setNotification({
          msg: "Document successfully deleted!!",
          type: "success",
          color: "green",
        });
        await this.getDocuments()
        this.$bvModal.hide("modal-document");
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    showPopup(id) {
      this.showDocumentModal = true;
      this.itemId = id;
    },
    downloadFile(uri) {
      axios({
        method: 'get',
        url: uri,
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const fileName = this.docName
        a.download = fileName
        document.body.appendChild(a);
        a.click();
        a.remove()
        window.URL.revokeObjectURL(url);
      });
    },
    async openDoc(data) {
      this.docName = `${data.name}-${data.description ? data.description : ''}`
      const doc = await this.retrieveDocument(data.id);
      this.media = doc.document
      this.mediaID = data.id
      if (doc.document.includes('.pdf')) this.mediaType = 'pdf'
      else this.mediaType = 'image'
      this.editDocument(data.id, data.name)
    },

    downloadURI(uri) {
      let link = document.createElement("a");
      link.setAttribute("href", uri);
      link.setAttribute("download", "export.csv");
      link.click();
      link.remove()
    },
    async resetFilterFields() {
      this.filterFields = {
        search: "",
        type: "",
        days: ""
      }
      await this.getDocuments()
    },
    checkNullValues(payload) {
      let nullKeys = [];
      for (let [key, value] of Object.entries(payload)) {
        if (!value) {
          nullKeys.push(key);
        }
      }
      return nullKeys;
    },
    async sendDocumentData() {
      try {
        const nullKeys = this.checkNullValues({
          name: this.modelName,
          date: this.documentData.date,
          document: this.documentData.document,
          ...this.company[0].doc_type ? { doc_type: this.documentData.doc_type } : {}
        });
        if (nullKeys.length === 0) {
          this.setLoader(true);
          let formData = new FormData();
          let fileType = this.documentData.document instanceof File || this.documentData.document instanceof Blob;
          if (!fileType) this.documentData.document = null
          formData.append("object_id", this.modelName.split("-")[1]);
          formData.append(
            "date",
            moment(this.documentData.date).format("YYYY-MM-DD")
          );
          formData.append("description", this.documentData.description);
          if (this.documentData.doc_type) formData.append("doc_type", this.documentData.doc_type);
          formData.append("type", this.documentData.type);
          formData.append("expiry_date", this.documentData.expiry_date ? moment(this.documentData.expiry_date).format("YYYY-MM-DD") : '');
          if (this.documentData.document)
            formData.append(
              "document",
              new File(
                [this.documentData.document],
                this.nameOptions.filter(
                  (item) => item.id === this.modelName
                )[0].name + "_"+
                this.documentData.description +
                "_" + this.userCompany + "_" +
                new Date().getTime() +
                "." +
                this.documentData.document["type"].split("/").pop(),
                { type: this.documentData.document["type"] }
              )
            );
          formData.append("driver", this.documentData.driver);
          formData.append(
            "model_type",
            this.nameOptions.filter(
              (item) => item.id === this.modelName
            )[0].type
          );
          if (this.nameOptions.filter(
            (item) => item.id === this.documentData.name
          )[0].type === 'company') formData.append("division", this.documentData.division);
          this.documentData = await this.updateTeamMemberDocument({
            id: this.documentData.id,
            payload: formData,
          });
          await this.setNotification({
            msg: "Document Successfully updated!!",
            type: "success",
            color: "green",
          });
          this.$bvModal.hide("modal-document");
          this.nameOptions = []
          await this.getDocuments();
          this.documentData = {
            object_id: null,
            date: null,
            name: null,
            type: null,
            document: null,
            description: null,
            expiry_date: "",
            driver: true,
            public: false,
            doc_type: "",
            division: null
          };
          this.fileName = null;
          this.setLoader(false);
        }
        else {
          let error = {};
          nullKeys.forEach((x) => {
            error[x] = ["This field is required."];
          });
          throw error;
        }
      }

      catch (error) {
        this.setLoader(false);
        console.log(error)
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
    async editDocument(id, name) {
      await this.getSingleDoc(id)
      const typeofDoc = this.singleDoc.document_of.role
        ? this.singleDoc.document_of.role
        : this.singleDoc.document_of.type
      this.nameOptions = [{
        id: `${typeofDoc}-${typeofDoc === "Company" ? this.userCompany : this.singleDoc.document_of.id}`,
        label: this.singleDoc.document_of.unit_no
          ? this.singleDoc.document_of.unit_no
          : this.singleDoc.document_of.name,
        type: `${typeofDoc === "Company" ? 'company' : typeofDoc === "Vehicle" || typeofDoc === "Trailer" ? 'equipment' : 'teammember'}`,
        name: this.singleDoc.document_of.role === "Driver" ? `${this.singleDoc.document_of.name}_` : this.singleDoc.document_of.type === "Vehicle" || this.singleDoc.document_of.type === "Trailer" ? `${this.singleDoc.document_of.unit_no}_` : ``,
        model: typeofDoc
      }]
      this.nameOptions = [...new Map(this.nameOptions.map(item => [item['id'], item])).values()]
      this.showDivision = this.singleDoc.document_of.role === "Company" ? true : false
      this.modelType = this.singleDoc.document_of.type || this.singleDoc.document_of.role
      this.documentIndex = id;
      this.documentData = this.singleDoc
      this.documentData = { ...this.singleDoc, doc_type: this.singleDoc.doc_type_info.id,  date: moment.tz(this.singleDoc.date, this.userTimeZone).toDate(), expiry_date: this.singleDoc.expiry_date ? moment.tz(this.singleDoc.expiry_date, this.userTimeZone).toDate():"" };
      this.documentData.type = typeofDoc
      this.documentData.name = `${typeofDoc}-${typeofDoc === "Company" ? this.userCompany : this.singleDoc.document_of.id}`;
      this.modelName = `${typeofDoc}-${typeofDoc === "Company" ? this.userCompany : this.singleDoc.document_of.id}`;
      this.documentData.docName = name
      if (this.documentData.document) {
        let file_name = "";
        if (typeof this.documentData.document === "string") {
          file_name = this.documentData.document;
        } else {
          file_name = this.documentData.document.name;
        }
        this.fileName = file_name.split("/").pop().split("#")[0].split("?")[0];
      } else {
        this.documentData.document = null;
        this.fileName = "";
      }
      this.errors = {
        date: null,
        type: null,
        document: null,
        description: null,
        ...this.company[0].doc_type? { doc_type: null } : {}
      };
      this.defaultData = JSON.parse(JSON.stringify({ ...this.documentData, doc_type: this.singleDoc.doc_type_info.id ? this.singleDoc.doc_type_info.id : '' }))
      this.$bvModal.show("modal-document");
    },
    async searchAssets(searchText) {
      if (searchText) {
        let options = []
        let equipmentRecords
        let url
        if (this.allowUpdateDocEquipment) {
          url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
          equipmentRecords = await this.getAllEquipments(url);
        }
        if (this.allowUpdateDocDrivers && (!this.allowUpdateDocEquipment || (equipmentRecords && equipmentRecords.records.length === 0))) {
          url = `name=${searchText}&sort_field=name&sort_order=asc&&page=1&page_size=10`
          const driverRecords = await this.getAllDrivers(url);
          if (driverRecords.records?.length) {
            driverRecords.records?.map((item) => {
              options.push({
                id: `Driver-${item.id}`,
                label: item.name,
                type: "teammember",
                name: `${item.user.first_name}_${item.user.last_name}_`,
                model: "Driver"
              });
            });
          }
        }
        else {
          if (equipmentRecords) {
            equipmentRecords.records.map((item) => {
              options.push({
                id: `${item.type}-${item.id}`,
                label: item.unit_no,
                type: "equipment",
                name: `${item.unit_no}_`,
                model: item.type
              });
            });
          }
        }
        if (this.allowUpdateDocCompany) {
          this.nameOptions = this.nameOptions.concat(options).concat([{
            id: `Company-${this.userCompany}`,
            label: "Company",
            type: "company",
            name: this.company[0].name,
            model: "Company"
          }])
        }
        else this.nameOptions = options
        this.nameOptions = [...new Map(this.nameOptions.map(item => [item['id'], item])).values()]
      }
    },
    getNameItem(item) {
      this.modelType = item.model
      this.showDivision = item.type === 'company' ? true : false
      if (this.showDivision) this.documentData.division = this.divisions.filter((division) => division.default)[0]?.id
    },
    removeFile() {
      this.fileName = null;
      this.documentData.document = null
      this.media = null
      this.mediaType = null
    },
    async downloadExcel() {
      try {
        this.setLoader(true)
        let url = ""
        let tags = ""
        Object.keys(this.filterFields).map((key) => {
          if (key === "tags" && this.filterFields[key].length > 0) {
            this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
            url += `${key}=${tags}&`
          }
          else if (this.filterFields[key] !== '' && key !== 'tags') {
            url += `${key}=${this.filterFields[key]}&`
          }
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=Days&sort_order=asc&`
        }
        const data = await this.exportExpiryData(url)
        const blob = new Blob([data], { type: 'text/csv' });
        const csvUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = csvUrl;
        a.download = 'records.csv';
        a.target = "_blank"
        document.body.appendChild(a);
        a.click();
        a.remove()
        window.URL.revokeObjectURL(url);
        await this.setNotification({
          msg: `File Exported Successfully`,
          type: "success",
          color: "green",
        });
        this.setLoader(false)
        this.setLoader(false)
      }
      catch (e) {
        this.setLoader(false)
        console.log(e)
      }
    },
    async getDocuments() {
      try {
        this.isBusy = true
        let url = ""
        let tags = ""
        Object.keys(this.filterFields).map((key) => {
          if (key === "tags" && this.filterFields[key].length > 0) {
            this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
            url += `${key}=${tags}&`
          }
          else if (this.filterFields[key] !== '' && key !== 'tags') {
            url += `${key}=${this.filterFields[key]}&`
          }
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=Days&sort_order=asc&`
        }
        url += `page=${this.currentPage}&page_size=${this.perPage}`
        const data = await this.getAllDocuments(url);
        if (data.count) {
          this.rows = data.count
          this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + (this.perPage > data.count ? data.count : this.perPage)} of ${data.count}`
        }
        else {
          this.rows = 0
          this.positionText = `0 to 0 of 0`
        }
        this.items = []
        this.documents.forEach((doc) => {
          if (doc.Days <= 60) {
            if (doc.Days <= 0) {
              var color = "color:red";
            } else if (doc.Days <= 31 && doc.Days >= 1) {
              color = "color:orange";
            } else {
              color = "color:green";
            }
            this.items.push({
              name: doc.name,
              id: doc.id,
              type: doc.type,
              description: doc.description,
              Date: `<span style=${color}>${moment(doc.Date).format("MMM DD, YYYY")}</span>`,
              "Expiry Date": moment(doc.Date).format("MMM DD, YYYY"),
              color: color,
              Days: doc.Days,
              route: doc.team_id ? "driver" : doc.company_id ? "company" : "equipment",
              tags: doc.tags
            });
          }
        })
        if (this.items.length === 0) this.emptyTable = true
        else this.emptyTable = false
        this.isBusy = false
      }
      catch (e) {
        this.isBusy = false
        console.log(e)
      }

    },
    phoneRule: (value) => {
      const pattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
      return pattern.test(value);
    },
    handleFileUpload() {
      this.documentData.document = this.$refs.docFile.files[0];
      this.fileName = this.documentData.document.name;
      this.media = URL.createObjectURL(this.documentData.document);
      if (this.$refs.docFile.files[0].type.includes('pdf')) this.mediaType = 'pdf'
      else this.mediaType = 'image'
    },

  },
  watch: {
    perPage: {
      async handler() {
        await this.getDocuments();
      },
      immediate: true,
    },
    currentPage: {
      async handler() {
        await this.getDocuments();
      },
    },
    sortBy: {
      async handler() {
        await this.getDocuments();
      },
    },
    sortDesc: {
      async handler() {
        await this.getDocuments();
      },
    },
    filterFields: {
      handler() {
        this.currentPage = 1
      },
      deep: true
    },
    // documentData:{
    //   handler(){
    //     this.modelName = this.documentData.name
    //   },
    //   deep:true
    // }
  },
  async mounted() {
    try {
      const data = await this.getDashboardStats()
      this.dashboardSummary = data
      await this.getDocuments()
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      checkValueExists,
      fileName: "",
      showDocumentModal: false,
      emptyTable: false,
      positionText: "",
      rows: 0,
      media: null,
      mediaType: null,
      mediaID: null,
      docName: "",
      panzoom: null,
      sortBy: undefined,
      sortDesc: undefined,
      filterFields: {
        search: "",
        type: "",
        days: ""
      },
      searchGroups: null,
      dashboardSummary:{
        total_drivers:0,
        total_vehicles:0,
        total_trailers:0,
        total_documents:0,
        expired_documents_count:0,
        expired_documents_percentage:0,
        expiring_soon_count:0,
        expiring_soon_documents_percentage:0,
        review_count:0,
        file_drop_count:0
      },
      documentData: {
        name: null,
        object_id: null,
        date: new Date(),
        type: null,
        document: null,
        description: null,
        expiry_date: "",
        driver: true,
        public: false,
        doc_type: ""
      },
      errors: {
        date: null,
        name: null,
        document: null,
        description: null,
      },
      userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('MMM D, YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'MMM D, YYYY').toDate() : null
        }
      },
      perPage: 10,
      currentPage: 1,
      nameOptions: [],
      items: [],
      defaultData: {},
      passwords: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      roleOptions: [
        {
          value: "Admin",
          text: "Admin",
        },
        {
          value: "Operations",
          text: "Operations",
        },
        {
          value: "Maintenance",
          text: "Maintenance",
        },
        {
          value: "Driver",
          text: "Driver",
        },
      ],
      tableFields: [
        {
          label: "Name",
          key: "name",
          sortable: true,
          stickyColumn: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Type",
          key: "type",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Description",
          key: "description",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Expiry Date",
          key: "Date",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          key: "Days",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Tags",
          key: "tags",
          sortable: true,
          tdClass: "align-middle",
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "Action",
          label: "Action",
          sortable: true,
          visible: true,
          disabled: true,
          field: "text",
          thClass: 'font-table-weight-bold'
        }
      ],
      showDivision: false,
      modelType: null,
      fields: [
        {
          key: "Name",
        },
        {
          key: "Type",
        },
        {
          key: "Inspection",
        },
        {
          key: "Days",
        },
      ],
      modelName: null,
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.userImage {
  /* max-width: 150px; */
  width: 150px;
  height: 150px;
  /* max-height: 140px; */
  object-fit: cover;
  margin: 3px;
  border: 3px solid lightgrey;
}

a {
  text-decoration: none !important;
  color: var(--black) !important;
}

.userFields {
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.userFields label {
  margin-right: auto;
}

.dashboard_total .total_team {
  display: flex;
  align-items: center;
  padding: 25px;
  background-color: white;
  border-radius: 0.25rem;
  height: 104px;
  box-shadow: 0 4px 50px rgb(0 0 0/10%);
  border: 1px solid #dee2e6;
}

.dashboard_total .total_team img {
  margin-right: 20px;
}

.dashboard_total .total_team .number {
  font-size: 23px !important;
  font-weight: 700 !important;
  color: black;
  text-align: left;
  margin-bottom: 0px !important;
}

.dashboard_total .total_team p {
  font-size: 14px;
  color: black;
  margin-bottom: 0;
  text-align: left;
}

.table_heading {
  background-color: #2D69F6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.table_heading .heading {
  color: #fff;
}

.main-container .table.bg-white {
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%);
}

.table {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.table> :not(caption)>*>* {
  padding: 10px 15px;
}

.table_heading .view {
  background-color: var(--btn-bg-color);
  font-size: 14px;
  color: #fff !important;
  border: none;
}

.table_heading .view,
.table_heading .view:focus,
.table_heading .view:active,
.table_heading .view:hover {
  background-color: var(--btn-bg-color) !important;
  box-shadow: none;
}

.card {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
}

.profile-card {
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%) !important;
}

.save {
  background-color: var(--btn-bg-color) !important;
}

.card-detail .email {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 30px;
  position: relative;
  flex-direction: row;
  margin-bottom: 5px;
}

.card-detail .email.update-change {
  display: block;
  padding: 0;
  margin-bottom: 13px;
  position: relative;
}

.card-detail .email.update-change svg {
  position: absolute;
  right: 10px;
  top: 43px;
}

/* BEFORE CLICK THE UPDATE CSS */
.card-detail .email .heading {
  font-weight: 600;
  width: 85px;
  text-align: left;
}

.card-detail .email p {
  line-break: anywhere;
  line-height: 16px;
}

/* AFTER CLICK THE UPDATE CSS */
.card-detail .email.update-change .heading {
  width: auto;
}

.card-detail .email p {
  margin-bottom: 0;
}

.card-detail .change {
  background-color: #2D69F6;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 12px;
  position: absolute;
  right: -10px;
  bottom: -45px;
}

.card-detail .change:hover,
.card-detail .change:focus,
.card-detail .change:active {
  background-color: #2D69F6;
}

.card-detail .w-100 {
  width: 100% !important;
  margin: auto;
  flex-direction: column;
}

.table-dashboard .table {
  margin-bottom: 0rem !important;
}

.designation {
  color: var(--black);
  opacity: 0.6;
}

@media (max-width: 1200px) {
  .card-detail .change {
    right: -25px;
    padding: 2px 5px;
  }
}

@media (max-width: 1140px) {
  .dashboard_total .total_team {
    padding: 9px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .card-detail .change {
    right: -10px;
  }
}

@media (max-width: 991px) {
  .table {
    overflow-x: scroll;
  }

  .card-detail .change {
    right: 0px;
  }
}

@media (max-width: 575px) {
  .card-detail .change {
    right: 0;
  }

  .card-detail .email p {
    font-size: 11px;
  }
}

@media (max-width: 395px) {
  .table_heading {
    padding: 12px !important;
  }

  .table_heading .view {
    font-size: 12px;
  }

  .card-detail .email p {
    overflow: hidden;
    line-height: 18px;
  }
}
</style>
