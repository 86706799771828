<template>
    <div class="bg-white rounded">
        <div class="table pt-3 mb-0">
            <div class="add_license d-flex justify-content-between align-items-center flex-wrap mb-3 px-3">
                <h3 class="font-weight-bold f-20 text-left mb-0 text-black">Documents</h3>
                <div class="d-flex align-items-center flex-wrap">
                    <b-form-group class="text-left mb-0 pb-3 pl-0 py-sm-0 w-200px">
                        <v-select :options="allDivisons" class="f-12" :reduce="(option) => option.id"
                            v-model="searchDivision" placeholder="Select Division" />
                    </b-form-group>
                    <b-button @click="addDocument"
                        class="export equipment d-flex justify-content-center align-items-center w-fit-content"
                        v-if="allowAddDocument" variant="primary">
                        <b-icon icon="plus" class="p-0" aria-hidden="true"></b-icon>
                        <p class="mb-0 ml-2 text-white p-0">Add Document</p>
                    </b-button>
                </div>
            </div>
            <b-table responsive :fields="fields" :items="searchItems" hover :per-page="perPage" :current-page="currentPage"
                show-empty>
                <template #cell(name)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <a :href="`#${data.value
                        .replace(/[^a-z]+/i, '-')
                        .toLowerCase()}`">{{ data.value }}</a>
                </template>
                <template #cell(description)="data">
                    <p class="item-description mb-0" v-if="data.value && data.value !== 'null'">
                        {{ data.value }}
                    </p>
                    <p v-else class="mb-0">-</p>
                </template>
                <template #cell(expiry_date)="data">
                    <span v-html="data.value"></span>
                </template>
                <template #cell(division)="data">
                    <span>{{ getDivisonLabel(data.value) }}</span>
                </template>
                <template #cell(doc_type)="data">
                        {{ data.value ? getDocTypeName(data.value) : '-' }}
                      </template>
                <template #cell(driver)="data">
                    <b-form-checkbox size="lg" class="ml-2" v-model="data.value" name="check-button" switch @change="
                        updateDocumentOnToggle(
                            data.item.id,
                            'driver',
                            data.value
                        )
                        " :disabled="!allowUpdateDocument" />
                </template>
                <template #cell(dot)="data">
                    <b-form-checkbox size="lg" class="ml-2" v-model="data.value" name="check-button" switch @change="
                        updateDocumentOnToggle(
                            data.item.id,
                            'dot_mode',
                            data.value
                        )
                        " :disabled="!allowUpdateDocument" />
                </template>
                <template #cell(action)="data">
                    <svg v-if="allowViewDocument || allowUpdateDocument"
                        @click="editDocument(data.item.id, data.item.pk, data.item.name)" class="cursor-pointer mr-4"
                        width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.6426 6.19707C18.8726 6.48384 19 6.85642 19 7.24273C19 7.62904 18.8726 8.00161 18.6426 8.28838C17.186 10.052 13.8704 13.4854 10 13.4854C6.12957 13.4854 2.81403 10.052 1.35741 8.28838C1.12735 8.00161 1 7.62904 1 7.24273C1 6.85642 1.12735 6.48384 1.35741 6.19707C2.81403 4.4335 6.12957 1 10 1C13.8704 1 17.186 4.4335 18.6426 6.19707Z"
                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M10.0007 10.0154C11.533 10.0154 12.7752 8.77323 12.7752 7.24091C12.7752 5.70859 11.533 4.4664 10.0007 4.4664C8.46839 4.4664 7.2262 5.70859 7.2262 7.24091C7.2262 8.77323 8.46839 10.0154 10.0007 10.0154Z"
                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <svg @click="downloadDoc(data.item.pk, data.item.name)" class="mr-4 cursor-pointer" width="20"
                        height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.53943 11.3822L10.001 14.8437L13.4625 11.3822" stroke="#FFC800" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.0001 14.8497V5.15741" stroke="#FFC800" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                            stroke="#FFC800" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <!-- <svg @click="editDocument(data.item.id)" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                        $store.state.user.user.team_member.role === 'Operations'" class="mr-4 cursor-pointer"
                        width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                            stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> -->
                    <svg @click="showDocumentDeletePopup(data.item.pk)" class="cursor-pointer" v-if="allowDeleteDocument"
                        width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </template>
            </b-table>
            <div class="entries-pagination px-3 px-sm-4">
                <div class="d-flex align-items-center flex-wrap">
                    <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                        <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">
                        {{ positionText }}
                    </p>
                </div>
            </div>
        </div>
        <b-modal id="modal-company-docs" centered hide-footer no-close-on-backdrop size="lg" @shown="resetField"
            :body-class="'px-3 pt-2 overflow-preview-auto'" no-close-on-esc :footer-class="'d-block border-0 pt-0 pb-3 m-0'"
            :header-class="'align-items-center'" scrollable>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Document
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
                <b-dropdown id="dropdown-preview" class="load-dropdown mt-0" v-if="editDocumentFlag">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button :class="(fileName && allowUpdateDocument) || allowDeleteDocument ? 'mb-2' : ''"
                        @click="downloadDoc(documentData.id, documentData.name)">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Download</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="removeFile()" :class="allowDeleteDocument ? 'mb-2' : ''"
                        v-if="fileName && (editDocumentFlag && allowUpdateDocument)">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="showDocumentDeletePopup(documentData.id)" v-if="allowDeleteDocument">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
                    </b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown id="dropdown-preview" text="text" class="load-dropdown mt-0"
                    v-if="!editDocumentFlag && fileName">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button @click="removeFile()">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                    </b-dropdown-item-button>
                </b-dropdown>
            </template>
            <div class="d-block text-center">
                <b-row>
                    <b-col lg="4" md="8" sm="12">
                        <div class="text-left mb-3">
                            <label for="modal-datepicker" class="state">Date:</label>
                            <date-picker v-model="documentData.date" :formatter="momentFormat" :range-separator="' - '"
                                @clear="documentData.date = ''" placeholder="Select Date" class="date-picker-custom"
                                :teleport="'body'"
                                :disabled="!allowAddDocument || (editDocumentFlag && !allowUpdateDocument)"></date-picker>
                            <p v-if="errors && errors.date" class="field-error">
                                {{ errors.date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label for="modal-expiry-date" class="state">Expiry Date:</label>
                            <date-picker v-model="documentData.expiry_date" :formatter="momentFormat"
                                :range-separator="' - '" @clear="documentData.expiry_date = ''" placeholder="Select Date"
                                class="date-picker-custom" :teleport="'body'"
                                :disabled="!allowAddDocument || (editDocumentFlag && !allowUpdateDocument)"></date-picker>
                            <p v-if="errors && errors.expiry_date" class="field-error">
                                {{ errors.expiry_date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Division:</label>
                            <v-select class="f-12" :clearable="false" :options="allDivisons" v-model="documentData.division"
                                :disabled="!allowAddDocument || (editDocumentFlag && !allowUpdateDocument)"
                                :reduce="(option) => option.id" />
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Type:</label>
                            <b-form-select class="f-12" :options="getDotOptions" v-model="documentData.doc_type"
                                @change="setDocDescription($event)"
                                :disabled="!allowAddDocument || (editDocumentFlag && !allowUpdateDocument)">
                            </b-form-select>
                            <p v-if="errors && errors.doc_type" class="field-error">
                                {{ errors.doc_type[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Description:</label>
                            <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                                v-model="documentData.description"
                                :disabled="!allowAddDocument || (editDocumentFlag && !allowUpdateDocument)"></b-form-textarea>
                            <p v-if="errors && errors.description" class="field-error">
                                {{ errors.description[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Driver:</label>
                            <b-form-checkbox v-model="documentData.driver" name="check-button" switch size="lg"
                                :disabled="!allowAddDocument || (editDocumentFlag && !allowUpdateDocument)"
                                class="ml-0-6rem" />
                        </div>
                        <div class="text-left">
                            <label class="state">DOT Mode:</label>
                            <b-form-checkbox v-model="documentData.dot_mode" name="check-button" switch size="lg"
                                :disabled="!allowAddDocument || (editDocumentFlag && !allowUpdateDocument)"
                                class="ml-0-6rem" />
                        </div>
                    </b-col>
                    <b-col lg="8" sm="12">
                        <div class="upload-btn-wrapper" v-if="!fileName">
                            <b-img :src="require('@/assets/drop.svg')"></b-img>
                            <button v-if="!fileName" class="foo">
                                Drop your document here, or browse
                            </button>
                            <button v-else class="foo">{{ fileName }}</button>

                            <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                                accept=".pdf, .png, .jpg, .jpeg" />
                            <p v-if="errors && errors.document" class="field-error">
                                {{ errors.document[0] }}
                            </p>
                        </div>
                        <div v-else>
                            <div class="review-media-section" :class="mediaType === 'pdf' ? 'overflow-hidden' : ''">
                                <iframe class="w-100 h-100 border-0" v-if="mediaType === 'pdf'" :src="media"></iframe>
                                <div v-else>
                                    <div id="panzoom-element" ref="panzoomElement">
                                        <img :src="media" class="w-100 h-100 img-section" />
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center zoom-section">
                                        <b-icon @click="zoom(1)" icon="zoom-in"
                                            style="fill:white; cursor: pointer;"></b-icon>
                                        <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out"
                                            style="fill:white;cursor: pointer;"></b-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-4">
                <b-button class="mx-0 h-40px w-100px m-0" :class="hasChanged ? 'mr-3' : ''"
                    @click="$bvModal.hide('modal-company-docs'); panzoom = null" variant="secondary">{{ editDocumentFlag ?
                        'Close' :
                        'Cancel' }}</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="sendDocumentData"
                    v-if="hasChanged && (allowAddDocument || (editDocumentFlag && allowUpdateDocument))">Save</b-button>
            </div>
        </b-modal>
        <DeleteModal v-if="showDeletePopup" @confirm="deleteItem($event)" />
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as moment from "moment-timezone";
import generatePdf from "../../services/generatePDF";
import DeleteModal from "@/components/DeleteModal.vue";
import { checkValueExists } from "../../services/helper";
import Panzoom from '@panzoom/panzoom'
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name: "Documents",
    components: { DeleteModal, vSelect, DatePicker },
    data() {
        return {
            checkValueExists,
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            editDocumentFlag: false,
            items: [],
            panzoom: null,
            searchDivision: null,
            perPage: 50,
            currentPage: 1,
            documentId: 0,
            emptyTable: false,
            documentIndex: null,
            fileName: null,
            defaultData: {},
            showDeletePopup: false,
            media: null,
            mediaType: null,
            mediaID: null,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            documentOptions: [
                {
                    value: null,
                    text: "",
                },
                {
                    value: "Permit",
                    text: "Permit",
                },
                {
                    value: "Insurance",
                    text: "Insurance",
                },
                {
                    value: "IFTA",
                    text: "IFTA",
                },
                {
                    value: "ELD Documents",
                    text: "ELD Documents",
                },
                {
                    value: "Other",
                    text: "Other",
                },
            ],
            documentData: {
                object_id: null,
                date: null,
                type: null,
                division: null,
                expiry_date: "",
                document: null,
                description: "",
                driver: true,
            },
            errors: {
                date: null,
                type: null,
                document: null,
                expiry_date: "",
                description: null,
            },
            options: [
                {
                    value: "Illinois",
                    text: "Illinois",
                },
                {
                    value: "Chicago",
                    text: "Chicago",
                },
                {
                    value: "Mexico",
                    text: "Mexico",
                },
            ],
            fields: [
                {
                    key: "Description",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                    stickyColumn: true,
                    thStyle: { width: "200px" },
                },
                {
                    key: "expiry_date",
                    label: "Expiry Date",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "doc_type",
                    label: "Doc Type",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "division",
                    label: "Division",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Driver",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: 'DOT Mode',
                    key: "Dot",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                    // Variant applies to the whole column, including the header and footer
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['company', "allCountries", "userCompany", 'divisions', 'rolePermissions', 'docTypeItems']),
        allowViewDocument() {
            return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][0].allow
        },
        allowAddDocument() {
            return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][2].allow
        },
        allowUpdateDocument() {
            return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][1].allow
        },
        allowDeleteDocument() {
            return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][3].allow
        },
        allDivisons() {
            return this.divisions.map((item) => {
                return {
                    id: item.id,
                    label: item.division,
                }
            })
        },
        getDotOptions() {
            return [...[{
                value: "",
                text: "",
            }], ...this.docTypeItems?.filter((item) => item.model === 'Company' || item.model === 'All').map((item) => {
                return {
                    value: item.id,
                    text: item.type,
                }
            })]
        },
        hasChanged() {
            return Object.keys(this.documentData).filter(field => {
                if (field !== 'updated_at') {
                    if (typeof this.documentData[field] === 'object') return JSON.stringify(this.documentData[field]) !== JSON.stringify(this.defaultData[field])
                    if (field === 'description') {
                        return this.checkValueExists(this.documentData[field]?.trim().replace(/\r\n|\r|\n/g, '\n')) != this.checkValueExists(this.defaultData[field]?.trim().replace(/\r\n|\r|\n/g, '\n'));
                    }
                    else if (!this.documentData[field] && !this.defaultData[field]) return false
                    else return this.checkValueExists(this.documentData[field]) != this.checkValueExists(this.defaultData[field])
                } return false
            }).length > 0
        },
        rows() {
            return this.searchItems.length;
        },
        positionText() {
            let endIndex = this.currentPage * this.perPage,
                startIndex =
                    this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

            return (
                startIndex +
                " to " +
                (endIndex > this.rows ? this.rows : endIndex) +
                " of " +
                this.rows
            );
        },
        searchItems() {
            const searchKeysWithFields = {
                division: this.searchDivision
            };
            let filteredItems = this.items;
            for (let [field, searchQuery] of Object.entries(searchKeysWithFields)) {
                if (searchQuery) {
                    console.log(searchQuery)
                    filteredItems = this.searchByKeys(searchQuery, field, filteredItems);
                }
            }
            return filteredItems;
        },
    },
    methods: {
        ...mapActions(['sendCompanyDocuments', 'updateCompanyDocuments', 'deleteCompanyDocuments', 'retrieveDocument', "getAllCountries", "setNotification", "setLoader", "getCompany"]),
        getDocTypeName(id){
            return this.getDotOptions.filter((option) => option.value === id)[0].text
        },
        getDivisonLabel(id) {
            return this.divisions.filter((division) => division.id === id)[0]?.division
        },
        setDocDescription(event) {
            if (event) {
                this.documentData.description = this.getDotOptions.filter((option) => option.value === event)[0].text
            }
            else this.documentData.description = this.defaultDocsData.description
        },
        searchByKeys(searchQuery, field, filteredItems) {
            return filteredItems.filter(
                (x) =>
                    x[field] &&
                    x[field]
                        .toString()
                        .toLowerCase()
                        .includes(searchQuery.toString().toLowerCase())
            );
        },
        zoom(level) {
            if (!this.panzoom) {
                this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
                    maxScale: 5
                })
            }
            level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
        },
        removeFile() {
            this.fileName = null;
            this.documentData.document = null
            this.media = null
            this.mediaType = null
        },
        resetField() {
            if (this.$refs.docFile) this.$refs.docFile.value = null;
        },
        async downloadDoc(id, name) {
            this.setLoader(true)
            const doc = await this.retrieveDocument(id);
            fetch(doc.document)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const newUrl = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = newUrl;
                    a.download = name
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(newUrl);
                    a.remove()
                    this.setLoader(false)
                })
                .catch(error => {
                    this.setLoader(false)
                    console.log(error)
                });
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        deleteItem(item) {
            if (item) {
                this.deleteDocument(this.documentId);
            }
            this.showDeletePopup = false;
        },
        showDocumentDeletePopup(id) {
            this.showDeletePopup = true;
            this.documentId = id;
        },
        getPdf(data) {
            let country = "-";
            if (this.company[0].state.country) {
                country = this.allCountries.filter(
                    (country) => country.id === this.company[0].state.country
                )[0].name;
            }
            generatePdf(data, this.company, country);
        },
        handleFileUpload() {
            this.documentData.document = this.$refs.docFile.files[0];
            this.fileName = this.documentData.document.name;
            this.media = URL.createObjectURL(this.documentData.document);
            if (this.$refs.docFile.files[0].type.includes('pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
        },
        addDocument() {
            this.fileName = null;
            this.editDocumentFlag = false;
            this.defaultData = {}
            this.$bvModal.show("modal-company-docs");
            this.documentData = {
                object_id: null,
                date: new Date(),
                type: null,
                document: null,
                division: this.divisions.filter((division) => division.default)[0]?.id,
                expiry_date: "",
                description: null,
                driver: true,
                dot_mode: true,
                doc_type: ""
            };
            this.errors = {
                date: null,
                type: null,
                document: null,
                expiry_date: "",
                description: null,
                ...this.company[0].doc_type ? { doc_type: null } : {}
            };
        },
        async sendDocumentData() {
            try {
                const nullKeys = this.checkNullValues({
                    date: this.documentData.date,
                    document: this.documentData.document,
                    ...this.company[0].doc_type ? { doc_type: this.documentData.doc_type } : {}
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true);
                    let formData = new FormData();
                    let fileType = this.documentData.document instanceof File || this.documentData.document instanceof Blob;
                    if (!fileType) this.documentData.document = null
                    formData.append("object_id", this.userCompany);
                    formData.append(
                        "date",
                        moment(this.documentData.date).format("YYYY-MM-DD")
                    );
                    formData.append("type", this.documentData.type);
                    formData.append("description", this.documentData.description);
                    formData.append("division", this.documentData.division);
                    formData.append("expiry_date", this.documentData.expiry_date ? moment(this.documentData.expiry_date).format("YYYY-MM-DD") : '');
                    if (this.documentData.doc_type) formData.append("doc_type", this.documentData.doc_type);
                    if (this.documentData.document)
                        formData.append(
                            "document",
                            new File(
                                [this.documentData.document],
                                this.company[0].name + "_" +
                                this.documentData.description +
                                "_" + this.userCompany + "_" +
                                new Date().getTime() +
                                "." +
                                this.documentData.document["type"].split("/").pop(),
                                { type: this.documentData.document["type"] }
                            )
                        );
                    formData.append("driver", this.documentData.driver);
                    formData.append("dot_mode", this.documentData.dot_mode);
                    formData.append("model_type", "company");
                    formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
                    if (this.editDocumentFlag) {
                        this.documentData = await this.updateCompanyDocuments({
                            id: this.documentData.id,
                            payload: formData,
                        });
                        await this.setNotification({
                            msg: "Document Successfully updated!!",
                            type: "success",
                            color: "green",
                        });
                        await this.getCompany()
                        this.$bvModal.hide("modal-company-docs");
                        this.documentData = {
                            object_id: null,
                            date: null,
                            type: null,
                            expiry_date: "",
                            document: null,
                            description: null,
                            driver: true,
                            dot_mode: true
                        };
                        this.updateItems();
                        this.fileName = null;
                    } else {
                        if (this.documentData.date) {
                            await this.sendCompanyDocuments(formData);
                            await this.getCompany()
                            this.updateItems();
                            this.$bvModal.hide("modal-company-docs");
                            await this.setNotification({
                                msg: "Document Successfully added!!",
                                type: "success",
                                color: "green",
                            });
                            this.documentData = {
                                object_id: null,
                                date: null,
                                division: this.divisions.filter((division) => division.default)[0]?.id,
                                type: null,
                                document: null,
                                description: null,
                                expiry_date: "",
                                driver: true,
                                dot_mode: true,
                                doc_type: ""
                            };
                            this.fileName = null;
                        } else {
                            this.errors.date = this.documentData.date
                                ? null
                                : ["This field is required."];
                            // this.errors.description = this.documentData.description? null: ['This field is required.']
                            console.log(this.errors);
                        }
                    }
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
                this.setLoader(false);
            } catch (error) {
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        async openLink(id) {
            const doc = await this.retrieveDocument(id);
            window.open(doc.document, "_blank");
        },
        async editDocument(id, docID, name) {
            this.editDocumentFlag = true;
            this.documentIndex = id;
            this.documentData = { ...this.company[0].docs[id], name: name, doc_type: this.company[0].docs[id].doc_type_id, division: this.company[0].docs[id].division ? this.company[0].docs[id].division : this.company[0].docs[id].division_id, date: moment.tz(this.company[0].docs[id].date, this.userTimeZone).toDate(), expiry_date: this.company[0].docs[id].expiry_date ? moment.tz(this.company[0].docs[id].expiry_date, this.userTimeZone).toDate() : "" }
            if (this.documentData.document) {
                let file_name = "";
                if (typeof this.documentData.document === "string") {
                    file_name = this.documentData.document;
                } else {
                    file_name = this.documentData.document.name;
                }
                this.fileName = file_name.substring(file_name.lastIndexOf("/") + 1);
            } else {
                this.documentData.document = null;
                this.fileName = "";
                this.errors = {
                    date: null,
                    type: null,
                    document: null,
                    description: null,
                };
            }
            await this.openDoc(docID)
            this.defaultData = JSON.parse(JSON.stringify(this.documentData))
            this.$bvModal.show("modal-company-docs");
        },
        async updateDocumentOnToggle(id, key, value) {
            try {
                this.company[0].docs[id][key] = value;
                if (!this.company[0].docs[id].expiry_date)
                    delete this.company[0].docs[id].expiry_date;
                delete this.company[0].docs[id].document;
                await this.updateCompanyDocuments({
                    id: this.company[0].docs[id].id,
                    payload: this.company[0].docs[id],
                });
                await this.setNotification({
                    msg: "Document successfully updated!!",
                    type: "success",
                    color: "green",
                });
                await this.getCompany()
                this.updateItems();
            } catch (error) {
                console.log(error);
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        async deleteDocument(id) {
            try {
                this.setLoader(true)
                await this.deleteCompanyDocuments(`${id}/?company_id=${this.userCompany}`);
                await this.setNotification({
                    msg: "Document successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                this.$bvModal.hide("modal-company-docs");
                await this.getCompany()
                this.updateItems();
                this.setLoader(false)
            } catch (error) {
                console.log(error);
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        updateItems() {
            this.items = [];
            this.companyDocuments = this.company[0].docs.sort((a, b) => a?.description?.toLowerCase().localeCompare(b?.description?.toLowerCase()));
            this.companyDocuments.forEach((doc, id) => {
                let color;
                if (doc.expiry_date) {
                    const currentDate = new Date();
                    const expiryDate = new Date(doc.expiry_date);
                    const diffDays = parseInt(
                        (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                        10
                    );
                    if (diffDays > 31) {
                        color = "color:black";
                    } else if (diffDays <= 31 && diffDays >= 1) {
                        color = "color:#FFC800";
                    } else color = "color:#FF0000";
                }
                this.items.push({
                    id: id,
                    pk: doc.id,
                    Date: moment(doc.date).format("MMM DD, YYYY"),
                    Description: doc.description,
                    expiry_date: doc.expiry_date
                        ? `<span style=${color}>${moment(doc.expiry_date).format(
                            "MMM DD, YYYY"
                        )}</span>`
                        : "-",
                    Driver: doc.driver,
                    Public: doc.public,
                    link: doc.document,
                    Dot: doc.dot_mode,
                    name: `${this.company[0].name}-${doc.description && doc.description !== 'null' ? doc.description : ''}`,
                    division: doc.division ? doc.division : doc.division_id,
                    doc_type: doc.doc_type_id
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
            });
        },
        async openDoc(id) {
            const doc = await this.retrieveDocument(id);
            this.media = doc.document
            this.mediaID = id
            this.itemId = id;
            if (doc.document.includes('.pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
        },
        downloadFile(uri) {
            axios({
                method: 'get',
                url: uri,
                responseType: 'blob',
            }).then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                const fileName = this.docName
                a.download = fileName
                document.body.appendChild(a);
                a.click();
                a.remove()
                window.URL.revokeObjectURL(url);
            });
        },
    },
    mounted() {
        this.updateItems();
        this.getAllCountries()
    }
}
</script>