<template>
    <div class="bg-white rounded">
        <div class="table pt-3 mb-0">
            <div class="add_license d-flex justify-content-between align-items-center flex-wrap mb-3 px-3">
                <h3 class="font-weight-bold f-20 text-left mb-0 text-black">Doc Types</h3>
                <b-button @click="$bvModal.show('add-type-modal')" v-if="allowAddDocType"
                    class="export equipment d-flex justify-content-center align-items-center w-fit-content"
                    variant="primary">
                    <b-icon icon="plus" class="p-0" aria-hidden="true"></b-icon>
                    <p class="mb-0 ml-2 text-white p-0">Add Type</p>
                </b-button>
            </div>
            <b-table responsive :fields="fields" :items="items" hover :per-page="perPage" :current-page="currentPage"
                show-empty>
                <template #cell(required)="data">
                    <span>{{ data.value ? 'YES' : 'NO' }}</span>
                </template>
                <template #cell(action)="data">
                    <div v-if="data.item.model !== 'All' && allowUpdateDocType">
                        <svg @click="editDocTypeItem(data.item)" class="cursor-pointer" width="21" height="21"
                            viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                                stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div v-else>-</div>
                </template>
            </b-table>
            <div class="entries-pagination px-3 px-sm-4">
                <div class="d-flex align-items-center flex-wrap">
                    <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                        <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">
                        {{ positionText }}
                    </p>
                </div>
            </div>
        </div>
        <b-modal id="add-type-modal" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
            :body-class="'equipment-edit-modal'" no-close-on-esc :header-class="'align-items-center'">
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    {{ editDocType ? 'Edit Type' : 'Add Type' }}
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="body">
                <b-row>
                    <b-col cols="12" class="mt-3">
                        <b-form-group label="Type" class="text-left mb-0">
                            <b-form-input v-model="form.type" placeholder="Enter Type" class="unit"
                                :disabled="!allowAddDocType && (!editDocType || ( editDocType && !allowUpdateDocType))"
                                required></b-form-input>
                            <p v-if="errors && errors.type" class="field-error mb-lg-3">
                                {{ errors.type[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-3">
                        <b-form-group label="Model" class="text-left mb-0">
                            <b-form-select v-model="form.model"
                            :disabled="!allowAddDocType && (!editDocType || ( editDocType && !allowUpdateDocType))"
                                :options="[
                                    {
                                        value: '',
                                        text: '',
                                    },
                                    {
                                        value: 'Driver',
                                        text: 'Driver',
                                    },
                                    {
                                        value: 'Vehicle',
                                        text: 'Vehicle',
                                    },
                                    {
                                        value: 'Trailer',
                                        text: 'Trailer',
                                    },
                                    {
                                        value: 'Company',
                                        text: 'Company',
                                    }

                                ]" class="unit f-12" required />
                            <p v-if="errors && errors.model" class="field-error mb-lg-3">
                                {{ errors.model[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-3">
                        <b-form-group label="Required" class="text-left mb-0">
                            <b-form-select  :disabled="!allowAddDocType && (!editDocType || ( editDocType && !allowUpdateDocType))"
                                v-model="form.required" :options="[
                                    {
                                        value: '',
                                        text: '',
                                    },
                                    {
                                        value: true,
                                        text: 'Yes',
                                    },
                                    {
                                        value: false,
                                        text: 'No',
                                    }

                                ]" class="unit f-12" required />
                            <p v-if="errors && errors.required" class="field-error mb-lg-3">
                                {{ errors.required[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <div class="text-right modal-footer border-0 p-0 mx-2">
                    <b-button class="mx-0 h-40px w-100px m-0"
                        :class="(allowAddDocType && !editDocType)  || (editDocType && allowUpdateDocType) ? 'mr-3' : ''"
                        @click="resetForm()" variant="secondary">Cancel</b-button>
                    <div class="d-flex align-items m-0"
                        v-if="(allowAddDocType && !editDocType) || (editDocType && allowUpdateDocType)">
                        <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary"
                            @click="updateDocType()">Save</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: "DocTypes",
    data() {
        return {
            items: [],
            perPage: 50,
            currentPage: 1,
            editDocType: false,
            docTypeID: null,
            emptyTable: false,
            showDeletePopup: false,
            docTypeData: null,
            form: {
                type: "",
                model: "",
                required: "",
            },
            errors: {},
            fields: [
                {
                    key: "type",
                    label: "Type",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                    stickyColumn: true,
                },
                {
                    key: "model",
                    label: "Model",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "required",
                    label: "Required",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
        }
    },
    computed: {
        ...mapGetters(["userCompany", 'rolePermissions']),
        allowViewDocType() {
            return this.rolePermissions && this.rolePermissions['Doc Types'] && this.rolePermissions['Doc Types'][0].allow
        },
        allowAddDocType() {
            return this.rolePermissions && this.rolePermissions['Doc Types'] && this.rolePermissions['Doc Types'][2].allow
        },
        allowUpdateDocType() {
            return this.rolePermissions && this.rolePermissions['Doc Types'] && this.rolePermissions['Doc Types'][1].allow
        },
        allowDeleteDocType() {
            return this.rolePermissions && this.rolePermissions['Doc Types'] && this.rolePermissions['Doc Types'][3].allow
        },
        rows() {
            return this.items.length;
        },
        positionText() {
            let endIndex = this.currentPage * this.perPage,
                startIndex =
                    this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

            return (
                startIndex +
                " to " +
                (endIndex > this.rows ? this.rows : endIndex) +
                " of " +
                this.rows
            );
        }
    },
    methods: {
        ...mapActions(["getAllDocTypes", "setLoader", "setNotification", "addDocTypeItem", "updateDocTypeItem"]),
        async updateDocType() {
            try {
                this.errors = {}
                const nullKeys = this.checkNullValues({
                    type: this.form.type,
                    model: this.form.model,
                    required: this.form.required
                });
                if (nullKeys.length === 0) {
                    let method = 'added'
                    if (this.editDocType) {
                        method = 'updated'
                        try {
                            this.setLoader(true)
                            await this.updateDocTypeItem({
                                id: this.docTypeID,
                                payload: { ...this.form, company: this.userCompany }
                            })
                        }
                        catch (e) {
                            this.setLoader(false)
                            await this.setNotification({
                                msg: e,
                                type: "error",
                                color: "red",
                            });
                            return
                        }
                    }
                    else {
                        try {
                            this.setLoader(true)
                            await this.addDocTypeItem({ ...this.form, company: this.userCompany })
                        }
                        catch (e) {
                            this.setLoader(false)
                            await this.setNotification({
                                msg: e,
                                type: "error",
                                color: "red",
                            });
                            return
                        }
                    }
                    await this.setNotification({
                        msg: `Doc Type successfully ${method}!!`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.resetForm()
                    this.getAllDocTypesData()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (value === "") {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        resetForm() {
            this.errors = {}
            this.$bvModal.hide('add-type-modal')
            this.docTypeData = null
            this.docTypeID = null
            this.editDocType = false
            this.form = {
                type: "",
                model: "",
                required: "",
            }

        },
        editDocTypeItem(data) {
            this.editDocType = true
            this.form = {
                type: data.type,
                model: data.model,
                required: data.required,
            }
            this.docTypeID = data.id
            this.$bvModal.show('add-type-modal')
        },
        async getAllDocTypesData() {
            try {
                this.items = []
                const data = await this.getAllDocTypes()
                data.map((item) => {
                    this.items.push({
                        id: item.id,
                        type: item.type,
                        model: item.model,
                        required: item.required
                    })
                })
            }
            catch (e) {
                console.log(e)
            }
        }
    },
    async mounted() {
        this.getAllDocTypesData()
    }
}
</script>