<template>
  <b-container fluid class="form login-form">
    <b-row>
      <b-col lg="6" md="6" sm="12" class="left-side">
        <b-row>
          <b-col lg="9" md="12" sm="12" class="m-md-0 my-0 my-md-5">
            <div class="fix-width fix-width-login">
              <div class="logo mb-3 pb-3 text-left">
                <b-img
                  class=""
                  :src="require('@/assets/new-logo.png')"
                  width="300"
                  height="50"
                ></b-img>
              </div>
              <!-- <div class="heading text-left mb-4">
                <h1 class="mb-0">Welcome Back</h1>
                <p>Sign in to continue to Digital Permit Book</p>
              </div> -->
              <b-form @submit.prevent="onLogin">
                <b-form-group
                  id="input-group-1"
                  label="Email:"
                  label-for="input-1"
                  class="text-left label"
                >
                  <b-form-input
                    id="input-1"
                    type="email"
                    class="mb-3 input h-50px"
                    required
                    placeholder="Enter Email"
                    v-model="user.username"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Password:"
                  label-for="input-2"
                  class="text-left label password auth-form-group"
                >
                  <b-form-input
                    id="input-2"
                    class="mb-3 input h-50px"
                    required
                    placeholder="Enter Password"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="user.password"
                  ></b-form-input>

                  <b-icon
                    :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"
                    @click="showPassword = !showPassword"
                  ></b-icon>
                </b-form-group>
                <div class="text-left mb-3">
                <router-link
                    class="font-weight-normal opacity-50 f-14"
                    to="/forgot-password"
                    >Forgot Password?</router-link
                  >
                </div>
                <!-- <b-form-group
                  id="input-group-4"
                  class="text-left position-relative"
                >
                  <b-form-checkbox-group id="checkboxes-4">
                    <b-form-checkbox value="that">Remember me</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group> -->

                <b-button type="submit" class="w-100 mt-2 login h-50px"
                variant="primary"
                  >Log In</b-button
                >
                <!-- <div class="signup mt-3">
                  Dont have an account?
                  <router-link to="/register">Signup now</router-link>
                </div> -->
              </b-form>
            </div>
          </b-col>
          <p class="mb-0 f-14 opacity-50 copyright-text">© {{new Date().getFullYear()}} Copyright Digital Permit Book LLC.</p>
        </b-row>
      </b-col>
      <b-col cols="6" md="6" class="p-0 auth-bg-img">
        <Auth />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Auth from "../../components/auth/Auth.vue";
import { mapActions } from "vuex";
export default {
  components: { Auth },
  name: "Login",
  data() {
    return {
      showPassword: false,
      user: {
        username: "",
        password: "",
      },
    };
  },

  methods: {
    ...mapActions(["loginUser", "setNotification","getUserCompanies"]),
    async onLogin() {
      if (!this.user.username || !this.user.password) {
        this.setNotification({
          msg: "Please enter credentials",
          type: "error",
          color: "red",
        });
      } else {
        try {
          const { key } = await this.loginUser({
            payload:{
            email: this.user.username,
            password: this.user.password},
            registerCompany: false
          });

          if (key) {
            this.setNotification({
              msg: "Succesfully Login",
              type: "success",
              color: "green",
            });
            await this.getUserCompanies()
            if (localStorage.getItem("userSelectedCompany")) this.$router.push("/")
            else this.$router.push("/all-companies");
          }
        } catch (error) {
          this.setNotification({
            msg: error,
            type: "error",
            color: "red",
          });
        }
      }
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* .login-form .right-img {
  

  height: calc(100vh) ;
  overflow: hidden;
} */

.left-side {
  background-color: white;
}
.right-img img {
  object-fit: cover;
  height: 100%;
}
.fix-width {
  width: 397px;
}
.left-side {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .form {
  background-color: #f8f8f8;
} */
.heading h1 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  color: var(--btn-color);
}
.heading p {
  font-size: var(--small);
  line-height: 21px;
  font-weight: 400;
  color: var(--black);
  opacity: 0.6;
}
.label {
  font-size: var(--small) !important;
  font-weight: 500;
  line-height: 21px;
  font-family: poppins;
  position: relative;
}
.input {
  border: 1px solid #e3e3e3 !important;
  background-color: #f5f5f5 !important;
  border-radius: 5px !important;
}
.input.h-50px, .vue-tel-input.h-50px{
  height: 50px !important;
}
.forgot {
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  color: var(--black);
  opacity: 0.6;
  font-size: var(--extrasmall);
  line-height: 18px;
}
.forgot:hover {
  color: var(--black);
  opacity: 0.6;
}
.login {
  background-color: var(--btn-bg-color) !important;
  border: none !important;
  font-size: var(--small) !important;
  height: 40px;
}
.signup {
  color: var(--black);
  font-size: var(--small);
  line-height: 21px;
  font-family: poppins;
}
.signup a {
  color: var(--btn-color) !important;
  font-weight: 600;
  text-decoration: none;
}
.custom-control-input[type="checkbox"] {
  cursor: pointer;
  visibility: hidden;
  overflow: hidden;
  margin-right: 5px;
  width: 19px;
  height: 19px;
  top: 4px;
  position: relative;
}
.custom-control-label {
  font-size: var(--small);
  font-weight: 400;
  padding-top: 2px;
  color: var(--black);
}
.custom-control-input[type="checkbox"]::after {
  content: " ";
  display: inline-block;
  visibility: visible;
}
.custom-control-input[type="checkbox"]::before {
  content: " ";
  position: absolute;
  top: 0;
  border-radius: 3px;
  width: 19px;
  height: 19px;
  display: inline-block;
  visibility: visible;
  border: 1px solid #c7c7c7;
}
.custom-control-input[type="checkbox"]:checked::after {
  content: "\2714";
  font-size: 10px;
  text-align: center;
  width: 17px;
  height: 17px;
  position: relative;
  left: 1px;
  top: -5px;
  background-color: #fff;
  color: var(--btn-color);
  border-radius: 3px;
}
.custom-control-inline {
  padding-left: 0 !important;
}
.login-form .b-icon :not(svg) {
  color: var(--black);
}
.password {
  position: relative;
}
.password .b-icon {
  position: absolute;
  top: 42px;
  right: 13px;
}
.auth-form-group.password .b-icon{
  top: 49px;
}
.copyright-text{
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
}
.h-50px .vs__actions{
  padding: 10px 6px 0 3px !important;
}
@media (min-width: 1201px) {
  .login-form .auth-bg-img {
    height: 100vh;
  }
}
@media (max-width: 1200px) {
  .fix-width.fix-width-login {
    width: 397px !important;
  }
  /* .login-form .right-img{
    height: 100%;
    height: calc(100vh);
  } */
  .auth-bg-img {
    height: auto;
  }
}
@media (max-width: 991px) {
  .fix-width.fix-width-login {
    width: auto !important;
  }
  .right-img {
    height: 100%;
  }
  /* .right-img img{
    height: calc(100vh);
    height: 100%;
    object-fit: cover;
  } */
}
@media (max-width: 767px) {
  .auth-bg-img {
    display: none;
  }
}
@media (max-width: 568px) {
  .login-form .row {
    height: 100vh;
  }
}
</style>
