<template>
  <div>
    <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 team-page">
      <b-row>
        <b-col cols="" class="">
          <b-row>
            <b-col cols="">
              <div class="documents-alert-section f-14 text-left" v-if="!!showDocumentProgess">
                Smart Drop{{ showDocumentProgess.requestor === 2 ? ' +' : '' }} is processing your zip file. Please check
                back
                soon!
              </div>
              <div class="documents-progess-section f-14 text-left d-flex align-items-center"
                v-if="!!showDocumentUploadStatus">
                <div class="w-75">
                  Smart Drop + has processed your file. Results are as follows :
                  | {{ showDocumentUploadStatus.desc_json.total_completed }} Completed Files | {{
                    showDocumentUploadStatus.desc_json.total_incomplete }} Incomplete Files | {{
    showDocumentUploadStatus.desc_json.total_failed_files }}
                  Failed to read |
                  {{ showDocumentUploadStatus.desc_json.total_incomplete +
                    showDocumentUploadStatus.desc_json.data_processing_errors.length }}
                  Total files require review | {{
                    showDocumentUploadStatus.desc_json.unsupported_file_formats.length }} Unsupported files are ignored |
                </div>
                <p class="mb-0 text-primary cursor-pointer f-14 ml-auto" @click="dimissUploadStatus">Dismiss</p>
              </div>
              <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
                <h2 class="text-left">Documents</h2>
                <div class="d-flex justify-content-between justify-content-sm-center flex-wrap">
                  <b-button @click="addDocument"
                    v-if="!reviewDocs && !showFileDrop && (allowCreateDocDrivers || allowCreateDocEquipment || allowCreateDocCompany)"
                    class="export equipment d-flex justify-content-center align-items-center mx-1 my-1 w-fit-content"
                    variant="primary">
                    <b-icon icon="plus" aria-hidden="true"></b-icon>
                    <p class="mb-0 ml-2 text-white">Add Document</p>
                  </b-button>
                  <b-button v-else-if="selectedRows.length && reviewDocs"
                    class="export equipment d-flex justify-content-center align-items-center mx-1 my-1 w-125px"
                    variant="primary" @click="approveDocs">Approve
                  </b-button>
                  <b-dropdown id="dropdown-divider" text="text" class="load-dropdown-2"
                    v-if="((!showDocumentProgess && showSmartDropAndSmartDropPlusSection) || selectedRows.length)">
                    <template slot="button-content">
                      <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button @click="checkUploadStatus('smartDrop')" class="mb-2"
                      v-if="!showDocumentProgess && showSmartDropAndSmartDropPlusSection">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Smart Drop</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="checkUploadStatus('smartDrop+')"
                      :class="selectedRows.length ? 'mb-2' : 'mb-0'"
                      v-if="!showDocumentProgess && showSmartDropAndSmartDropPlusSection">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Smart Drop +</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="checkDocType()" v-if="selectedRows.length" class="mb-2">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Doc Type</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="selectedRows.length" class="mb-2" @click="checkDriverShare()">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Driver Share</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="selectedRows.length" class="mb-2" @click="setExpiryDate()">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Expiry Date</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="selectedRows.length && allowUpdateDocEquipment" class="mb-2"
                      @click="showPublicShare()">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Public Share</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="selectedRows.length" class="mb-2" @click="checkDotMode()">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Dot Mode</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="selectedRows.length" class="mb-2" @click="downloadBulkFiles()">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Download {{ selectedRows.length }} Files</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="selectedRows.length" @click="checkBulkDeleteDocumentsStatus()">
                      <p class="text_secondary cursor-pointer mb-0 f-14">Delete {{ selectedRows.length }} Rows</p>
                    </b-dropdown-item-button>
                  </b-dropdown>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="data-table team-data-table table-custom-border rounded-top">
                <div class="bg-white rounded">
                  <v-tabs class="team-tabs mb-0" v-if="isTableMounted">
                    <v-tab @click="setDocumentType(''); showFileDrop = false">All
                      <span class="f-12 ml-1"> ({{
                        allCount ?
                        allCount : 0 }})</span>
                    </v-tab>
                    <v-tab v-if="showReviewSection" @click="setDocumentType('Review'); showFileDrop = false">Review <span
                        class="f-12 ml-1"> ({{
                          reviewDocsCount ?
                          reviewDocsCount : 0 }})</span></v-tab>
                    <v-tab v-if="showFileDropSection" @click="setFileDropSection()">File Drop
                      <span class="f-12 ml-1"> ({{
                        fileDropCount ?
                        fileDropCount : 0 }})</span>
                    </v-tab>
                  </v-tabs>
                </div>
                <div class="bg-white pt-3 pt-sm-4" v-if="!showFileDrop">
                  <div class="table_row team_table_row">
                    <div class="equipment-border filter-box">
                      <div class="d-flex mb-4 px-3 px-sm-4">
                        <b-form-group class="text-left label font-weight-normal search-field mb-0">
                          <b-input-group class="username-input-field">
                            <b-form-input type="text" placeholder="Search" v-model="filterFields.search_field"
                              class="unit f-12" @keyup.enter="getAllDocs()"></b-form-input>
                            <b-input-group-append>
                              <b-button variant="primary" @click="getAllDocs()">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </svg>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                        <div class="ml-auto align-items-center d-flex flex-wrap">
                          <div class="position-relative">
                            <div class="position-absolute notification-badge" v-if="activeFiltersCount">
                              {{ activeFiltersCount }}
                            </div>
                            <b-dropdown id="dropdown-divider" ref="filterDropdown"
                              class="filter-section table-columns-dropdown mt-0">
                              <template slot="button-content">
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M8.74045 19C8.55379 19 8.37477 18.9256 8.24278 18.7933C8.11079 18.6609 8.03663 18.4813 8.03663 18.2941V11.5071L1.51223 4.31059C1.2554 4.02674 1.08624 3.67422 1.02528 3.29583C0.964316 2.91743 1.01418 2.52942 1.16881 2.17889C1.32344 1.82836 1.57619 1.53038 1.89639 1.32112C2.21659 1.11185 2.59047 1.0003 2.97266 1H18.0273C18.4095 1.0003 18.7834 1.11185 19.1036 1.32112C19.4238 1.53038 19.6766 1.82836 19.8312 2.17889C19.9858 2.52942 20.0357 2.91743 19.9747 3.29583C19.9138 3.67422 19.7446 4.02674 19.4878 4.31059L12.9634 11.5071V15.4706C12.9633 15.5763 12.9395 15.6807 12.8939 15.776C12.8482 15.8713 12.7817 15.9551 12.6994 16.0212L9.18034 18.8447C9.05557 18.9451 8.90042 18.9999 8.74045 19ZM2.97266 2.41176C2.86314 2.41204 2.75604 2.44414 2.66434 2.50419C2.57263 2.56424 2.50024 2.64966 2.45594 2.75011C2.41163 2.85056 2.3973 2.96174 2.41468 3.07019C2.43207 3.17864 2.48042 3.27971 2.55389 3.36118L9.26128 10.7729C9.3762 10.8997 9.44123 11.064 9.44427 11.2353V16.8259L11.5557 15.1176V11.2353C11.5553 11.0591 11.6206 10.8892 11.7387 10.7588L18.4461 3.34706C18.5152 3.26523 18.5598 3.16547 18.5748 3.0593C18.5897 2.95312 18.5744 2.84488 18.5306 2.74706C18.4868 2.64924 18.4163 2.56585 18.3273 2.50652C18.2382 2.44719 18.1342 2.41434 18.0273 2.41176H2.97266Z"
                                    stroke-width="0.2" />
                                </svg>

                              </template>
                              <div class="filter-section-items">
                                <p class="font-weight-bold mb-2 mt-2 heading">Filter</p>
                                <b-form-group label="Type" class="text-left select_arrow label w-equipment-box mb-3">
                                  <b-form-select v-model="filterFields.type" class="f-12 bg-white" @change="getAllDocs()"
                                    :options="typeOptions" />
                                </b-form-group>
                                <b-form-group label="Doc Type"
                                  class="text-left select_arrow label w-equipment-box bg-white">
                                  <b-form-select v-model="filterFields.doc_type" class="f-12 bg-white"
                                    @change="getAllDocs()" :options="docTypeOptions" />
                                </b-form-group>
                                <b-form-group label="Days" class="text-left select_arrow label w-equipment-box bg-white">
                                  <b-form-select v-model="filterFields.expiry_days" class="f-12 bg-white"
                                    @change="getAllDocs()" :options="[
                                      {
                                        value: '',
                                        text: '',
                                      },
                                      {
                                        value: '0-15',
                                        text: '0-15',
                                      },
                                      {
                                        value: '0-31',
                                        text: '0-31',
                                      },
                                    ]" />
                                </b-form-group>
                                <b-form-group v-if="reviewDocs" label="Uploaded by Driver"
                                  class="text-left select_arrow label w-equipment-box bg-white">
                                  <b-form-select v-model="filterFields.uploaded_by_driver" class="f-12 bg-white"
                                    @change="getAllDocs()" :options="[
                                      {
                                        value: '',
                                        text: '',
                                      },
                                      {
                                        value: true,
                                        text: 'Yes',
                                      },
                                      {
                                        value: false,
                                        text: 'No',
                                      },
                                    ]" />
                                </b-form-group>
                                <b-form-group v-if="reviewDocs" label="Rejected Docs"
                                  class="text-left select_arrow label w-equipment-box bg-white">
                                  <b-form-select v-model="filterFields.rejected_documents" class="f-12 bg-white"
                                    @change="getAllDocs()" :options="[
                                      {
                                        value: '',
                                        text: '',
                                      },
                                      {
                                        value: true,
                                        text: 'Yes',
                                      },
                                      {
                                        value: false,
                                        text: 'No',
                                      },
                                    ]" />
                                </b-form-group>
                              </div>
                              <div class="d-flex align-items-center justify-content-between buttons-section">
                                <b-button variant="primary" @click="resetFilterFields()">Reset</b-button>
                                <b-button class="text-dark btn-secondary-2"
                                  @click="$refs.filterDropdown.hide()">Close</b-button>
                              </div>
                            </b-dropdown>
                          </div>
                          <div class="table-columns-section ml-2 mt-0" @click="showColumns = true">
                            <button class="btn btn-table-columns-section">
                              <svg width="18" height="20" class="dots" viewBox="0 0 18 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <mask id="path-1-inside-1_4223_1514" fill="white">
                                  <rect width="4" height="20" rx="1" />
                                </mask>
                                <rect width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                                  mask="url(#path-1-inside-1_4223_1514)" />
                                <mask id="path-2-inside-2_4223_1514" fill="white">
                                  <rect x="7" width="4" height="20" rx="1" />
                                </mask>
                                <rect x="7" width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                                  mask="url(#path-2-inside-2_4223_1514)" />
                                <mask id="path-3-inside-3_4223_1514" fill="white">
                                  <rect x="14" width="4" height="20" rx="1" />
                                </mask>
                                <rect x="14" width="4" height="20" rx="1" stroke="#2D69F6" stroke-width="3"
                                  mask="url(#path-3-inside-3_4223_1514)" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <b-table ref="tableRef" class="mt-2" responsive :fields="visibleFields" :items="items" hover
                      :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                      :no-local-sorting="true" :busy.sync="isBusy" selectable select-mode="multi" no-select-on-click
                      @row-selected="onRowSelected" :show-empty="emptyTable">
                      <template #head(selected)="">
                        <b-form-checkbox class="pl-0 w-fit-content" @change="selectRows($event)"
                          v-model="allRowsSelected">
                        </b-form-checkbox>
                      </template>
                      <template #cell(name)="data">
                        <div class="d-flex align-items-center">
                          <a>{{ data.value }}</a>
                        </div>
                      </template>
                      <template #cell(source)="data">
                        <svg v-if="data.value === 'BOT'" width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.07751 18.9975V15.5368H6.53822" stroke="#2D69F6" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M18.8442 8.34855C19.7053 12.9277 16.9031 17.4673 12.328 18.6933C8.82635 19.6315 5.25353 18.3694 3.07751 15.7545"
                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M1.15502 11.6536C0.293936 7.07442 3.09617 2.53472 7.67127 1.30883C11.1727 0.370634 14.7452 1.63251 16.9213 4.24705"
                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M16.9219 1V4.46071H13.4612" stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M5.93818 10.4693C5.4485 10.3842 5.4485 9.6812 5.93818 9.59601C7.71219 9.28739 9.12316 7.93616 9.50822 6.17717L9.53773 6.04233C9.64368 5.55838 10.3328 5.55536 10.4429 6.03837L10.4788 6.1955C10.8781 7.94621 12.2894 9.28601 14.0585 9.59378C14.5506 9.67942 14.5506 10.3859 14.0585 10.4716C12.2894 10.7793 10.8781 12.1192 10.4788 13.8698L10.4429 14.027C10.3328 14.51 9.64368 14.507 9.53773 14.023L9.50822 13.8882C9.12316 12.1292 7.71219 10.778 5.93818 10.4693Z"
                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg v-else-if="data.value === 'Driver' && !data.item.rejected" width="20" height="20"
                          viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                            stroke="#FFC800" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0004 11.3825C11.9122 11.3825 13.462 9.83266 13.462 7.92091C13.462 6.00916 11.9122 4.45937 10.0004 4.45937C8.08867 4.45937 6.53888 6.00916 6.53888 7.92091C6.53888 9.83266 8.08867 11.3825 10.0004 11.3825Z"
                            stroke="#FFC800" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M4.08673 16.7876C4.7046 15.7734 5.57299 14.9352 6.60841 14.3536C7.64382 13.7719 8.81144 13.4664 9.99904 13.4664C11.1866 13.4664 12.3543 13.7719 13.3897 14.3536C14.4251 14.9352 15.2934 15.7734 15.9114 16.7876"
                            stroke="#FFC800" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg v-else-if="data.value === 'Driver' && data.item.rejected" width="20" height="20"
                          viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.001 11.3838C11.9127 11.3838 13.4625 9.83401 13.4625 7.92226C13.4625 6.01051 11.9127 4.46072 10.001 4.46072C8.08921 4.46072 6.53943 6.01051 6.53943 7.92226C6.53943 9.83401 8.08921 11.3838 10.001 11.3838Z"
                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M4.08588 16.7859C4.70375 15.7717 5.57214 14.9334 6.60755 14.3519C7.64297 13.7702 8.81059 13.4647 9.99818 13.4647C11.1858 13.4647 12.3534 13.7702 13.3888 14.3519C14.4243 14.9334 15.2926 15.7717 15.9105 16.7859"
                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        <div v-else>{{ data.value }}</div>

                      </template>
                      <template #cell(type)="data">
                        {{ data.value ? data.value : '-' }}
                      </template>
                      <template #cell(selected)="data">
                        <b-form-checkbox class="pl-0 w-fit-content" @change="checked(data.index, data.rowSelected)"
                          v-model="data.rowSelected">
                        </b-form-checkbox>
                      </template>
                      <template #cell(description)="data">
                        <v-app v-if="data.value && data.value !== 'null'">
                          <v-tooltip top>
                            <template #activator="{ on, attrs }">
                              <span class="w-fit-content item-description text-left" v-on="on" v-bind="attrs">{{
                                data.value
                              }}</span>
                            </template>{{ data.value }}
                          </v-tooltip>
                        </v-app>
                        <p v-else>-</p>
                      </template>

                      <template #cell(public)="data">
                        <b-form-checkbox :disabled="!allowUpdateDocEquipment" v-if="data.item.type === 'Trailer' ||
                          data.item.type === 'Vehicle'
                          " v-model="data.value" name="check-button" switch @change="
    updateDocumentOnToggle(
      data.item,
      'public',
      data.value
    )
    " size="lg" class="ml-2" />
                      </template>
                      <template #cell(dot_mode)="data">
                        <b-form-checkbox :disabled="!checkUpdateStatus(data.item.type)" v-model="data.value"
                          name="check-button" switch @change="
                            updateDocumentOnToggle(
                              data.item,
                              'dot_mode',
                              data.value
                            )
                            " size="lg" class="ml-2" />
                      </template>
                      <template #cell(expiry_date)="data">
                        <span v-html="data.value"></span>
                      </template>
                      <template #cell(expiry_days)="data">
                        <span v-if="data.item.expiry_date !== '-'" :style="data.item.color">{{ data.value }} Days</span>
                        <span v-else :style="data.item.color">-</span>
                      </template>
                      <template #cell(driver)="data">
                        <b-form-checkbox v-model="data.value" :disabled="!checkUpdateStatus(data.item.type)"
                          name="check-button" switch @change="
                            updateDocumentOnToggle(
                              data.item,
                              'driver',
                              data.value
                            )
                            " size="lg" class="ml-2" />
                      </template>
                      <template #cell(action)="data">
                        <svg @click="openDoc(data.item)" class="cursor-pointer mr-4" width="20" height="15"
                          viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M18.6426 6.19707C18.8726 6.48384 19 6.85642 19 7.24273C19 7.62904 18.8726 8.00161 18.6426 8.28838C17.186 10.052 13.8704 13.4854 10 13.4854C6.12957 13.4854 2.81403 10.052 1.35741 8.28838C1.12735 8.00161 1 7.62904 1 7.24273C1 6.85642 1.12735 6.48384 1.35741 6.19707C2.81403 4.4335 6.12957 1 10 1C13.8704 1 17.186 4.4335 18.6426 6.19707Z"
                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0007 10.0154C11.533 10.0154 12.7752 8.77323 12.7752 7.24091C12.7752 5.70859 11.533 4.4664 10.0007 4.4664C8.46839 4.4664 7.2262 5.70859 7.2262 7.24091C7.2262 8.77323 8.46839 10.0154 10.0007 10.0154Z"
                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg @click="downloadDoc(data.item, data.item.pk)" class="mr-4 cursor-pointer" width="20"
                          height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.53943 11.3822L10.001 14.8437L13.4625 11.3822" stroke="#FFC800" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M10.0001 14.8497V5.15741" stroke="#FFC800" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                            stroke="#FFC800" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <!-- <svg @click="editDocument(data.item.id)"
                          v-if="($store.state.user.user.team_member.role === 'Admin' ||
                            $store.state.user.user.team_member.role === 'Operations' || ((data.item.type === 'Vehicle' || data.item.type === 'Trailer') && $store.state.user.user.team_member.role === 'Maintenance Manager')) && !reviewDocs"
                          class="mr-4 cursor-pointer" width="21" height="21" viewBox="0 0 21 21" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                            stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> -->
                        <svg @click="showPopup(data.item.pk)" class="cursor-pointer"
                          v-if="checkDeleteDocsStatus(data.item)" width="19" height="20" viewBox="0 0 19 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>

                      </template>
                    </b-table>
                    <div class="entries-pagination px-3 px-sm-4">
                      <div class="d-flex align-items-center justify-content-between flex-wrap">
                        <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                          <b-form-select v-model="perPage" :options="pageOptions" />
                        </div>

                        <div class="pagination">
                          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                        </div>
                        <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">
                          {{ positionText }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <FileDrop @refreshService="getAllDocs()" ref="fileDropComponent" />
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <DeleteModal v-if="showDocumentModal" @confirm="deleteDocumentItem($event)" />
    <DeleteModal v-if="deleteMultipleDocumentModal" @confirm="deleteDocumentItems($event)"
      :message="`Do you want to delete ${selectedRows.length} Items`" />
    <b-modal id="modal-document" centered no-close-on-backdrop no-close-on-esc size="lg"
      :body-class="'px-3 pt-2 overflow-preview-auto'" :footer-class="'d-block border-0 pt-0 pb-3 m-0'"
      :header-class="'align-items-center'" scrollable>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Document
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
        <b-dropdown id="dropdown-preview" class="load-dropdown mt-0" v-if="editDocumentFlag">
          <template slot="button-content">
            <b-icon class="dots" icon="three-dots"></b-icon>
          </template>
          <b-dropdown-item-button
            :class="(fileName && (!editDocumentFlag) || (editDocumentFlag && checkUpdateDocsStatus())) || checkDeleteDocsStatus(documentData) ? 'mb-2' : ''"
            @click="downloadDoc(documentData, documentData.id)">
            <p class="text_secondary cursor-pointer mb-0 f-14">Download</p>
          </b-dropdown-item-button>

          <b-dropdown-item-button @click="removeFile()" :class="checkDeleteDocsStatus(documentData) ? 'mb-2' : ''"
            v-if="fileName && !editDocumentFlag || (editDocumentFlag && checkUpdateDocsStatus())">

            <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="showPopup(documentData.id)" v-if="checkDeleteDocsStatus(documentData)">
            <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
          </b-dropdown-item-button>
        </b-dropdown>
        <b-dropdown id="dropdown-preview" text="text" class="load-dropdown mt-0" v-if="!editDocumentFlag && fileName">
          <template slot="button-content">
            <b-icon class="dots" icon="three-dots"></b-icon>
          </template>
          <b-dropdown-item-button @click="removeFile()">
            <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
          </b-dropdown-item-button>
        </b-dropdown>
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col lg="4" md="8" sm="12">
            <div class="text-left mb-3">
              <label class="state">Name:</label>
              <v-select class="bg_white w-100 f-12" v-model="documentData.name" :options="nameOptions" item-value="id"
                item-text="label" :reduce="(option) => option.id" @option:selected="getNameItem($event)"
                @search="searchAssets" :disabled="editDocumentFlag && !checkUpdateDocsStatus()">
                <span slot="no-options">
                  Search Unit No or Name...
                </span>
              </v-select>
              <p v-if="errors && errors.name" class="field-error">
                {{ errors.name[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Date:</label>
                <date-picker v-model="documentData.date" :formatter="momentFormat" :range-separator="' - '"
                @clear="documentData.date = ''" placeholder="Select Date"
                class="date-picker-custom"
                :teleport="'body'"
                :disabled="editDocumentFlag && !checkUpdateDocsStatus()"
                ></date-picker>
              <p v-if="errors && errors.date" class="field-error">
                {{ errors.date[0] }}
              </p>
            </div>

            <div class="text-left mb-3">
              <label for="modal-expiry-date" class="state">Expiry Date:</label>
              <date-picker v-model="documentData.expiry_date" :formatter="momentFormat" :range-separator="' - '"
                @clear="documentData.expiry_date = ''" placeholder="Select Date"
                class="date-picker-custom"
                :disabled="editDocumentFlag && !checkUpdateDocsStatus()"
                :teleport="'body'"
                ></date-picker>
            </div>
            <div class="text-left mb-3" v-if="showDivision">
              <label class="state">Division:</label>
              <v-select class="f-12" :clearable="false" :options="allDivisons" v-model="documentData.division"
                :disabled="editDocumentFlag && !checkUpdateDocsStatus()" :reduce="(option) => option.id" />
            </div>
            <div class="text-left mb-3">
              <label class="state">Type:</label>
              <b-form-select class="f-12" :options="getDotOptions" v-model="documentData.doc_type"
                :disabled="editDocumentFlag && !checkUpdateDocsStatus()" @change="setDocDescription($event)">
              </b-form-select>
              <p v-if="errors && errors.doc_type" class="field-error">
                {{ errors.doc_type[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Description:</label>
              <b-form-textarea id="textarea" placeholder="Enter Description" rows="3" v-model="documentData.description"
                :disabled="editDocumentFlag && !checkUpdateDocsStatus()"></b-form-textarea>
              <p v-if="errors && errors.description" class="field-error">
                {{ errors.description[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Driver:</label>
              <b-form-checkbox class="ml-0-6rem" :disabled="editDocumentFlag && !checkUpdateDocsStatus()"
                v-model="documentData.driver" name="driver-button" switch size="lg" />
            </div>
          </b-col>
          <b-col lg="8" sm="12">
            <div class="upload-btn-wrapper" v-if="!fileName">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button v-if="!fileName" class="foo">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ fileName }}</button>

              <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                accept=".pdf, .png, .jpg, .jpeg" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document[0] }}
              </p>
            </div>
            <div v-else>
              <div class="review-media-section" :class="mediaType === 'pdf' ? 'overflow-hidden' : ''">
                <iframe class="w-100 h-100 border-0" v-if="mediaType === 'pdf'" :src="media"></iframe>
                <div v-else>
                  <div id="panzoom-element" ref="panzoomElement">
                    <img :src="media" class="w-100 h-100 img-section" />
                  </div>
                  <div class="d-flex align-items-center justify-content-center zoom-section">
                    <b-icon @click="zoom(1)" icon="zoom-in" style="fill:white; cursor: pointer;"></b-icon>
                    <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out" style="fill:white;cursor: pointer;"></b-icon>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="">
      <div class="text-right modal-footer border-0 p-0 mt-4">
        <b-button class="mx-0 h-40px w-100px m-0" @click="$bvModal.hide('modal-document'); panzoom = null;"
          variant="secondary"
          :class="hasChanged && (!editDocumentFlag || (editDocumentFlag && checkUpdateDocsStatus())) ? 'mr-3' : ''">{{
            editDocumentFlag ? 'Close' : 'Cancel' }}</b-button>
        <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="sendDocumentData"
          v-if="hasChanged && (!editDocumentFlag || (editDocumentFlag && checkUpdateDocsStatus()))">Save</b-button>
      </div>
      </template>
    </b-modal>
    <!-- Import CSV Modal  -->
    <b-modal id="modal-bulk-upload" centered hide-footer @shown="resetField" no-close-on-backdrop :body-class="'p-4'"
      no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Smart Drop
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label class="state">Model:</label>
              <b-form-select type="text" v-model="bulkUploadDocumentData.model" class="input f-12" :options="[
                {
                  value: '',
                  text: '',
                },
                {
                  value: 'Vehicle',
                  text: 'Vehicle',
                },
                {
                  value: 'Trailer',
                  text: 'Trailer',
                }
              ]"></b-form-select>
              <p v-if="bulkFileUploadErrors && bulkFileUploadErrors.model" class="field-error">
                {{ bulkFileUploadErrors.model[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Type:</label>
              <b-form-select type="text" v-model="bulkUploadDocumentData.doc_type" class="input f-12"
                :options="getBulkDotOptions"></b-form-select>
              <p v-if="bulkFileUploadErrors && bulkFileUploadErrors.doc_type" class="field-error">
                {{ bulkFileUploadErrors.doc_type[0] }}
              </p>
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="upload-btn-wrapper">
              <b-img class="img" :src="require('@/assets/drop.svg')"></b-img>
              <button class="foo-2 w-100" v-if="!bulkFileUploadFileName">
                Drop your document here, or browse
                <br />
                <br />
                <span>Only Zip File of equipment documents is allowed.</span>
              </button>
              <button v-else class="foo-2">{{ bulkFileUploadFileName }}</button>
              <input ref="bulkFile" type="file" name="bulkFile" @change="handleBulkFileUpload"
                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" />
              <p v-if="bulkFileUploadErrors && bulkFileUploadErrors.document" class="field-error">
                {{ bulkFileUploadErrors.document[0] }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0 mt-3">
        <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetBulkUploadForm" variant="secondary">Cancel</b-button>
        <b-button class="mx-0 mb-0 h-40px w-100px mt-0" variant="primary" @click="bulkUploadEquipment">Save</b-button>
      </div>
    </b-modal>
    <SmartDropPlus />
    <SmartDropPreview :documentItem="selectedReviewDoc" @docsUpdated="getAllDocs()" @close="selectedReviewDoc = null"
      :company="company" :showDeletePopup="showPopup" ref="smartDropPreview" />
    <DriverAndPublicShare :title="sharePopupTitle" @updateDocs="getAllDocs()" v-if="selectedRows.length"
      :docIds="selectedRows.map((data) => data.pk)"
      @close="$refs.tableRef.clearSelected(); selectedRows = []; allRowsSelected = false" />
    <BulkDocTypeUpdate @close="$refs.tableRef.clearSelected(); selectedRows = []; allRowsSelected = false"
      :docIds="selectedRows.map((data) => data.pk)" v-if="selectedRows.length" @updateDocs="getAllDocs()"
      :modelType="selectedRows[0].type" />
    <BulkDotModeUpdate @close="$refs.tableRef.clearSelected(); selectedRows = []; allRowsSelected = false"
      :docIds="selectedRows.map((data) => data.pk)" v-if="selectedRows.length" @updateDocs="getAllDocs()" />
    <TableColumns :showColumns="showColumns" :fields="fields" @getUpdatedFieldsList="getUpdatedFieldsList($event)"
      @updateFields="updateFields($event)" @resetTableFields="resetTableFields()" @resetData="showColumns = false" />
    <BulkExpiryDateUpdate @close="$refs.tableRef.clearSelected(); selectedRows = []; allRowsSelected = false"
      :docIds="selectedRows.map((data) => data.pk)" v-if="selectedRows.length" @updateDocs="getAllDocs()" />
  </div>
</template>
<script>
import * as moment from "moment-timezone";
import DeleteModal from "@/components/DeleteModal.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import SmartDropPlus from "@/components/Documents/SmartDropPlus.vue";
import SmartDropPreview from "@/components/Documents/SmartDropPreview.vue";
import DriverAndPublicShare from "@/components/Documents/DriverAndPublicShare.vue";
import BulkDocTypeUpdate from "@/components/Documents/BulkDocTypeUpdate.vue";
import BulkDotModeUpdate from "@/components/Documents/BulkDotModeUpdate.vue";
import BulkExpiryDateUpdate from "@/components/Documents/BulkExpiryDateUpdate.vue";
import FileDrop from "@/components/Documents/FileDrop.vue";
import TableColumns from "@/components/Shared/TableColumns.vue";
import { checkValueExists } from "../../services/helper";
import JSZip from "jszip"
import Panzoom from '@panzoom/panzoom'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: "Documents",
  components: { FileDrop, DeleteModal, vSelect, SmartDropPlus, SmartDropPreview, DriverAndPublicShare, BulkDocTypeUpdate, BulkDotModeUpdate, TableColumns, BulkExpiryDateUpdate, DatePicker },
  data() {
    return {
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('MMM D, YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'MMM D, YYYY').toDate() : null
        }
      },
      showColumns: false,
      checkValueExists,
      searchName: null,
      userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      searchType: null,
      showFileDrop: false,
      panzoom: null,
      currentPage: 1,
      selectedReviewDoc: null,
      sortBy: undefined,
      isTableMounted:false,
      defaultData: {},
      showDocumentProgess: null,
      sortDesc: undefined,
      showDocumentUploadStatus: null,
      emptyTable: false,
      reviewDocs: false,
      isBusy: false,
      rows: 1,
      media: null,
      mediaType: null,
      mediaID: null,
      docName: "",
      positionText: "",
      sharePopupTitle: "Driver Share",
      allRowsSelected: false,
      searchDescription: null,
      showDocumentModal: false,
      deleteMultipleDocumentModal: false,
      itemId: null,
      searchDays: null,
      fileName: null,
      bulkFileUploadFileName: '',
      editDocumentFlag: false,
      items: [],
      showDivision: false,
      modelType: null,
      reviewDocsCount: 0,
      fileDropCount: 0,
      allCount: 0,
      perPage: 25,
      selectedRows: [],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      fields: [],
      filterFields: {
        search_field: '',
        type: '',
        doc_type: '',
        expiry_days: '',
        rejected_documents: '',
        uploaded_by_driver: ''
      },
      documentData: {
        name: null,
        object_id: null,
        date: new Date(),
        type: null,
        document: null,
        description: null,
        expiry_date: "",
        driver: true,
        public: false,
        doc_type: "",
        division: null
      },
      bulkUploadDocumentData: {
        document: null,
        model: "",
        doc_type: ""
      },
      errors: {
        date: null,
        name: null,
        document: null,
        description: null,
      },
      bulkFileUploadErrors: {
        document: null,
        model: null,
        doc_type: null
      },
      nameOptions: [],
    };
  },
  computed: {
    ...mapGetters(["allCompanyDocs", "company", "allDrivers", "allEquipments", "userCompany", "docTypeItems", 'defaultDocType', 'divisions', 'rolePermissions', 'userPreferences', 'user']),
    showReviewSection() {
      return this.rolePermissions && this.rolePermissions['Document'] && this.rolePermissions['Document'][0].allow
    },
    allowUpdateDocDrivers() {
      return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][8].allow
    },
    allowUpdateDocEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][7].allow
    },
    allowUpdateDocCompany() {
      return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][1].allow
    },
    allowCreateDocDrivers() {
      return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][9].allow
    },
    allowCreateDocEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][8].allow
    },
    allowCreateDocCompany() {
      return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][2].allow
    },
    showFileDropSection() {
      return this.rolePermissions && this.rolePermissions['Document'] && this.rolePermissions['Document'][1].allow
    },
    allowDeleteDocDrivers() {
      return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][10].allow
    },
    allowDeleteDocEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][9].allow
    },
    allowDeleteDocCompany() {
      return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][3].allow
    },
    showSmartDropAndSmartDropPlusSection() {
      return this.rolePermissions && this.rolePermissions['Document'] && this.rolePermissions['Document'][2].allow
    },
    allowViewDocDrivers() {
      return this.rolePermissions && this.rolePermissions['Drivers'] && this.rolePermissions['Drivers'][7].allow
    },
    allowViewDocEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][6].allow
    },
    allowViewDocCompany() {
      return this.rolePermissions && this.rolePermissions['Company Documents'] && this.rolePermissions['Company Documents'][0].allow
    },
    allDivisons() {
      return this.divisions.map((item) => {
        return {
          id: item.id,
          label: item.division,
        }
      })
    },
    hasChanged() {
      return Object.keys(this.documentData).filter(field => {
        if (field !== 'updated_at') {
          if (typeof this.documentData[field] === 'object') return JSON.stringify(this.documentData[field]) !== JSON.stringify(this.defaultData[field])
          if (field === 'description') {
            return this.checkValueExists(this.documentData[field]?.trim().replace(/\r\n|\r|\n/g, '\n')) != this.checkValueExists(this.defaultData[field]?.trim().replace(/\r\n|\r|\n/g, '\n'));
          }
          else if (!this.documentData[field] && !this.defaultData[field]) return false
          else return this.checkValueExists(this.documentData[field]) != this.checkValueExists(this.defaultData[field])
        } return false
      }).length > 0
    },
    docTypeOptions() {
      const items = [{
        value: "",
        text: ""
      }]
      this.docTypeItems.map((item) => {
        items.push({
          value: item.id,
          text: item.type
        }
        )
      })
      return items
    },
    getDotOptions() {
      if (!this.documentData.name) return []
      return [...[{
        value: "",
        text: "",
      }], ...this.docTypeItems?.filter((item) => item.model === this.modelType || item.model === 'All').map((item) => {
        return {
          value: item.id,
          text: item.type,
        }
      })]
    },
    getBulkDotOptions() {
      return [...[{
        value: "",
        text: "",
      }], ...this.docTypeItems?.filter((item) => item.model === this.bulkUploadDocumentData.model || item.model === 'All').map((item) => {
        return {
          value: item.id,
          text: item.type,
        }
      })]
    },
    activeFiltersCount() {
      return Object.keys(this.filterFields).reduce((count, key) => {
        if (
          this.filterFields[key] !== '' &&
          this.filterFields[key] !== null
        ) {
          return count + 1;
        }
        return count;
      }, 0);
    },
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
    typeOptions() {
      const options = [
        {
          value: "",
          text: "",
        },
      ];
      if (this.allowViewDocEquipment) {
        options.push({
          value: "Vehicle",
          text: "Vehicle",
        })
        options.push(
          {
            value: "Trailer",
            text: "Trailer",
          })
      }
      if (this.allowViewDocDrivers) {
        options.push({
          value: "Driver",
          text: "Driver",
        });
      }
      if (this.allowViewDocCompany) {
        options.push({
          value: "Company",
          text: "Company",
        });
      }
      return options;
    }
  },
  methods: {
    ...mapActions([
      "getCompanyDocs",
      "setLoader",
      "retrieveDocument",
      "deleteCompanyDocument",
      "setNotification",
      "updateTeamMemberDocument",
      "getAllDrivers",
      "getAllEquipments",
      "sendCompanyDocuments",
      "sendBulkCompanyDocuments",
      "checkBulkCompanyDocumentsUploadStatus",
      "updateBulkCompanyDocumentsUploadStatus",
      "checkSmartDropStatus",
      "bulkUpdateDocTypeItems",
      "updateUserPreferences",
      "deleteUserPreferences"
    ]),
    async resetTableFields() {
      try {
        this.setLoader(true)
        await this.deleteUserPreferences(`Document/`)
        this.showColumns = false
        this.setLoader(false)
      }
      catch (e) {
        this.setLoader(false)
        console.log(e)
      }
    },
    async getUpdatedFieldsList(updatedFields) {
      this.fields = updatedFields
      try {
        const updatedOrderList = updatedFields.map((item, index) => {
          return { ...item, display_order: index + 1 }
        })
        await this.updateUserPreferences({
          "columns": updatedOrderList
        })
      }
      catch (e) {
        console.log(e)
      }
    },
    checkDriverShare() {
      const uniqueTypes = [...new Set(this.selectedRows.map(item => item.type))];
      let updatePermission = true
      for (let type of uniqueTypes) {
        if (!this.checkUpdateStatus(type)) {
          this.setNotification({
            msg: `You don't have permission to update ${type}'s docs.`,
            type: "error",
            color: "red",
          });
          updatePermission = false
          break
        }
      }
      if (updatePermission) {
        this.sharePopupTitle = 'Driver Share';
        this.$bvModal.show('modal-driver-public-share')
      }
    },
    checkDotMode() {
      const uniqueTypes = [...new Set(this.selectedRows.map(item => item.type))];
      let updatePermission = true
      for (let type of uniqueTypes) {
        if (!this.checkUpdateStatus(type)) {
          this.setNotification({
            msg: `You don't have permission to update ${type}'s docs.`,
            type: "error",
            color: "red",
          });
          updatePermission = false
          break
        }
      }
      if (updatePermission) {
        this.$bvModal.show('modal-bulk-dot-mode-update')
      }
    },
    setExpiryDate() {
      this.$bvModal.show('modal-bulk-expiry-date-update')
    },
    checkBulkDeleteDocumentsStatus() {
      const uniqueTypes = [...new Set(this.selectedRows.map((item) => {
        if (item.type === 'Vehicle' || item.type === 'Trailer') return { type: 'Equipment' }
        return { type: item.type }
      }))];
      let deletePermission = true
      for (let type of uniqueTypes) {
        if (!this.checkDeleteDocsStatus(type)) {
          this.setNotification({
            msg: `You don't have permission to delete ${type.type}'s docs.`,
            type: "error",
            color: "red",
          });
          deletePermission = false
          break
        }

      }
      if (deletePermission) {
        this.deleteMultipleDocumentModal = true
      }
    },
    checkDeleteDocsStatus(data) {
      if (data.type === 'Driver' && this.allowDeleteDocDrivers) return true
      else if (data.type === 'Company' && this.allowDeleteDocCompany) return true
      else if ((data.type === 'Equipment' || data.type === 'Vehicle' || data.type === 'Trailer') && this.allowDeleteDocEquipment) return true
      else if (!data.type && this.allowDeleteDocEquipment) return true
      else return false
    },
    checkUpdateDocsStatus() {
      if (this.documentData) return this.checkUpdateStatus(this.documentData.type)
      return false
    },
    checkUpdateStatus(type) {
      if (type === 'Driver' && this.allowUpdateDocDrivers) return true
      else if (type === 'Company' && this.allowUpdateDocCompany) return true
      else if ((type === 'Equipment' || type === 'Vehicle' || type === 'Trailer') && this.allowUpdateDocEquipment) return true
      else return false
    },
    async setFileDropSection() {
      this.reviewDocs = false
      this.currentPage = 1
      this.resetFilterFields()
      this.showFileDrop = true
      await this.$nextTick()
      this.$refs.fileDropComponent.getFileData()
      await this.getAllDocs()
      this.items = []
    },
    zoom(level) {
      if (!this.panzoom) {
        this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
          maxScale: 5
        })
      }
      level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
    },
    getExtensionFromMimeType(mimeType) {
      const mimeMap = {
        'application/pdf': 'pdf',
        'image/jpeg': 'jpg',
        'image/png': 'png',
      };

      return mimeMap[mimeType] || '';
    },
    async downloadBulkFiles() {
      this.setLoader(true)
      const zip = new JSZip();
      const promises = this.selectedRows.map(async (doc) => {
        const { document } = await this.retrieveDocument(doc.pk);
        await fetch(document)
          .then(async (response) => await response.blob())
          .then(blob => {
            let fileName = `${doc.name}-${doc.description ? doc.description : ''}${this.reviewDocs ? `-${doc.pk}` : ''}.${this.getExtensionFromMimeType(blob.type)}`
            // const file = new File([blob], fileName, {
            //   type: blob.type
            // });
            zip.file(fileName, blob);
          })
      })
      await Promise.all(promises).then(async () => {
        const content = await zip.generateAsync({ type: "blob" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = `${this.company[0].name}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.$refs.tableRef.clearSelected()
        this.allRowsSelected = false
        this.setLoader(false)
      }).catch(() => {
        this.setLoader(false)
      })
    },
    async showPublicShare() {
      const allEquipments = this.selectedRows.every(obj => obj.type === "Trailer" || obj.type === "Vehicle");
      if (allEquipments) {
        this.sharePopupTitle = 'Public Share';
        this.$bvModal.show('modal-driver-public-share')
      }
      else {
        await this.setNotification({
          msg: 'Only Equipment Documents can be selected.',
          type: "error",
          color: "red",
        });
      }
    },
    async downloadDoc(data, id) {
      this.setLoader(true)
      const doc = await this.retrieveDocument(id);
      fetch(doc.document)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const newUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = newUrl;
          const urlWithoutParams = doc.document.split('?')[0];
          const extension = urlWithoutParams.split('.').pop();
          a.download = `${data.docName ? data.docName : data.name}-${data.description ? data.description : ''}.${extension}`
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(newUrl);
          a.remove()
          this.setLoader(false)
        })
        .catch(error => {
          this.setLoader(false)
          console.log(error)
        });
    },
    async checkDocType() {
      const allSameType = this.selectedRows.every(obj => obj.type === this.selectedRows[0].type);
      console.log(this.selectedRows[0].type)
      if (allSameType) {
        if (this.checkUpdateStatus(this.selectedRows[0].type)) {
          this.$bvModal.show('modal-bulk-doc-type')
        }
        else {
          await this.setNotification({
            msg: "You don't have permission to perform this action.",
            type: "error",
            color: "red",
          });
        }
      } else {
        await this.setNotification({
          msg: 'All selected documents need be in same model',
          type: "error",
          color: "red",
        });
      }
    },
    resetBulkUploadForm() {
      this.bulkUploadDocumentData = {
        document: null,
        model: "",
        doc_type: ""
      }
      this.bulkFileUploadErrors = {
        document: null,
        model: null,
        doc_type: null
      },
        this.$bvModal.hide('modal-bulk-upload')
    },
    setDocDescription(event) {
      if (event) {
        this.documentData.description = this.getDotOptions.filter((option) => option.value === event)[0].text
      }
      else this.documentData.description = this.defaultData.description
    },
    async checkUploadStatus(model) {
      try {
        console.log(model)
        const status = await this.checkSmartDropStatus()
        if (!status) {
          if (model === 'smartDrop') this.$bvModal.show('modal-bulk-upload')
          else this.$bvModal.show('modal-smart-drop-plus')
        }
        else {
          await this.setNotification({
            msg: `${model === 'smartDrop' ? 'Smart Drop' : 'Smart Drop +'} is in use, please try later!`,
            type: "error",
            color: "red",
          });
        }
      }
      catch (e) {
        console.log(e)
      }
    },
    async dimissUploadStatus() {
      try {
        this.setLoader(true)
        await this.updateBulkCompanyDocumentsUploadStatus({
          id: this.showDocumentUploadStatus.id,
          payload: { dismissed: true }
        })
        await this.setNotification({
          msg: "Dimissed Successfully",
          type: "success",
          color: "green",
        });
        this.showDocumentUploadStatus = null
        this.setLoader(false)
      }
      catch (e) {
        this.setLoader(false)
        console.log(e)

      }
    },
    async resetFilterFields() {
      this.filterFields = {
        search_field: '',
        type: '',
        doc_type: '',
        expiry_days: '',
        rejected_documents: '',
        uploaded_by_driver: ''
      }
      await this.getAllDocs();
    },
    async updateFields(updatedFields) {
      this.fields = updatedFields
      this.filterFields = {
        search_field: '',
        type: '',
        doc_type: '',
        expiry_days: '',
        rejected_documents: '',
        uploaded_by_driver: ''
      }
      try {
        await this.updateUserPreferences({
          "columns": updatedFields
        })
        await this.getAllDocs();
      }
      catch (e) {
        console.log(e)
      }
      await this.getAllDocs();
    },
    onRowSelected(items) {
      this.selectedRows = items
    },
    selectRows(checked) {
      if (checked) {
        this.$refs.tableRef.selectAllRows()
      }
      else {
        this.$refs.tableRef.clearSelected()
        this.allRowsSelected = false
      }
    },
    checked(index, checked) {
      let tableRef = this.$refs.tableRef
      if (checked === true) tableRef.selectRow(index)
      else tableRef.unselectRow(index)
    },
    resetField() {
      this.$refs.bulkFile.value = null;
      this.bulkFileUploadFileName = null;
      this.document = null;
      this.bulkFileUploadErrors = {
        document: null,
      };
    },
    downloadFile(uri) {
      axios({
        method: 'get',
        url: uri,
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const urlWithoutParams = uri.split('?')[0];
        const extension = urlWithoutParams.split('.').pop();
        a.download = this.docName + "." + extension
        document.body.appendChild(a);
        a.click();
        a.remove()
        window.URL.revokeObjectURL(url);
      });
    },
    handleFileUpload() {
      this.documentData.document = this.$refs.docFile.files[0];
      this.fileName = this.documentData.document.name;
      this.media = URL.createObjectURL(this.documentData.document);
      if (this.$refs.docFile.files[0].type.includes('pdf')) this.mediaType = 'pdf'
      else this.mediaType = 'image'
    },
    handleBulkFileUpload() {
      this.bulkUploadDocumentData.document = this.$refs.bulkFile.files[0];
      this.bulkFileUploadFileName = this.bulkUploadDocumentData.document.name;
    },
    async openDoc(data) {
      this.docName = `${data.name}-${data.description ? data.description : ''}`
      if (this.reviewDocs) {
        this.selectedReviewDoc = data
        this.$bvModal.show("modal-smart-drop-plus-preview");
      } else {
        this.media = data.link
        this.mediaID = data.pk
        if (data.link.includes('.pdf')) this.mediaType = 'pdf'
        else this.mediaType = 'image'
        this.editDocument(data.id, data.name)
      }
    },
    getNameItem(item) {
      this.showDivision = item.type === 'company' ? true : false
      this.modelType = item.model
      if (this.showDivision) this.documentData.division = this.divisions.filter((division) => division.default)[0]?.id
    },
    async searchAssets(searchText) {
      if (searchText) {
        let options = []
        let equipmentRecords
        let url
        if ((this.allowUpdateDocEquipment && this.editDocumentFlag) || this.allowCreateDocEquipment) {
          url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
          equipmentRecords = await this.getAllEquipments(url);
        }
        if (((this.allowUpdateDocDrivers && this.editDocumentFlag) || this.allowCreateDocDrivers) && ((((!this.allowUpdateDocEquipment && this.editDocumentFlag) && !this.allowCreateDocEquipment)) || (equipmentRecords && equipmentRecords.records.length === 0))) {
          url = `name=${searchText}&sort_field=name&sort_order=asc&&page=1&page_size=10`
          const driverRecords = await this.getAllDrivers(url);
          if (driverRecords.records?.length) {
            driverRecords.records?.map((item) => {
              options.push({
                id: `Driver-${item.id}`,
                label: item.name,
                type: "teammember",
                name: `${item.user.first_name}_${item.user.last_name}_`,
                model: "Driver"
              });
            });
          }
        }
        else {
          if (equipmentRecords) {
            equipmentRecords.records.map((item) => {
              options.push({
                id: `${item.type}-${item.id}`,
                label: item.unit_no,
                type: "equipment",
                name: `${item.unit_no}_`,
                model: item.type
              });
            });
          }
        }
        if (this.allowUpdateDocCompany) {
          this.nameOptions = this.nameOptions.concat(options).concat([{
            id: `Company-${this.userCompany}`,
            label: "Company",
            type: "company",
            name: this.company[0].name,
            model: "Company"
          }])
        }
        else this.nameOptions = options
        this.nameOptions = [...new Map(this.nameOptions.map(item => [item['id'], item])).values()]
      }
    },
    async bulkUploadEquipment() {
      try {
        const nullKeys = this.checkNullValues({
          document: this.bulkUploadDocumentData.document,
          ...this.company[0].doc_type ? { doc_type: this.bulkUploadDocumentData.doc_type, model: this.bulkUploadDocumentData.model } : {}
        });
        if (nullKeys.length === 0) {
          this.setLoader(true);
          let formData = new FormData();
          formData.append("zip_file", this.bulkUploadDocumentData.document)
          formData.append("company_id", this.userCompany)
          if (this.bulkUploadDocumentData.doc_type) formData.append("doc_type", this.bulkUploadDocumentData.doc_type)
          const status = await this.sendBulkCompanyDocuments(formData);
          if (status === 'Another task is already in progress.') {
            await this.setNotification({
              msg: status,
              type: "error",
              color: "red",
            });
          }
          else {
            if (status === 'In Progress') this.showDocumentProgess = true
            this.getAllDocs();
            await this.setNotification({
              msg: "File Successfully Added. Documents will be live shortly!!",
              type: "success",
              color: "green",
            });
          }
          this.$bvModal.hide('modal-bulk-upload')
          this.setLoader(false);
          this.bulkUploadDocumentData = {
            document: null,
            model: "",
            doc_type: ""
          }
        }
        else {
          let error = {};
          nullKeys.forEach((x) => {
            error[x] = ["This field is required."];
          });
          console.log(nullKeys)
          throw error;
        }
      }
      catch (error) {
        this.setLoader(false);
        if (error?.data?.error) {
          await this.setNotification({
            msg: error?.data?.error,
            type: "error",
            color: "red",
          });
        }
        if (typeof error === "object") {
          this.bulkFileUploadErrors = error;
          // eslint-disable-next-line no-ex-assign

        }
      }

    },
    checkNullValues(payload) {
      let nullKeys = [];
      for (let [key, value] of Object.entries(payload)) {
        if (!value) {
          nullKeys.push(key);
        }
      }
      return nullKeys;
    },
    extractFilePath(url) {
      const pathname = new URL(url).pathname;

      return pathname.substring(pathname.indexOf("documents/"));
    },
    async sendDocumentData() {
      try {
        const nullKeys = this.checkNullValues({
          name: this.documentData.name,
          date: this.documentData.date,
          document: this.documentData.document,
          ...this.company[0].doc_type  ? { doc_type: this.documentData.doc_type } : {}
        });
        if (nullKeys.length === 0) {
          this.setLoader(true);
          let formData = new FormData();
          let fileType = this.documentData.document instanceof File || this.documentData.document instanceof Blob;
          if (!fileType) this.documentData.document = null
          formData.append("object_id", this.documentData.name.split("-")[1]);
          formData.append(
            "date",
            moment(this.documentData.date).format("YYYY-MM-DD")
          );
          formData.append("description", this.documentData.description);
          if (this.documentData.doc_type) formData.append("doc_type", this.documentData.doc_type);
          formData.append("type", this.documentData.type);
          formData.append("expiry_date", this.documentData.expiry_date ? moment(this.documentData.expiry_date).format("YYYY-MM-DD") : '');
          if (this.documentData.document)
            formData.append(
              "document",
              new File(
                [this.documentData.document],
                this.nameOptions.filter(
                  (item) => item.id === this.documentData.name
                )[0].name + "_"+
                this.documentData.description +
                "_" + this.userCompany + "_" +
                new Date().getTime() +
                "." +
                this.documentData.document["type"].split("/").pop(),
                { type: this.documentData.document["type"] }
              )
            );
          formData.append("driver", this.documentData.driver);
          formData.append("public", this.documentData.public);
          formData.append(
            "model_type",
            this.nameOptions.filter(
              (item) => item.id === this.documentData.name
            )[0].type
          );
          if (this.nameOptions.filter(
            (item) => item.id === this.documentData.name
          )[0].type === 'company') formData.append("division", this.documentData.division);
          formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
          if (this.editDocumentFlag) {
            this.documentData = await this.updateTeamMemberDocument({
              id: this.documentData.id,
              payload: formData,
            });
            await this.setNotification({
              msg: "Document Successfully updated!!",
              type: "success",
              color: "green",
            });
            this.$bvModal.hide("modal-document");
            let color;
            let expiryColor;
            let daysDiff = "-";
            if (this.documentData.expiry_date) {
              const currentDate = new Date();
              const expiryDate = new Date(this.documentData.expiry_date);
              const diffDays = parseInt(
                (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                10
              );
              if (diffDays > 31) {
                color = "color:black";
              } else if (diffDays <= 31 && diffDays >= 1) {
                color = "color:#FFC800";
              } else color = "color:#FF0000";
              daysDiff = parseInt(
                (new Date(this.documentData.expiry_date) - new Date()) /
                (1000 * 60 * 60 * 24),
                10
              );
              if (daysDiff <= 0) {
                expiryColor = "color:red";
              } else if (daysDiff <= 30 && daysDiff >= 1) {
                expiryColor = "color:#FFC800";
              } else {
                expiryColor = "color:green";
              }
              this.allCompanyDocs[this.documentIndex] = this.documentData;
              this.allCompanyDocs[this.documentIndex] = { ...this.documentData, doc_type: this.documentData.doc_type, document: this.extractFilePath(this.documentData.document) };
              this.items[this.documentIndex].Date = moment(
                this.documentData.date
              ).format("MMM DD, YYYY");
              this.items[this.documentIndex].type = this.documentData.type;
              this.items[this.documentIndex].Description =
                this.documentData.description;
              this.items[this.documentIndex].expiry_date = this.documentData
                .expiry_date
                ? `<span style=${color}>${moment(
                  this.documentData.expiry_date
                ).format("MMM DD, YYYY")}</span`
                : "-";
              this.items[this.documentIndex].Driver = this.documentData.driver;
              this.items[this.documentIndex].public = this.documentData.public;
              this.items[this.documentIndex].Days = daysDiff;
              this.items[this.documentIndex].color = expiryColor;
              this.editDocumentFlag = false;
            }
          } else {
            await this.sendCompanyDocuments(formData);
            this.$bvModal.hide("modal-document");
            await this.setNotification({
              msg: "Document Successfully added!!",
              type: "success",
              color: "green",
            });
          }
          this.getAllDocs();
          this.documentData = {
            object_id: null,
            date: null,
            name: null,
            type: null,
            document: null,
            description: null,
            expiry_date: "",
            driver: true,
            public: false,
            doc_type: "",
            division: null
          };
          this.fileName = null;
          this.nameOptions = []
          this.setLoader(false);
        }
        else {
          let error = {};
          nullKeys.forEach((x) => {
            error[x] = ["This field is required."];
          });
          throw error;
        }
      } catch (error) {
        console.log(error)
        this.setLoader(false);
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }

    },
    removeFile() {
      this.fileName = null;
      this.documentData.document = null
      this.media = null
      this.mediaType = null
    },
    addDocument() {
      this.$bvModal.show("modal-document");
      this.nameOptions = []
      this.defaultData = {}
      this.fileName = null;
      this.showDivision = false
      this.editDocumentFlag = false;
      this.documentData = {
        name: null,
        object_id: null,
        date: new Date(),
        type: null,
        document: null,
        description: null,
        expiry_date: "",
        driver: true,
        public: false,
        doc_type: "",
        division: null
      };
      this.errors = {
        date: null,
        name: null,
        document: null,
        description: null,
        ...this.company[0].doc_type ? { doc_type: null } : {}
      };
    },
    async openLink(id) {
      const doc = await this.retrieveDocument(id);
      window.open(doc.document, "_blank");
    },
    async deleteDocumentItem(item) {
      if (item) {
        try {
          this.setLoader(true)
          await this.deleteDocument(this.itemId);
          if (this.selectedReviewDoc) {
            this.$refs.smartDropPreview.resetPopup()
            this.selectedReviewDoc = null
          }
          this.setLoader(false)
        }
        catch (e) {
          this.setLoader(false)
        }
      }
      this.showDocumentModal = false;
    },
    async approveDocs() {
      let acceptedDocs = 0
      this.selectedRows.map(async (item) => {
        if (item.name !== '-') acceptedDocs++
      })
      if (acceptedDocs !== this.selectRows.length) {
        await this.setNotification({
          msg: "All documents require name.",
          type: "error",
          color: "red",
        });
        return
      }
      this.setLoader(true)
      await Promise.all(
        this.selectedRows.map(async (data) => {
          await this.updateTeamMemberDocument({
            id: data.pk,
            payload: { ...data, ...data.source === 'BOT' ? { data_extraction_successful: true } : { rejected: false }, date: moment(data.date).format("YYYY-MM-DD"), expiry_date: data.expiry ? moment(data.expiry).format("YYYY-MM-DD") : null, object_id: data.object_id },
          });
        })
      ).then(async () => {
        this.setLoader(false)
        await this.setNotification({
          msg: "Document successfully updated!!",
          type: "success",
          color: "green",
        });
        await this.getAllDocs();
        this.$refs.tableRef.clearSelected()
        this.allRowsSelected = false
      }).catch(async (e) => {
        this.setLoader(false)
        await this.setNotification({
          msg: e,
          type: "error",
          color: "red",
        });
      })
    },
    async deleteDocumentItems(item) {
      if (item) {
        this.setLoader(true)
        await Promise.all(
          this.selectedRows.map(async (item) => {
            await this.deleteDocument(item.pk, false);
          })
        ).then(async () => {
          this.setLoader(false)
          await this.getAllDocs();
          this.$refs.tableRef.clearSelected()
          this.allRowsSelected = false
          this.deleteMultipleDocumentModal = false;
        }).catch(async (e) => {
          this.setLoader(false)
          await this.setNotification({
            msg: e,
            type: "error",
            color: "red",
          });
        })
      }
      else {
        this.deleteMultipleDocumentModal = false
        this.$refs.tableRef.clearSelected();
        this.allRowsSelected = false
        this.selectedRows = []
      }
    },
    showPopup(id) {
      this.showDocumentModal = true;
      this.itemId = id;
    },
    async deleteDocument(id, refreshDocs = true) {
      try {
        await this.deleteCompanyDocument(`${id}/?company_id=${this.userCompany}`);
        if (refreshDocs) await this.getAllDocs();
        await this.setNotification({
          msg: "Document successfully deleted!!",
          type: "success",
          color: "green",
        });
        this.$bvModal.hide("modal-document");
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    editDocument(id, name) {
      const data = this.items.filter(
        (item) => item.pk === this.allCompanyDocs[id].id
      )[0];
      this.nameOptions = [{
        id: `${data.type}-${data.type === "Company" ? this.userCompany : data.userID}`,
        label: data.name,
        type: `${data.type === "Company" ? 'company' : data.type === "Vehicle" || data.type === "Trailer" ? 'equipment' : 'teammember'}`,
        name: `${data.type === "Company" ? '' : data.name}_`,
        model: data.type
      }]
      this.nameOptions = [...new Map(this.nameOptions.map(item => [item['id'], item])).values()]
      this.showDivision = data.type === 'Company' ? true : false
      this.modelType = data.type
      this.editDocumentFlag = true;
      this.documentIndex = id;
      this.documentData = { ...this.allCompanyDocs[id], doc_type: this.allCompanyDocs[id].doc_type_info.id, date: moment.tz(this.allCompanyDocs[id].date, this.userTimeZone).toDate(), expiry_date: this.allCompanyDocs[id].expiry_date ? moment.tz(this.allCompanyDocs[id].expiry_date, this.userTimeZone).toDate():"" };
      this.documentData.name = `${data.type}-${data.type === "Company" ? this.userCompany : data.userID}`;
      this.documentData.docName = name
      this.documentData.type = data.type
      if (this.documentData.document) {
        let file_name = "";
        if (typeof this.documentData.document === "string") {
          file_name = this.documentData.document;
        } else {
          file_name = this.documentData.document.name;
        }
        this.fileName = file_name.split("/").pop().split("#")[0].split("?")[0];
      } else {
        this.documentData.document = null;
        this.fileName = "";
      }
      this.errors = {
        date: null,
        name: null,
        document: null,
        description: null,
        ...this.company[0].doc_type  ? { doc_type: null } : {}
      };
      this.defaultData = JSON.parse(JSON.stringify({ ...this.documentData, doc_type: this.allCompanyDocs[id].doc_type_info.id ? this.allCompanyDocs[id].doc_type_info.id : '' }))
      this.$bvModal.show("modal-document");
    },
    async updateDocumentOnToggle(data, key, value) {
      try {
        this.setLoader(true)
        data[key] = value;
        delete data.document;
        await this.updateTeamMemberDocument({
          id: data.pk,
          payload: { ...data, date: moment(data.date).format("YYYY-MM-DD"), expiry_date: data.expiry ? moment(data.expiry).format("YYYY-MM-DD") : null },
        });
        await this.setNotification({
          msg: "Document Successfully updated",
          type: "success",
          color: "green",
        });
        this.setLoader(false)
      } catch (error) {
        console.log(error);
        this.setLoader(false)
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async setDocumentType(review) {
      if (review) this.reviewDocs = true
      else this.reviewDocs = false
      this.currentPage = 1
      this.resetFilterFields()
    },
    async getAllDocs() {
      try {
        this.isBusy = true
        let url = ""
        Object.keys(this.filterFields).map((key) => {
          if (this.filterFields[key] !== '') {
            if (key !== 'search_field') url += `${key}=${this.filterFields[key]}&`
            else {
              let searchList = ""
              this.fields.filter((item) => item.visible && item.field === 'text').map(({ key }, index) => index === 0 ? searchList = key : searchList += ',' + key)
              url += `${key}=${this.filterFields[key]}&search_list=${searchList}&`
            }
          }
        })
        localStorage.setItem(
          "docs_filters",
          JSON.stringify({
            filterFields: this.filterFields
          })
        );
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=expiry_date&sort_order=asc&`
        }
        if (this.reviewDocs) url += `to_be_reviewed=True&`

        url += `page=${this.currentPage}&page_size=${this.perPage}`
        const data = await this.getCompanyDocs({ url, company_id: this.userCompany });
        this.reviewDocsCount = data.review_count >= 0 ? data.review_count : 0
        this.fileDropCount = data.file_drop_count >= 0 ? data.file_drop_count : 0
        this.allCount = data.all_count >= 0 ? data.all_count : 0
        if (data.total_entries) {
          this.rows = data.total_entries
          this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
        }
        else {
          this.rows = 0
          this.positionText = `0 to 0 of 0`
        }
        this.updateDocs();
        this.isBusy = false
        this.isTableMounted = true
      }
      catch (e) {
        this.isBusy = false
        console.log(e)
      }
    },
    async updateDocs() {
      this.items = [];
      this.allCompanyDocs.forEach((doc, id) => {
        if (
          !doc.document_of.role ||
          (doc.document_of.role &&
            (doc.document_of.role === "Driver" ||
              doc.document_of.role === "Company"))
        ) {
          let color;
          let expiryColor;
          let daysDiff = "-";
          if (doc.expiry_date) {
            const currentDate = new Date();
            const expiryDate = new Date(doc.expiry_date);
            const diffDays = parseInt(
              (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
              10
            );
            if (diffDays > 31) {
              color = "color:black";
            } else if (diffDays <= 31 && diffDays >= 1) {
              color = "color:#FFC800";
            } else color = "color:#FF0000";
            daysDiff = parseInt(
              (new Date(doc.expiry_date) - new Date()) / (1000 * 60 * 60 * 24),
              10
            );
            if (daysDiff <= 0) {
              expiryColor = "color:red";
            } else if (daysDiff <= 31 && daysDiff >= 1) {
              expiryColor = "color:#FFC800";
            } else {
              expiryColor = "color:green";
            }
          }
          this.items.push({
            name: doc.document_of.unit_no
              ? doc.document_of.unit_no
              : doc.document_of.name,
            type: doc.document_of.role
              ? doc.document_of.role
              : doc.document_of.type,
            description: doc.description,
            data_extraction_requested: doc.data_extraction_requested,
            date: moment(doc.date).format("MMM DD, YYYY"),
            expiry: doc.expiry_date,
            expiry_date: doc.expiry_date
              ? `<span style=${color}>${moment(doc.expiry_date).format(
                "MMM DD, YYYY"
              )}</span>`
              : "-",
            id: id,
            pk: doc.id,
            link: doc.document,
            color: expiryColor,
            public: doc.public,
            driver: doc.driver,
            expiry_days: daysDiff,
            userID: doc.document_of.id,
            doc_type: doc.doc_type_info.id,
            doc_type_name: doc.doc_type_info.type ? doc.doc_type_info.type : '-',
            dot_mode: doc.dot_mode,
            source: (doc.uploaded_by_driver || doc.data_extraction_requested) ? doc.uploaded_by_driver ? 'Driver' : 'BOT' : '-',
            object_id: doc.object_id,
            rejected: doc.rejected,
            rejection_reason: doc.rejection_reason
          });

        }
        else {
          console.log(doc)
        }
      });
      if (this.items.length === 0) this.emptyTable = true
      else this.emptyTable = false
    },
  },
  watch: {
    userPreferences: {
      async handler() {
        if (this.userPreferences) {
          this.fields = this.userPreferences['Document'].sort((a, b) => a.display_order - b.display_order).map((col) => {
            return { ...col, thClass: 'font-table-weight-bold', ...col.key === 'selected' ? { thStyle: { minWidth: "20px", width: "20px" } } : {} }
          })
        }
      },
      immediate: true
    },
    perPage: {
      async handler() {
        await this.getAllDocs();
      },
      immediate: true,
    },
    currentPage: {
      async handler() {
        await this.getAllDocs();
      },
    },
    sortBy: {
      async handler() {
        await this.getAllDocs();
      },
    },
    sortDesc: {
      async handler() {
        await this.getAllDocs();
      },
    },
    filterFields: {
      handler() {
        this.currentPage = 1
      },
      deep: true
    }
  },
  async mounted() {
    if (this.rolePermissions && this.rolePermissions['Document'] && this.rolePermissions['Document'][2].allow) {
      const data = await this.checkBulkCompanyDocumentsUploadStatus()
      if (data.status === 'In Progress') this.showDocumentProgess = data
      if ((data.status === 'Completed' || data.status === 'Failed') && !data.dismissed && data.requestor === 2) this.showDocumentUploadStatus = data
    }
  }
};
</script>
<style>
.documents-alert-section {
  background-color: white;
  color: black;
  padding: 11px 18px;
  border-radius: 5px;
  margin-bottom: 26px;
  font-weight: 500;
  border-left: 10px solid #FF0000;
}

.documents-progess-section {
  background-color: white;
  border-left: 10px solid #FFC800;
  color: black;
  padding: 11px 18px;
  border-radius: 5px;
  margin-bottom: 26px;
  font-weight: 500;
}

.error-button {
  z-index: 1;
}
</style>
