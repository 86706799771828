import {
  getReq,
  getById,
  postReq,
  postFormData,
  patchReq,
  deleteReq,
  putReq,
} from "../../../services/api";

const state = {
  allTeamMembers: [],
  allDrivers: [],
  allDocs: [],
  currentTeamMember: null,
  allStates: [],
  allCountries: [],
  allDriverDeductions: [],
  allDriverPayInfo: [],
  singleDriverPayInfo: [],
};

const getters = {
  allTeamMembers: (state) => state.allTeamMembers,
  allDrivers: (state) => state.allDrivers,
  currentTeamMember: (state) => state.currentTeamMember,
  allStates: (state) => state.allStates,
  allCountries: (state) => state.allCountries,
  allDriverDeductions: (state) => state.allDriverDeductions,
  allDriverPayInfo: (state) => state.allDriverPayInfo,
  singleDriverPayInfo: (state) => state.singleDriverPayInfo,
  allCompanyDocs: (state) => state.allDocs,
};

const actions = {
  async getAllTeamMembers({ commit }, url) {
    try {
      let data = await getReq(
        `app/get_users_by_role/?role=no_drivers${url ? "&"+url:""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&':''}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`
      );
      commit("setAllTeamMembers", data.data);
      return {records:data.data, total_pages:data.total_pages, queryset_count:data.queryset_count, total_entries:data.total_entries_count, current_page:data.current_page}
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllUsersByUsername(_, url) {
    try {
      let data = await getReq(
        `app/get-user-names/${url ? "?"+url:""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&':''}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`
      );
      return {records:data.data, total_pages:data.total_pages, queryset_count:data.queryset_count, total_entries:data.total_entries_count, current_page:data.current_page}
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllDrivers({ commit }, url) {
    try {
      let data = await getReq(`app/get_users_by_role/?role=driver${url ? "&"+url:""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&':''}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setAllDrivers", data.data);
      return {records:data.data, total_pages:data.total_pages, queryset_count:data.queryset_count, total_entries:data.total_entries_count, current_page:data.current_page, total_active_count: data.total_active_drivers, total_inactive_count: data.total_inactive_drivers}
     
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getDriversExportData(_, query) {
    try {
      return await getReq(`app/export_excel_drivers/${query ? "?"+query : ''}${localStorage.getItem("userSelectedCompany") ? `${query ? '&':''}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  async getAllStates({ commit }) {
    try {
      const allStates = await getReq(`app/states/`);
      commit("setAllStates", allStates);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllCountries({ commit }) {
    try {
      const allCountries = await getReq(`app/countries/`);
      commit("setAllCountries", allCountries);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getTeamMember({ commit }, id) {
    try {
      const currentTeamMember = await getReq(`app/team-members/${id}/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setCurrentTeamMember", currentTeamMember);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async checkDriverStatus(_, payload) {
    try {
      const {exists} = await postReq(`app/check-driver-exists/`,payload);
      return exists
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getTeamMembersCount(_, query) {
    try {
      const data = await getReq(`app/get_users_count_by_role/?role=${query}${localStorage.getItem("userSelectedCompany") ? `&company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      return data;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  async getCompanyDocs({ commit }, payload) {
    try {
      const data = await postReq(`app/get_docs/${payload.url ? "?"+payload.url:""}`, payload);
      commit("setAllDocs", data.docs);
      return {records:data.docs, total_pages:data.total_pages, queryset_count:data.queryset_count, total_entries:data.total_entries_count, current_page:data.current_page, review_count: data.review_count, file_drop_count: data.file_drop_count, all_count: data.all_documents_count}
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async addTeamMember(context, payload) {
    try {
      const newMember = await postReq(`app/team-members/`, payload);
      console.log("post");

      return newMember;
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response.data;
    }
  },
  async changeDriverStatus(context, payload) {
    try {
      const newMember = await postReq(`app/change-driver-status/`, payload);
      return newMember;
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response.data;
    }
  },
  async addBulkData(context, payload) {
    try {
      console.log(payload);
      const bulkData = await postReq(`app/csv_bulk_upload/`, payload);
      return bulkData;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async sendBulkInvite(context, payload) {
    try {
      const invite = await postReq(`app/send-bulk-invite/`, payload);
      return invite;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async sendInvite(context, payload) {
    try {
      const invite = await postReq(`app/invite/`, payload);
      return invite;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async postExcelData(context, payload) {
    try {
      const excelLink = await postReq(`app/excel/`, payload);
      console.log("post");

      return excelLink;
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async setPassword(context, payload) {
    try {
      await postReq(`app/setpassword/`, payload);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async setPasswordforAllDrivers(context, payload) {
    try {
      await postReq(`app/set_password_drivers/`, payload);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  
  async updateTeamMember({ commit }, { id, payload }) {
    try {
      const updatedTeamMember = await patchReq("app/team-members", id, payload);
      if (updatedTeamMember) {
        commit("setCurrentTeamMember", updatedTeamMember);
      }
      return updatedTeamMember
    } catch (e) {
      const errors = e?.response?.data
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }

      throw error[0][0];
    }
  },
  async deleteTeamMember(_, id) {
    try {
      return await deleteReq("app/team-members",id);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      throw errors;
    }
  },
  async sendTeamMemberDocument(_, formData) {
    try {
      const docs = await postFormData("app/documents/", formData);

      return docs;
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async updateTeamMemberDocument(_, { id, payload }) {
    try {
      return await patchReq("app/documents", id, payload);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async deleteCompanyDocument(_, id) {
    try {
      return await deleteReq("app/documents", id);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async retrieveDocument(_, id) {
    try {
      return await getById("app/documents/", id);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async addDriverDeduction({ commit }, payload) {
    try {
      const driverDeduction = await postReq(
        `tms_user/driver-credit-deduction/`,
        payload
      );
      commit("addDriverDeduction", driverDeduction);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async getDriverDeductions({ commit }, id) {
    try {
      const driverDeductions = await getReq(
        `tms_user/driver-credit-deduction/?driver_id=${id}`
      );
      commit("setDriverDeductions", driverDeductions);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  async deleteDriverDeduction({ commit }, id) {
    try {
      const response = await deleteReq("tms_user/driver-credit-deduction", id+"/");
      commit("deleteDriverDeduction", id);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateDriverDeduction({ commit }, payload) {
    try {
      const response = await putReq(
        "tms_user/driver-credit-deduction",
        payload.id,
        payload
      );
      commit("updateDriverDeduction", response);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateBulkDocument(_, payload) {
    try {
      const response = await postReq("app/document-bulk-update/",payload);
      return response;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  async addDriverBulkTags(_, payload) {
    try {
      const response = await postReq("app/driver-add-tags-bulk/",payload);
      return response;
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  async getSingleDriverPayInfo({ commit }, payload) {
    try {
      const response = await getReq(
        `tms_user/pay-info/?driver_id=${payload.id}`
      );
      console.log("response of getSingleDriverPayInfo", response);
      // const response = await getById("tms_user/pay-info/", `?driver_id=${payload.id}`);
      // const response = await putReq(
      //   `tms_user/pay-info/?driver_id=${payload.id}`,
      // );
      commit("setSingleDriverPayInfo", response);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateDriverPayInfo({ commit }, payload) {
    try {
      const response = await patchReq(
        "tms_user/pay-info",
        payload.id,
        payload.payload
      );
      commit("updateDriverPayInfo", response);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async sendMessageToDrivers(_, payload) {
    try {
      await postReq(`app/send-message-to-drivers/`,payload);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
};

const mutations = {
  setAllTeamMembers: (state, allTeamMembers) => (state.allTeamMembers = allTeamMembers),
  setAllDrivers: (state, allDrivers) =>(state.allDrivers = allDrivers),
  setCurrentTeamMember: (state, TeamMember) =>
    (state.currentTeamMember = TeamMember),
  setAllStates: (state, allStates) => (state.allStates = allStates),
  setAllCountries: (state, allCountries) => (state.allCountries = allCountries),
  setAllDocs: (state, allDocs) => (state.allDocs = allDocs),
  addDriverDeduction: (state, driverDeduction) =>
    state.allDriverDeductions.push(driverDeduction),
  setDriverDeductions: (state, driverDeductions) =>
    (state.allDriverDeductions = driverDeductions),
  deleteDriverDeduction: (state, id) => {
    const index = state.allDriverDeductions.findIndex((x) => x.id === id);
    state.allDriverDeductions.splice(index, 1);
  },
  updateDriverDeduction: (state, driverDeduction) => {
    state.allDriverDeductions = state.allDriverDeductions.map((item) =>
      item.id === driverDeduction.id ? driverDeduction : item
    );
  },
  setSingleDriverPayInfo: (state, driverPayInfo) =>
    (state.singleDriverPayInfo = driverPayInfo),
  updateDriverPayInfo: (state, driverPayInfo) => {
    state.allDriverPayInfo = state.allDriverPayInfo.map((item) =>
      item.id === driverPayInfo.id ? driverPayInfo : item
    );
  },
  // setBulkTeamMembers: (state, newTeamMembers) =>{
  //   state.allTeamMembers.push(newTeamMembers)
  // }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
